import React, { useContext, useState, useEffect, useRef } from "react";
import { globalContext } from "./Context";
import {
  formatDateTime2,
  showInfoDialog,
  stringToDate2,
  sleep,
  formatDate2,
  getInterval,
  getTimeframeFromInterval,
  attachCumulativesData,
  attachTradesData,
} from "./utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle, faStop, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { RadioButtons } from "./RadioButtons";
import { startBacktest, stopBacktest } from "./Bot";
import getEcbDatabase from "./EcbDatabase";
import loading from "./assets/images/loading-reverced.gif";
import { StaticChartWithIndicators } from "./Charts";
import { getTimeframes } from "./tatools.js";
import getExchange from "./Exchange.js";
import { TradesTable, ResultsSection } from "./Trading.js";

const BacktestProgress = (props) => {
  const { dispatch } = useContext(globalContext);
  return (
    <div className="d-flex position-relative align-items-center">
      <div className="progress flex-grow-1">
        <div
          className="progress-bar progress-bar-striped bg-info"
          role="progressbar"
          aria-valuenow={props.runningBacktest.completed}
          aria-valuemin={0}
          aria-valuemax={100}
          style={{
            width: props.runningBacktest.completed + "%",
          }}
        >
          {props.runningBacktest.completed}%
        </div>
        {props.runningBacktest.downloaded >= 0 && props.runningBacktest.downloaded < 100 && (
          <div title="Getting Historical Data from Exchange" className="eta text-info">
            Getting data
            {props.runningBacktest.downloaded > 0 && <>: {Math.floor(props.runningBacktest.downloaded)}%</>}
          </div>
        )}
        {props.runningBacktest.completed === 0 && props.runningBacktest.downloaded === 100 && (
          <div title="Calculating Indicators" className="eta text-info">
            Calculating indicators...
          </div>
        )}
      </div>
      <a
        href="#/"
        className="text-danger ml-1 font-normal"
        title="Stop Backtest"
        onClick={(e) => {
          e.preventDefault();
          document.activeElement.blur();
          stopBacktest(props.strategy.id, dispatch);
        }}
      >
        <FontAwesomeIcon icon={faStop} />
      </a>
    </div>
  );
};

const BacktestSettings = (props) => {
  const { state, dispatch } = useContext(globalContext);
  const [commissionRate, setCommissionRate] = useState(state.commissionRate);
  const [dateFrom, setDateFrom] = useState(formatDateTime2(state.dateFrom));
  const [dateTo, setDateTo] = useState(formatDateTime2(state.dateTo));
  const [tradeSize, setTradeSize] = useState(state.btSize);
  const [recentBacktests, setRecentBacktests] = useState([]);
  const [shownBacktestResult, setShownBacktestResult] = useState(false);
  const componentIsMounted = useRef(true);
  const strategyId = useRef(null);

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!props.strategy) {
      strategyId.current = null;
      setRecentBacktests([]);
      return;
    }
    strategyId.current = props.strategy.id;
    setRecentBacktests([]);
    setShownBacktestResult(props.runningBacktest && props.runningBacktest.completed === 100);
    let strategyIdTmp = props.strategy.id;
    getEcbDatabase()
      .getBacktests(props.strategy.id)
      .then((backtests) => {
        if (componentIsMounted.current && strategyId.current === strategyIdTmp) {
          setRecentBacktests(backtests);
        }
      });
  }, [props.strategy]);

  useEffect(() => {
    if (props.runningBacktest && props.runningBacktest.completed === 100 && !shownBacktestResult) {
      let strategyIdTmp = props.strategy.id;
      getEcbDatabase()
        .getBacktests(props.strategy.id)
        .then((backtests) => {
          if (componentIsMounted.current && strategyId.current === strategyIdTmp) {
            setRecentBacktests(backtests);
            setShownBacktestResult(true);
          }
        });
    }
  }, [props.backtests]);

  return (
    props.strategy && (
      <div
        className={`${props.rightPanelWidth > 650 ? "d-flex flex-wrap" : "pr-1"} small user-select-none fade-in pl-1`}
      >
        <div className={props.rightPanelWidth > 650 ? "w-350 border-right" : "border-bottom text-center pb-4 mb-4"}>
          <div className="d-inline-block text-left w-350">
            <div className="pb-2">
              <div className="d-inline-block min-w-150">Commission rate:</div>
              <input
                type="number"
                className="setup-info  w-165"
                value={commissionRate}
                placeholder="<Number>"
                onChange={(e) => {
                  let value = parseFloat(e.target.value);
                  value = isNaN(value) ? "" : Math.abs(value);
                  setCommissionRate(value);
                  dispatch({
                    type: "commissionRate",
                    payload: value ? value : 0,
                  });
                }}
              />
              <span className="text-info">%</span>
            </div>
            <div className="pb-2 pt-1">
              <div className="d-inline-block min-w-150">Start date:</div>
              <input
                type="datetime-local"
                className="setup-info  w-165"
                value={dateFrom}
                onChange={(e) => {
                  if (state.demo) {
                    showInfoDialog(
                      dispatch,
                      <div className="text-info">
                        <FontAwesomeIcon icon={faExclamationTriangle} /> Demo mode
                      </div>,
                      "Cannot be changed in DEMO mode!"
                    );
                    return;
                  }
                  let dateFromTmp = stringToDate2(e.target.value);
                  if (!dateFromTmp || isNaN(dateFromTmp.getTime())) {
                    return;
                  }
                  let date = new Date();
                  date.setMonth(date.getMonth() - 1);
                  date.setHours(0, 0, 0, 0);
                  if (!state.app && date > dateFromTmp) {
                    showInfoDialog(
                      dispatch,
                      <div className="text-danger">
                        <FontAwesomeIcon icon={faExclamationTriangle} /> Invalid period
                      </div>,
                      "For older periods, use the ECB desktop application."
                    );
                    return;
                  }
                  if (state.user.isExpired && date > dateFromTmp) {
                    showInfoDialog(
                      dispatch,
                      <div className="text-danger">
                        <FontAwesomeIcon icon={faExclamationTriangle} /> Invalid period
                      </div>,
                      <>
                        Older backtest periods are not avaiable for the FREE subscription plan.
                        <br />
                        <br />
                        Please upgrade your subscription plan.
                      </>
                    );
                    return;
                  }

                  setDateFrom(e.target.value);
                  dispatch({
                    type: "dateFrom",
                    payload: dateFromTmp,
                  });
                }}
              />
            </div>
            <div className="pb-2 pt-1">
              <div className="d-inline-block min-w-150">End date:</div>
              <input
                type="datetime-local"
                className="setup-info  w-165"
                value={dateTo}
                onChange={(e) => {
                  if (state.demo) {
                    showInfoDialog(
                      dispatch,
                      <div className="text-info">
                        <FontAwesomeIcon icon={faExclamationTriangle} /> Demo mode
                      </div>,
                      "Cannot be changed in DEMO mode!"
                    );
                    return;
                  }
                  let dateToTmp = stringToDate2(e.target.value);
                  if (!dateToTmp || isNaN(dateToTmp.getTime())) {
                    return;
                  }
                  let now = new Date();
                  now.setHours(0, 0, 0, 0);
                  if (!state.app && dateToTmp > now) {
                    return;
                  }
                  setDateTo(e.target.value);
                  dispatch({
                    type: "dateTo",
                    payload: dateToTmp,
                  });
                }}
              />
            </div>
            {(state.demo || props.freeBacktest) && (
              <div className="pb-2 pt-1 text-info">Custom periods are available only in the app</div>
            )}

            <div className="pb-2 pt-1">
              <div className="d-inline-block min-w-150">Trade size type:</div>
              <RadioButtons
                options={[
                  {
                    optionClasses: "text-light mr-md-4 pr-md-2",
                    checkedColor: "text-info",
                    uncheckedColor: "text-new-dark hover-info border rounded-circle border-info",
                    checked: tradeSize.type === "base",
                    check: () => {
                      let newSize = {
                        type: "base",
                        value: tradeSize.value,
                      };
                      setTradeSize(newSize);
                      dispatch({
                        type: "btSize",
                        payload: newSize,
                      });
                    },
                    text: "Base",
                  },
                  {
                    optionClasses: "text-light ml-md-2",
                    checkedColor: "text-info",
                    uncheckedColor: "text-new-dark hover-info border rounded-circle border-info",
                    checked: tradeSize.type === "quoted",
                    check: () => {
                      let newSize = {
                        type: "quoted",
                        value: tradeSize.value,
                      };
                      setTradeSize(newSize);
                      dispatch({
                        type: "btSize",
                        payload: newSize,
                      });
                    },
                    text: "Quote",
                  },
                ]}
              />
            </div>
            <div className="pb-2 pt-1 text-nowrap">
              <div className="d-inline-block min-w-150">Trade size:</div>
              <input
                type="number"
                className="setup-info  w-165 optional"
                value={tradeSize.value}
                placeholder="<Optional>"
                onChange={(e) => {
                  let value = Math.abs(parseFloat(e.target.value));

                  let newSize = {
                    type: tradeSize.type,
                    value: isNaN(value) || value < 0 ? "" : value,
                  };
                  setTradeSize(newSize);

                  dispatch({
                    type: "btSize",
                    payload: newSize,
                  });
                }}
              />
              <span className="text-info">
                {tradeSize.type === "base"
                  ? props.strategy.pairDetails.baseAsset
                  : props.strategy.pairDetails.quoteAsset}
              </span>
            </div>
            {(!props.runningBacktest || props.runningBacktest.completed === 100) && (
              <div className="mt-4 text-center">
                <button
                  className="btn btn-sm btn-info min-w-150 mx-auto"
                  onClick={() => {
                    let dateToTmp = stringToDate2(dateTo);
                    let dateFromTmp = stringToDate2(dateFrom);
                    if (dateToTmp <= dateFromTmp) {
                      showInfoDialog(
                        dispatch,
                        <div className="text-danger">
                          <FontAwesomeIcon icon={faExclamationTriangle} /> Invalid period
                        </div>,
                        "Start date should be older than the End date."
                      );
                      return;
                    }
                    let now = new Date();
                    let date = new Date();
                    now.setHours(0, 0, 0, 0);
                    date.setMonth(date.getMonth() - 1);
                    date.setHours(0, 0, 0, 0);
                    if (!state.demo && !state.app && (date > dateFromTmp || dateToTmp > now)) {
                      showInfoDialog(
                        dispatch,
                        <div className="text-danger">
                          <FontAwesomeIcon icon={faExclamationTriangle} /> Invalid period
                        </div>,
                        "For custom periods, use the ECB desktop application"
                      );
                      return;
                    }
                    if (state.user.isExpired && date > dateFromTmp) {
                      showInfoDialog(
                        dispatch,
                        <div className="text-danger">
                          <FontAwesomeIcon icon={faExclamationTriangle} /> Invalid period
                        </div>,
                        <>
                          Older backtest periods are not avaiable for the FREE subscription plan.
                          <br />
                          <br />
                          Please upgrade your subscription plan.
                        </>
                      );
                      return;
                    }

                    setShownBacktestResult(false);
                    if (!commissionRate) {
                      setCommissionRate(0);
                      dispatch({
                        type: "commissionRate",
                        payload: 0,
                      });
                    }

                    startBacktest(
                      props.strategy,
                      {
                        dateFrom: dateFromTmp,
                        dateTo: dateToTmp,
                        commissionRate: commissionRate ? commissionRate : 0,
                        tradeSize: tradeSize,
                      },
                      dispatch
                    );
                  }}
                >
                  Start
                </button>
              </div>
            )}
          </div>
        </div>
        <div
          className={props.rightPanelWidth > 650 ? "pl-3" : ""}
          style={props.rightPanelWidth > 650 ? { width: props.rightPanelWidth - 390 } : {}}
        >
          <div className="mb-2">Recent:</div>
          <div>
            <div className="table-responsive">
              <table className="table table-sm table-borderless">
                <tbody>
                  {props.runningBacktest && !shownBacktestResult && (
                    <tr className="text-nowrap fade-in">
                      <td className="text-left pl-0 " colSpan="2">
                        <div className="max-w-220">
                          <BacktestProgress runningBacktest={props.runningBacktest} strategy={props.strategy} />
                        </div>
                      </td>
                    </tr>
                  )}
                  {recentBacktests.map((el, index) => {
                    return props.runningBacktest && !shownBacktestResult && index === 9 ? null : (
                      <tr key={index} className="text-nowrap">
                        <td
                          className={`text-left rounded text-nowrap pl-0 ${
                            props.runningBacktest &&
                            props.runningBacktest.completed === 100 &&
                            index === 0 &&
                            shownBacktestResult
                              ? "bg-change-info"
                              : ""
                          }`}
                        >
                          <a
                            href="#/"
                            onClick={(e) => {
                              e.preventDefault();
                              document.activeElement.blur();
                              props.setStrategyActiveSubTabResult(true);
                              props.setResult(el.backtest);
                            }}
                            className="text-info"
                          >
                            {formatDate2(el.backtest.params.dateFrom)} - {formatDate2(el.backtest.params.dateTo)}{" "}
                            <span
                              className={
                                el.backtest.totalReturn > 0
                                  ? "text-success"
                                  : el.backtest.totalReturn < 0
                                  ? "text-danger"
                                  : "text-white"
                              }
                            >
                              {el.backtest.totalReturn !== null &&
                                el.backtest.totalReturn !== undefined &&
                                el.backtest.totalReturn.toFixed(1) + "%"}
                            </span>
                          </a>
                        </td>
                        <td className="text-left w-100 pl-0">
                          <a
                            href="#/"
                            className="text-danger pl-1"
                            title="Delete result"
                            onClick={(e) => {
                              e.preventDefault();
                              document.activeElement.blur();
                              getEcbDatabase().deleteBacktest(el.id);
                              let recentBacktestsNew = recentBacktests.filter((bt) => bt.id !== el.id);
                              if (props.currentBacktestResult && props.currentBacktestResult.id === el.id) {
                                props.setResult(recentBacktestsNew.length > 0 ? recentBacktestsNew[0].backtest : null);
                              }
                              setRecentBacktests(recentBacktestsNew);
                            }}
                          >
                            <FontAwesomeIcon icon={faTimesCircle} />
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

const BacktestResult = (props) => {
  const { state } = useContext(globalContext);
  const [tradeToShow, setTradeToShow] = useState(null);
  const [stats, setStats] = useState(null);
  const strategyId = useRef(null);
  const [timeframes, setTimeframes] = useState([]);
  const [selectedTimeframe, setSelectedTimeframe] = useState("");
  const [historicalData, setHistoricalData] = useState(null);
  const [cumulativeData, setCumulativeData] = useState(null);

  const [chartHistoricalData, setChartHistoricalData] = useState([]);

  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (props.strategy) {
      strategyId.current = props.strategy.id;
      let timeframes = getTimeframes(props.strategy).map((tf) => getInterval(tf));
      setTimeframes(timeframes);
      setSelectedTimeframe(timeframes[0]);
    } else {
      strategyId.current = null;
      setTimeframes([]);
      setSelectedTimeframe("");
      setHistoricalData(null);
      setCumulativeData(null);
      setChartHistoricalData([]);
      setStats(null);
    }
  }, [props.strategy]);

  useEffect(() => {
    if (!props.backtest) {
      let strategyIdTmp = strategyId.current;
      getEcbDatabase()
        .getBacktests(props.strategy.id)
        .then((backtests) => {
          if (componentIsMounted.current) {
            if (strategyId.current === strategyIdTmp && backtests.length > 0) {
              if (backtests[0].backtest) {
                props.setResult(backtests[0].backtest);
              }
            }
          }
        });
    }
  }, [props.backtests]);

  useEffect(() => {
    setHistoricalData(null);
    setCumulativeData(null);
    setChartHistoricalData([]);
    setStats(null);

    if (props.backtest) {
      let strategyIdTmp = strategyId.current;
      (async () => {
        let btResult = null;
        for (let i = 0; i < 10; i++) {
          btResult = await getEcbDatabase().getBacktest(props.backtest.id);
          if (btResult) {
            break;
          }
          await sleep(200);
        }
        if (componentIsMounted.current && strategyIdTmp === strategyId.current) {
          if (btResult) {
            let dateFrom = new Date(props.backtest.params.dateFrom);
            let timeframes = getTimeframes(props.strategy);
            btResult.chartData = {};
            attachTradesData(btResult);
            setStats(btResult);

            let dateTo = new Date(props.backtest.params.dateTo);
            let datesDiff = (dateTo.getTime() - dateFrom.getTime()) / (1000 * 3600 * 24);
            let use1DayForCumulativesChart = datesDiff > 30;
            if (!state.demo && use1DayForCumulativesChart) {
              getExchange(props.strategy.exchange)
                .getHistoricalData(
                  `showRes-${Math.random() * 10000000 + 10000000}`,
                  props.strategy.pair,
                  ["1 day"],
                  dateFrom,
                  dateTo,
                  null,
                  false
                )
                .then((historicalData) => {
                  if (componentIsMounted.current && strategyIdTmp === strategyId.current && historicalData) {
                    let smallTfHistoricalData = historicalData["1 day"];
                    if (!smallTfHistoricalData || smallTfHistoricalData.length === 0) {
                      return;
                    }
                    let cumulativeData = { trades: btResult.trades };
                    attachCumulativesData(cumulativeData, smallTfHistoricalData, dateFrom);
                    setCumulativeData(cumulativeData);
                  }
                });
            }
            getExchange(props.strategy.exchange)
              .getHistoricalData(
                `showRes-${Math.random() * 10000000 + 10000000}`,
                props.strategy.pair,
                timeframes,
                dateFrom,
                dateTo,
                null,
                false
              )
              .then((historicalData) => {
                if (componentIsMounted.current && strategyIdTmp === strategyId.current && historicalData) {
                  let smallTfHistoricalData = historicalData[timeframes[0]];
                  if (!smallTfHistoricalData || smallTfHistoricalData.length === 0) {
                    return;
                  }
                  if (state.demo || !use1DayForCumulativesChart) {
                    let cumulativeData = { trades: btResult.trades };
                    attachCumulativesData(cumulativeData, smallTfHistoricalData, dateFrom);
                    setCumulativeData(cumulativeData);
                  }

                  setHistoricalData(historicalData);
                }
              });
          }
        }
      })();
    }
  }, [props.backtest]);

  useEffect(() => {
    if (historicalData) {
      let tf = getTimeframeFromInterval(selectedTimeframe);
      setChartHistoricalData(historicalData[tf]);
    }
  }, [selectedTimeframe, historicalData]);

  return props.backtest ? (
    stats ? (
      props.backtest && (
        <div className="fade-in pr-2">
          <div className="text-center mb-3 custom-hr">
            {formatDate2(props.backtest.params.dateFrom)} - {formatDate2(props.backtest.params.dateTo)}
          </div>
          {stats.tradesCount > 0 ? (
            <ResultsSection
              backtest={props.backtest}
              labelsColor="text-info"
              dateFrom={props.backtest.params.dateFrom}
              dateTo={props.backtest.params.dateTo}
              tradeSize={props.backtest.params.tradeSize}
              stats={stats}
              commissionRate={props.backtest.params.commissionRate}
              exchange={props.strategy.exchange}
              pair={props.strategy.pair}
              pairDetails={props.strategy.pairDetails}
              setTradeToShow={setTradeToShow}
              cumulativeData={cumulativeData}
              marketReturn={stats.marketReturn}
              chartId="btChart"
            />
          ) : (
            <>
              <div className="text-center my-4 custom-hr">No trades for the selected period</div>
            </>
          )}

          <div className={stats.trades.length > 0 ? "custom-hr" : ""}>
            <div className="text-right mb-2 mr-1">
              {timeframes.map((time) => {
                return (
                  <a
                    key={time}
                    href="/#"
                    className={
                      selectedTimeframe === time ? "small bg-info text-light rounded px-1" : "small text-info px-1"
                    }
                    onClick={(e) => {
                      e.preventDefault();
                      document.activeElement.blur();
                      setSelectedTimeframe(time);
                    }}
                  >
                    {time}
                  </a>
                );
              })}
            </div>

            <StaticChartWithIndicators
              strategy={props.strategy}
              pair={props.strategy.pair}
              exchange={props.strategy.exchange}
              timeframe={selectedTimeframe}
              id="btChart"
              showZoomSlider={true}
              height={Math.max(250, props.topPanelHeight - 200)}
              trades={stats.trades}
              historicalData={chartHistoricalData}
              markPointBig={true}
              tradeToShow={tradeToShow}
            />
          </div>
          {stats.trades.length > 0 && (
            <TradesTable
              setTradeToShow={setTradeToShow}
              trades={stats.trades}
              stats={stats}
              exchange={props.strategy.exchange}
              pair={props.strategy.pair}
              pairDetails={props.strategy.pairDetails}
              chartId="btChart"
            />
          )}
        </div>
      )
    ) : (
      <div className="text-center">
        <div className="loading-img cursor-help my-5" title="Loading..">
          <img src={loading} alt="" />
        </div>
      </div>
    )
  ) : props.runningBacktest ? (
    <div className="text-center">
      <h5 className="text-secondary mt-5 mb-3">Running Backtest</h5>
      <div className="max-w-200 mx-auto">
        <BacktestProgress runningBacktest={props.runningBacktest} strategy={props.strategy} />
      </div>
    </div>
  ) : (
    <div className="text-center">
      <h5 className="text-secondary px-5">Backtest results will be displayed here</h5>
      <a
        href="#/"
        className="text-info mt-5"
        title="New Backtest"
        onClick={(e) => {
          e.preventDefault();
          document.activeElement.blur();
          props.setStrategyActiveSubTabResult(false);
        }}
      >
        Setup new backtest
      </a>
    </div>
  );
};

export { BacktestSettings, BacktestResult };
