import React, { useContext, useEffect, useRef, useState } from "react";
import { globalContext } from "./Context.js";
import loading from "./assets/images/loading-reverced.gif";
import pl1 from "./assets/images/pl1.png";
import pl2 from "./assets/images/pl2.png";
import pl3 from "./assets/images/pl3.png";
import plRest from "./assets/images/pl410.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";

const ContestRules = (props) => {
  let { state } = useContext(globalContext);
  const [activeContest, setActiveContest] = useState();
  const [futureContest, setFutureContest] = useState(false);

  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (state.contests.length > 0) {
      if (
        !state.contests[state.contests.length - 1].ongoing &&
        state.nextContest &&
        state.nextContest.month
      ) {
        setActiveContest(state.nextContest);
        setFutureContest(true);
      } else {
        setActiveContest(state.contests[state.contests.length - 1]);
      }
    }
  }, [state.contests, state.nextContest]);
  return (
    <div className="">
      <section>
        <div className="mx-2 mx-lg-4">
          <h1 className="h2 mt-4 pb-4 mx-auto">Let the game begin!</h1>
          <h5 className="mb-4">
            Create{" "}
            <FontAwesomeIcon
              className="text-info mx-2"
              icon={faArrowAltCircleRight}
            />{" "}
            Submit{" "}
            <FontAwesomeIcon
              className="text-info mx-2"
              icon={faArrowAltCircleRight}
            />{" "}
            Watch your strategy in action{" "}
            <FontAwesomeIcon
              className="text-info mx-2"
              icon={faArrowAltCircleRight}
            />{" "}
            Win
          </h5>
          {activeContest && (
            <>
              {futureContest && (
                <div className="h4 text-center">Next Contest</div>
              )}
              <div className="h5 text-center text-light-green">
                {activeContest.month} - {activeContest.pair}
              </div>
              <div className="row row-cols-1 row-cols-lg-3 mb-5">
                <div className="col mb-4 order-lg-3">
                  {futureContest && (
                    <>
                      <h5 className="h5 text-center mt-4">
                        Next Contest
                        <small className="d-block">{activeContest.month}</small>
                      </h5>
                      <h6 className="h6 text-center pb-1">
                        pair: {activeContest.pair}
                      </h6>
                    </>
                  )}
                  <div className="h2 mt-4">
                    Start: {activeContest.start}-{activeContest.month}
                  </div>
                  <div className="h4">
                    End: {activeContest.end}-{activeContest.month}
                  </div>
                  <div className="text-light-green mb-5">
                    Submit until: {activeContest.submit}
                  </div>
                  {!futureContest && (
                    <>
                      <h6 className="h5 text-center border-top border-light-green mt-4 pt-4">
                        Next Contest
                        {state.nextContest && state.nextContest.month ? (
                          <small className="d-block">
                            {state.nextContest.month}
                          </small>
                        ) : (
                          <div className="h5 text-light-green">
                            Comming soon
                          </div>
                        )}
                      </h6>
                      {state.nextContest && state.nextContest.month && (
                        <>
                          <div className="">pair: {state.nextContest.pair}</div>
                          <div className="mt-2">
                            Start: {state.nextContest.start}-
                            {state.nextContest.month}
                          </div>
                          <div className="">
                            End: {state.nextContest.end}-
                            {state.nextContest.month}
                          </div>
                          <div className="text-light-green">
                            Submit until: {state.nextContest.submit}
                          </div>
                          <div className="mt-2 mb-1">Rewards:</div>

                          <div className="row row-cols-3 px-2 px-sm-0">
                            <div className="col">
                              <div className="text-light-green">2nd Place:</div>
                              <div className="mt-1">
                                {state.nextContest.prizes[1].usdt && (
                                  <>
                                    {state.nextContest.prizes[1].usdt} USDT
                                    <div className="small font-weight-normal my-2">
                                      or
                                    </div>
                                  </>
                                )}
                                {state.nextContest.prizes[1].months} Months Full
                                Access
                              </div>
                            </div>
                            <div className="col">
                              <div className="text-light-green">1st Place</div>
                              <div className="mt-1">
                                {state.nextContest.prizes[0].usdt && (
                                  <>
                                    {state.nextContest.prizes[0].usdt} USDT
                                    <div className="small font-weight-normal my-2">
                                      or
                                    </div>
                                  </>
                                )}
                                {state.nextContest.prizes[0].months} Months Full
                                Access
                              </div>
                            </div>
                            <div className="col">
                              <div className="text-light-green">3rd Place</div>
                              <div className="mt-1">
                                {state.nextContest.prizes[2].usdt && (
                                  <>
                                    {state.nextContest.prizes[2].usdt} USDT
                                    <div className="small font-weight-normal my-2">
                                      or
                                    </div>
                                  </>
                                )}
                                {state.nextContest.prizes[2].months} Months Full
                                Access
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
                <div className="col px-xl-3 border-left-lg border-right-lg border-top-max-lg border-bottom-max-lg border-light-green order-lg-2">
                  <h3 className="h5 card-title text-center my-4">Rewards</h3>
                  {activeContest.prizes && activeContest.prizes.length > 0 ? (
                    <div className="row row-cols-3 align-items-end px-2 px-sm-0">
                      <div className="col">
                        <img
                          className="w-75 "
                          src={pl2}
                          alt="Contest 2nd place"
                        />
                        <div className="h6 mt-1">
                          {activeContest.prizes[1].usdt} USDT
                          <div className="small font-weight-normal my-2">
                            or
                          </div>
                          {activeContest.prizes[1].months} Months Full Access
                        </div>
                      </div>
                      <div className="col">
                        <img
                          className="w-100"
                          src={pl1}
                          alt="Contest 1nd place"
                        />
                        <div className="h6 mt-1">
                          {activeContest.prizes[0].usdt} USDT
                          <div className="small font-weight-normal my-2">
                            or
                          </div>
                          {activeContest.prizes[0].months} Months Full Access
                        </div>
                      </div>
                      <div className="col">
                        <img
                          className="w-75"
                          src={pl3}
                          alt="Contest 3nd place"
                        />
                        <div className="h6 mt-1">
                          {activeContest.prizes[2].usdt} USDT
                          <div className="small font-weight-normal my-2">
                            or
                          </div>
                          {activeContest.prizes[2].months} Months Full Access
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="text-center mx-auto">
                      <div
                        className="loading-img cursor-help my-5"
                        title="Loading.."
                      >
                        <img src={loading} alt="" />
                      </div>
                    </div>
                  )}
                  <div className="row row-cols-1 my-3 mx-2 mx-lg-0">
                    <img
                      className="w-100"
                      src={plRest}
                      alt="Contest 4th-10th place"
                    />
                    <div className="mt-1">1 Month Full Access</div>
                  </div>
                </div>
                <div className="col text-left order-lg-1">
                  <h3 className="h5 card-title text-center my-4">Rules</h3>
                  <ul>
                    <li>
                      All participants create strategies for the{" "}
                      <span className="text-light-green">
                        same trading coin pair
                      </span>
                    </li>
                    <li>Make as many strategies as you want</li>
                    <li>Backtest and optimize as you see fit</li>
                    <li>
                      Submit{" "}
                      <span className="text-light-green">
                        {activeContest.maxStr
                          ? activeContest.maxStr === 1
                            ? "1 strategy"
                            : `up to ${activeContest.maxStr} strategies`
                          : "up to 3 strategies"}
                      </span>{" "}
                      to compete
                    </li>
                    <li>
                      Strategies are submitted until the start of the contest
                    </li>
                    <li>
                      All strategy{" "}
                      <span className="text-light-green">simulations</span> will
                      start{" "}
                      <span className="text-light-green">automatically</span>
                    </li>
                    <li>
                      <span className="text-light-green">
                        The most profitable
                      </span>{" "}
                      strategy wins!
                    </li>
                    <li>
                      One user wins{" "}
                      <span className="text-light-green">1 prize</span> with
                      their best strategy!
                    </li>
                    <li>
                      Open positions at the end of the contest are excluded from
                      the final result.
                    </li>
                    <li>
                      Winners from 1st to 3rd place can choose the prize type.
                    </li>
                  </ul>
                </div>
              </div>
            </>
          )}
        </div>
      </section>
    </div>
  );
};

export default ContestRules;
