import React from "react";
import logo from "./assets/images/logo-white.png";

const CommingSoon = (props) => {
  return (
    <div className="page text-center pt-5 px-3 px-md-5 pb-md-5">
      <div className="logo">
        <img src={logo} alt="" />
      </div>
      <h1 className="major alt py-4 strech">{props.title}</h1>
      <h2 className="text-info my-4 h4">Comming Soon</h2>
    </div>
  );
};

export default CommingSoon;
