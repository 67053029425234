import React, { useContext, useEffect } from "react";
import logo from "./assets/images/logo.png";
import { globalContext } from "./Context.js";
import getExchange, { initializeExchanges } from "./Exchange.js";
import loading from "./assets/images/loading.gif";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRunning } from "@fortawesome/free-solid-svg-icons";
import Footer from "./Footer";
import { convertOldStrategyDemo } from "./utils.js";

const Demo = (props) => {
  let { state, dispatch } = useContext(globalContext);

  const startDemo = () => {
    initializeExchanges(state.app, true);
    import("./demo-content.js").then((data) => {
      Object.keys(data.demoHistoricalData).forEach((tf) => {
        data.demoHistoricalData[tf].forEach((el) => {
          if (typeof el.d === "string") {
            el.d = new Date(el.d);
          }
        });
      });

      let assets = {
        BTC: { total: 0.05, free: 0.05, locked: 0 },
        USDT: { total: 300, free: 300, locked: 0 },
        ETH: { total: 2, free: 2, locked: 0 },
        LTC: { total: 5, free: 5, locked: 0 },
        BNB: { total: 10, free: 10, locked: 0 },
        XMR: { total: 5, free: 5, locked: 0 },
        SRM: { total: 100, free: 100, locked: 0 },
        SXP: { total: 100, free: 50, locked: 50 },
        ZIL: { total: 20, free: 20, locked: 0 },
        EOS: { total: 0.2, free: 0.2, locked: 0 },
        HOT: { total: 5, free: 5, locked: 0 },
      };
      getExchange("Binance").setDemoAssets(assets);
      data.demoHistoricalData["1 minute"] = data.demoHistoricalData["1 hour"].map((el) => el);
      getExchange("Binance").setDemoHistoricalData(data.demoHistoricalData);
      dispatch({ type: "demo", payload: true });
      dispatch({ type: "assets", payload: { id: "Binance", assets: assets } });

      let strategies = [];
      let liveStatuses = {};
      let liveDetails = {};
      data.demoStrategies.forEach((oldStrategy) => {
        let oldExecution = data.demoExecutions.find((execution) => execution.strategyId === oldStrategy.id);
        let obj = convertOldStrategyDemo(oldStrategy, oldExecution);
        strategies.push(obj.strategy);
        if (obj.status) {
          obj.status.trades = obj.trades;
          obj.status.lastTrade = obj.trades[obj.trades.length - 1];
          liveStatuses[oldStrategy.id] = obj.status;
          liveDetails[oldStrategy.id] = {};
        }
      });

      dispatch({
        type: "strategies",
        payload: { strategies: strategies, liveStatuses: liveStatuses, liveDetails: liveDetails },
      });
      data.demoExecutions.forEach((execution) => {
        execution.strategy = data.demoStrategies.find((strategy) => strategy.id === execution.strategyId);
      });

      //dispatch({ type: "executions", payload: data.demoExecutions });

      dispatch({ type: "dateFrom", payload: new Date("2020-04-01 00:00:00") });
      dispatch({ type: "dateTo", payload: new Date("2020-05-01 23:59:59") });
    });
  };
  if (window.location.href.indexOf("?s=now") !== -1) {
    startDemo();
  }

  return (
    <>
      <div className="page-alt">
        <div className="pt-1 text-center">
          <h1 className="major alt mt-4 pb-4 w-75 mx-auto left-slide">
            <FontAwesomeIcon icon={faRunning} /> Demo
          </h1>
        </div>
        <div className="container">
          <h2 className="h4 card-title text-center m-4">Try without account:</h2>
          <div className="col-12 col-sm-9 col-md-5 col-lg-4  mx-auto">
            <button className="btn btn-lg btn-info btn-block text-uppercase" type="submit" onClick={() => startDemo()}>
              Start Demo
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

const QuitDemo = (props) => {
  let { state } = useContext(globalContext);
  useEffect(() => {
    getExchange("Binance").unsubscribeToPricesUpdate("Binance");
    getExchange("Binance US").unsubscribeToPricesUpdate("Binance US");
    state.logout();
  }, []);

  return (
    <div className="page">
      <div className="loading-img cursor-help mt-5 mb-5" title="Loading..">
        <img src={loading} alt="" />
      </div>
    </div>
  );
};

const DemoHome = (props) => {
  let history = useHistory();
  return (
    <div className="page">
      <span className="logo mt-4">
        <img src={logo} alt="logo" />
      </span>

      <h1 className="major mt-2 pb-4">Easy Crypto Bot Demo</h1>

      <h2 className="h4 text-center mt-4 mx-2">Use the navigation to check-out the app features!</h2>

      <div className="text-center mt-4">
        <button
          type="button"
          className="btn min-w-150 btn-dark mx-2 mx-sm-4 my-2"
          onClick={() => {
            history.push(`/strategies`);
          }}
        >
          Strategies
        </button>

        <button
          type="button"
          className="btn min-w-150 btn-info mx-2 mx-sm-4 my-2"
          onClick={() => {
            history.push(`/trading`);
          }}
        >
          Trading
        </button>
      </div>
    </div>
  );
};

export { Demo, QuitDemo, DemoHome };
