import React, { useState, useContext, useEffect, useRef } from "react";
import logo from "./assets/images/logo-white.png";
import loading from "./assets/images/loading-reverced.gif";
import { globalContext } from "./Context.js";
import {
  showInfoDialog,
  fetchWithRetry,
  readApiKeys,
  autoSync,
  autoUpdateExecutionStatus,
  checkForNewVersion,
  getUserData,
  sleep,
  formatDateTime,
  getUsers,
  autoSyncHomePage,
  autoSyncStrategyGroups,
  getStrategyGroups,
  getWatchlists,
  getMultiCharts,
  autoAppSync,
  getFromLocalStorage,
  encrypt,
} from "./utils.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faSignInAlt, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { initializeExchanges, checkCORSEnabled } from "./Exchange.js";
import { initializeTradingWorkers } from "./Bot.js";
import { ensureDate } from "./tatools.js";
import Footer from "./Footer";

let checkUserExpireIndex = 0;
async function checkUserExpire(user, dispatch) {
  if (user.isExpired) {
    return;
  }
  checkUserExpireIndex++;
  let checkUserExpireIndexTmp = checkUserExpireIndex;
  while (true) {
    await sleep(1000 * 60 * 60);
    if (checkUserExpireIndexTmp !== checkUserExpireIndex) {
      break;
    }
    let now = new Date();
    let expireDate = ensureDate(user.expireDate);
    if (now > expireDate) {
      showInfoDialog(
        dispatch,
        <span className="text-danger">
          <FontAwesomeIcon icon={faExclamationTriangle} /> Subscription expired
        </span>,
        <div>
          Your subscription has expired!
          <br />
          Your account is now limited to the Free plan.
          <br />
          All strategies in simulation and live trading were stopped.
        </div>
      );
      dispatch({
        type: "userExpired",
        payload: {},
      });
      dispatch({
        type: "stopAllExecutions",
        payload: { dispatch: dispatch },
      });
      break;
    } else {
      now.setHours(now.getHours() + 24);
      if (now > expireDate) {
        dispatch({ type: "subscriptionIsExpiring", payload: true });
        showInfoDialog(
          dispatch,
          <span className="text-warning">
            <FontAwesomeIcon icon={faExclamationTriangle} /> Subscription warning
          </span>,
          <div>Your subscription will expire on {user.expireDate}</div>
        );
      } else {
        now.setHours(now.getHours() + 24 * 6);
        if (now > expireDate) {
          dispatch({ type: "subscriptionIsExpiring", payload: true });
        }
      }
    }
  }
}

async function signIn(state, dispatch, setSignInPhase, setErrorMsg, email, password, rememberMe, subAccount, subAccId) {
  setSignInPhase(1);
  if (rememberMe) {
    localStorage.setItem("ecbUsername", email);
  } else {
    localStorage.removeItem("ecbUsername");
  }
  const endpoint = "https://api2.easycryptobot.com/login.php";
  let params = {
    email: email,
    password: password,
    subAccId: subAccount ? subAccId : null,
  };

  await fetchWithRetry(
    endpoint,
    "post",
    params,
    async (json) => {
      if (!state.app && !state.demo) {
        let useInBrowser = await checkCORSEnabled();
        if (useInBrowser) {
          state.app = true;
          dispatch({
            type: "setIsApp",
            payload: {},
          });
        }
      }

      try {
        let userData = encrypt(JSON.stringify(json.user), `ECB512-${json.token}`);
        localStorage.setItem("h", userData);
      } catch (e) {}

      initializeExchanges(state.app, state.demo, true, json.user.id);

      dispatch({
        type: "user",
        payload: {
          token: json.token,
          id: json.user.id,
          name: json.user.name,
          email: json.user.email,
          expireDate: formatDateTime(new Date(json.user.expireDate)),
          isExpired: json.user.isExpired,
          refCode: json.user.refCode,
          subscriptionType: json.user.subscriptionType,
          parent: json.user.parent,
          dispatch: dispatch,
        },
      });

      json.user.isAdmin = json.user.email === "admin@easycryptobot.com";
      json.user.isContestRunner = json.user.email === "contest@easycryptobot.com";
      //setSignInPhase(0);
      state.login();
      let userData = { strategies: [], executions: [] };
      if (!state.demo && !json.user.isAdmin) {
        userData = await getUserData(json.user, dispatch, json.token, state.app);
        autoSyncHomePage(json.token, json.user.id);
        getStrategyGroups(json.token, dispatch, json.user.id);
        getWatchlists(json.token, dispatch, json.user.id);
        getMultiCharts(json.token, dispatch, json.user.id);
        autoSyncStrategyGroups(json.token, json.user.id);
      }

      if (state.app && !state.demo && !json.user.isAdmin) {
        let appId = localStorage.getItem("appId");
        if (!appId) {
          appId = `${Math.floor(Math.random() * 99 + 100)}${new Date().getTime()}`;
          localStorage.setItem("appId", appId);
        }
        autoAppSync(json.token, dispatch, appId);
        let apiKeys = await readApiKeys(dispatch, json.user.id, json.user.email);

        await initializeTradingWorkers(dispatch, apiKeys, userData, json.user.id, json.token, json.user.isExpired);
        autoSync(json.token, json.user);
      }
      if (!state.app && !state.demo && !json.user.isAdmin) {
        //TODO
        autoUpdateExecutionStatus(json.token, json.user.id, dispatch, json.user.isAdmin);
      }
      if (json.user.isAdmin) {
        getUsers(json.token, dispatch);
      }

      checkForNewVersion(dispatch, state.version);
    },
    async (response) => {
      setSignInPhase(2);
      if (response) {
        try {
          const json = await response.json();
          if (json.message) {
            setErrorMsg(json.message);
            return;
          }
        } catch (e) {}
      }
      setErrorMsg("Wrong username or password!");
    },
    {},
    1
  );
}

async function forgottenPassword(email) {
  await fetchWithRetry("https://api2.easycryptobot.com/forgotten-password.php", "post", {
    email: email,
  });
}

const SignInLoading = (props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="page intro">
      <div className="loading-img-big cursor-help mt-5 mb-3" title="Signing in..">
        <img src={loading} alt="" />
      </div>
      <h3 className="loading">Signing in</h3>
    </div>
  );
};

const SignIn = (props) => {
  const [signInPhase, setSignInPhase] = useState(0);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [logouted, setLogouted] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [subAccount, setSubAccount] = useState(false);
  const [subAccountId, setSubAccountId] = useState(getFromLocalStorage("subAccId", ""));
  const [errorMsg, setErrorMsg] = useState("Wrong Username or Password");
  const { state, dispatch } = useContext(globalContext);
  const [showFooter, setShowFooter] = useState(false);
  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    let rememberMeSetting = localStorage.getItem("ecbRememberMe");
    if (rememberMeSetting && rememberMeSetting === "true") {
      setRememberMe(true);
      let userName = localStorage.getItem("ecbUsername");
      if (rememberMeSetting) {
        setUsername(userName);
      }
    }
    let logouted = localStorage.getItem("logouted");
    if (logouted) {
      localStorage.removeItem("logouted");
      setLogouted(true);
    }

    let subAcc = localStorage.getItem("subAcc");
    if (subAcc && subAcc === "true") {
      setSubAccount(true);
    }

    sleep(1000 * 1).then(() => {
      if (componentIsMounted.current) {
        setShowFooter(true);
      }
    });
  }, []);

  return signInPhase !== 1 ? (
    <>
      <div className="page-alt user-select-none min-h-fullscreen pb-5">
        <div className="d-none d-sm-block">
          <span className="logo mt-4">
            <img src={logo} alt="logo" />
          </span>

          <h1 className="major alt mt-2 pb-4 w-75 mx-auto">Easy Crypto Bot</h1>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-sm-9 col-md-7 col-lg-5 mx-auto">
              <div className="card card-signin card-signin mt-5 bottom-slide">
                <div className="card-body text-dark">
                  <h2 className="h3 card-title text-center">
                    <FontAwesomeIcon icon={faSignInAlt} /> Sign In
                  </h2>
                  <form
                    className="form-signin"
                    id="signInForm"
                    onSubmit={(event) => {
                      event.preventDefault();
                      event.stopPropagation();
                      signIn(
                        state,
                        dispatch,
                        setSignInPhase,
                        setErrorMsg,
                        username,
                        password,
                        rememberMe,
                        subAccount,
                        subAccountId
                      );
                    }}
                  >
                    <div className="form-label-group mb-3">
                      <input
                        type="email"
                        id="inputEmail"
                        className="form-control focus-info"
                        placeholder="Email address"
                        required
                        autoComplete="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                      />
                    </div>

                    <div className="form-label-group mb-3">
                      <input
                        type="password"
                        id="inputPassword"
                        className="form-control focus-info"
                        placeholder="Password"
                        autoComplete="off"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    </div>

                    <div className="custom-control custom-checkbox mb-1">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="rememberMe"
                        checked={rememberMe}
                        onChange={(e) => {
                          localStorage.setItem("ecbRememberMe", e.target.checked);
                          setRememberMe(e.target.checked);
                        }}
                      />
                      <label className="custom-control-label text-left w-105" htmlFor="rememberMe">
                        Remember Me
                      </label>
                    </div>
                    <div className={`custom-control custom-checkbox ${subAccount ? "mb-2" : "mb-3"}`}>
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="setSubAccount"
                        checked={subAccount}
                        onChange={(e) => {
                          localStorage.setItem("subAcc", e.target.checked);
                          setSubAccount(e.target.checked);
                        }}
                      />
                      <label className="custom-control-label text-left w-105" htmlFor="setSubAccount">
                        Sub Account
                      </label>
                    </div>
                    {subAccount && (
                      <div className="d-flex fade-in-fast mb-3">
                        <input
                          type="text"
                          id="inputSubAcc"
                          className="form-control focus-info h-33 mx-auto"
                          placeholder="Sub Account ID"
                          required
                          autoComplete="username"
                          value={subAccountId}
                          onChange={(e) => {
                            localStorage.setItem("subAccId", e.target.value);
                            setSubAccountId(e.target.value);
                          }}
                        />
                      </div>
                    )}

                    <button className="btn btn-info btn-block text-uppercase" type="submit">
                      Sign in
                    </button>
                    <a
                      href="#/"
                      className="text-info text-muted d-inline-block mt-2"
                      onClick={(e) => {
                        e.preventDefault();
                        if (document.getElementById("inputEmail").checkValidity()) {
                          let header = (
                            <span className="text-info">
                              <FontAwesomeIcon icon={faEnvelope} /> Email sent
                            </span>
                          );
                          showInfoDialog(dispatch, header, `A password reset link was sent to ${username}.`);
                          forgottenPassword(username);
                        } else {
                          document.getElementById("inputEmail").focus();
                        }
                      }}
                    >
                      Forgotten Password
                    </a>
                    {signInPhase === 2 && <h5 className="text-danger mt-2">{errorMsg}</h5>}
                    {logouted && (
                      <h5 className="text-danger small mt-2">
                        <div>You loged in from another app</div>
                        <div className="mt-1">Running multiple apps at the same time is not permited</div>
                      </h5>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {showFooter && <Footer classes="fade-in" />}
    </>
  ) : (
    <SignInLoading />
  );
};

const Logout = (props) => {
  let { state, dispatch } = useContext(globalContext);

  useEffect(() => {
    dispatch({ type: "demo", payload: false });
  }, []);

  return (
    <div className="page">
      <span className="logo mt-4">
        <img src={logo} alt="logo" />
      </span>
      <h1 className="major mt-2 pb-4">Easy Crypto Bot</h1>;
    </div>
  );
};

export default SignIn;
export { SignIn, SignInLoading, Logout, checkUserExpire };
