import React, { useContext, useState, useEffect, useRef } from "react";

import { globalContext } from "./Context.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCog,
  faExclamationTriangle,
  faInfoCircle,
  faPlus,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import getExchange from "./Exchange.js";
import ReactEcharts from "echarts-for-react";
import $ from "jquery";
import loading from "./assets/images/loading-reverced.gif";
import {
  getUsdValue,
  showConfirmDialog,
  pricesUpdateDelay,
  binanceMinPairs,
  binanceUSMinPairs,
  showInfoDialog,
} from "./utils.js";
import { AddExchangeModal } from "./Settings.js";
import { exchanges } from "./constants/consts.json";
import Dropdown from "./Dropdown.js";
import { ModalConfirm } from "./Modal.js";
import ResizablePanels from "./ResizablePanels.js";
import ManualTrading from "./ManualTrading.js";

const AssetsChart = (props) => {
  const [series, setSeries] = useState(null);

  useEffect(() => {
    if (!props.assets || props.assets.length === 0) {
      setSeries(null);
      return;
    }
    let series = [];
    let others = 0;
    let treshHold = props.accountValueUsd ? props.accountValueUsd * 0.02 : 5;
    props.assets.forEach((asset) => {
      if (asset.usdValue > treshHold) {
        series.push({ name: asset.asset, value: asset.usdValue });
      } else {
        others += asset.usdValue;
      }
    });
    if (others > 0) {
      series.push({ name: "Other", value: others });
    }
    series.sort((a, b) => a.value - b.value);
    setSeries(series);
  }, [props.assets]);

  return series ? (
    <ReactEcharts
      lazyUpdate={true}
      style={{ height: "250px", left: 0, top: 0 }}
      /*opts={{ renderer: "svg" }}*/
      option={{
        backgroundColor: "transparent",
        title: {
          text: props.balance,
          left: "center",
          top: 0,
          textStyle: {
            color: "#fff",
            fontSize: 14,
          },
        },

        tooltip: {
          trigger: "item",
        },

        series: [
          {
            name: "Assets",
            type: "pie",
            radius: "55%",
            center: ["50%", "55%"],
            data:
              series.length !== 0
                ? series
                : [
                    {
                      name: "NONE",
                      value: 100,
                    },
                  ],
            label: {
              color: "rgba(255, 255, 255, 0.7)",
            },
            labelLine: {
              lineStyle: {
                color: "rgba(255, 255, 255, 0.7)",
              },
            },
            itemStyle: {
              shadowBlur: 5,
              shadowColor: "rgba(255, 255, 255, 0.7)",
            },

            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },

            animationType: "scale",
            animationEasing: "elasticOut",
            animationDelay: function (idx) {
              return Math.random() * 200;
            },
          },
        ],
      }}
    />
  ) : (
    ""
  );
};

const Accounts = (props) => {
  let { state, dispatch } = useContext(globalContext);
  const [apiKey, setApiKey] = useState("");
  const [apiSecret, setApiSecret] = useState("");
  const [exchange, setExchange] = useState(exchanges[0]);
  const [editExchange, setEditExchange] = useState(false);
  const [pricesBinance, setPricesBinance] = useState({});
  const [pricesBinanceUs, setPricesBinanceUs] = useState({});
  const [accountsValueUsd, setAccountsValueUsd] = useState({});
  const componentIsMounted = useRef(true);
  const pricesBinanceLastUpdate = useRef(null);
  const pricesBinanceUsLastUpdate = useRef(null);
  const pricesBinancePairsCount = useRef(0);
  const pricesBinanceUsPairsCount = useRef(0);
  const [selectedAccountAssets, setSelectedAccountAssets] = useState(null);
  const [topPanelHeight, setTopPanelHeight] = useState(395);
  const [panel] = useState({ type: 1 });

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    getExchange("Binance").subscribeToPricesUpdate("accounts", (prices, pricesStats) => {
      if (componentIsMounted.current) {
        let now = new Date();
        if (
          !pricesBinanceLastUpdate.current ||
          now.getTime() > pricesBinanceLastUpdate.current ||
          pricesBinancePairsCount.current < binanceMinPairs
        ) {
          now.setSeconds(now.getSeconds() + pricesUpdateDelay);
          pricesBinanceLastUpdate.current = now.getTime();
          pricesBinancePairsCount.current = Object.keys(prices).length;
          setPricesBinance({
            prices: prices,
            pricesStats: pricesStats,
          });
        }
      }
    });
    getExchange("Binance US").subscribeToPricesUpdate("accounts", (prices, pricesStats) => {
      if (componentIsMounted.current) {
        let now = new Date();
        if (
          !pricesBinanceUsLastUpdate.current ||
          now.getTime() > pricesBinanceUsLastUpdate.current ||
          pricesBinanceUsPairsCount.current < binanceUSMinPairs
        ) {
          now.setSeconds(now.getSeconds() + pricesUpdateDelay);
          pricesBinanceUsLastUpdate.current = now.getTime();
          pricesBinanceUsPairsCount.current = Object.keys(prices).length;
          setPricesBinanceUs({
            prices: prices,
            pricesStats: pricesStats,
          });
        }
      }
    });
    return () => {
      getExchange("Binance").unsubscribeToPricesUpdate("accounts");
      getExchange("Binance US").unsubscribeToPricesUpdate("accounts");
    };
  }, [state.user]);

  useEffect(() => {
    if (state.exchangesInitialized) {
      (async () => {
        let accountsValueUsd = {};
        for (let exchange in state.exchanges) {
          accountsValueUsd[exchange] = await getExchange(exchange).getAcountBalanceInUsd();
        }
        if (componentIsMounted.current) {
          setAccountsValueUsd(accountsValueUsd);
        }
      })();
    }
  }, [state.exchanges, state.exchangesInitialized, pricesBinance, pricesBinanceUs]);

  useEffect(() => {
    if (!props.selectedAccount && Object.keys(state.exchanges)[0]) {
      props.setSelectedAccount(Object.keys(state.exchanges)[0]);
    }
  }, [state.exchanges, state.exchangesInitialized]);

  useEffect(() => {
    if (props.selectedAccount) {
      let assets = state.assets[props.selectedAccount];
      let exchangeData = {};
      switch (props.selectedAccount) {
        case "Binance":
          exchangeData = pricesBinance;
          break;
        case "Binance US":
          exchangeData = pricesBinanceUs;
          break;
        default:
      }

      let assetsArr = [];
      if (assets) {
        if (exchangeData && exchangeData.prices && Object.keys(exchangeData.prices).length > 0) {
          assetsArr = Object.keys(assets).map((asset) => {
            let usdValue = getUsdValue(assets[asset].total, asset, exchangeData.prices);
            return {
              asset: asset,
              balance: assets[asset].total,
              free: assets[asset].free,
              usdValue: usdValue ? usdValue : 0,
            };
          });
        }
        assetsArr.sort((a, b) => b.usdValue - a.usdValue);
      }
      setSelectedAccountAssets(assetsArr);
    } else {
      setSelectedAccountAssets(null);
    }
  }, [props.selectedAccount, pricesBinance, pricesBinanceUs]);

  return (
    <div className="fade-in">
      {state.exchangesInitialized ? (
        Object.keys(state.exchanges).length === 0 ? (
          <div className="row row-cols-md-2 row-cols-lg-3 row-cols-xl-4 row-cols-xxl-6 mx-2 mx-md-0 small">
            <div
              className="col d-flex flex-column align-items-center justify-content-center text-info hover-bg-info rounded h-180"
              onClick={(e) => {
                e.preventDefault();
                setExchange(exchanges[0]);
                setEditExchange(false);
                setApiKey("");
                setApiSecret("");
                if (!$("#addApiKeys").is(":visible")) {
                  $("#addApiKeys").modal("toggle");
                }
              }}
            >
              <FontAwesomeIcon className="h2" icon={faPlus} />
              <div>Add account</div>
            </div>
          </div>
        ) : props.smallWidthDevice ? (
          <AccountPanel
            accountsValueUsd={accountsValueUsd}
            pricesBinanceUs={pricesBinanceUs}
            pricesBinance={pricesBinance}
            selectedAccountAssets={selectedAccountAssets}
            exchange={exchange}
            selectedAccount={props.selectedAccount}
            setSelectedAccount={props.setSelectedAccount}
            setExchange={setExchange}
            setEditExchange={setEditExchange}
            setApiKey={setApiKey}
            setApiSecret={setApiSecret}
          />
        ) : (
          <>
            <div id="panelTop" className="overflow-hidden fade-in py-2">
              <AccountPanel
                accountsValueUsd={accountsValueUsd}
                pricesBinanceUs={pricesBinanceUs}
                pricesBinance={pricesBinance}
                selectedAccountAssets={selectedAccountAssets}
                exchange={exchange}
                selectedAccount={props.selectedAccount}
                setSelectedAccount={props.setSelectedAccount}
                setExchange={setExchange}
                setEditExchange={setEditExchange}
                setApiKey={setApiKey}
                setApiSecret={setApiSecret}
                topPanelHeight={topPanelHeight}
              />
            </div>

            <div id="dragY"></div>
            <div id="panelBottom" className="overflow-auto">
              <ManualTrading
                tradingPair={props.tradingPair}
                setTradingPair={props.setTradingPair}
                selectedAccount={props.selectedAccount}
                setSelectedAccount={props.setSelectedAccount}
              />
            </div>

            <ResizablePanels
              topPanelSizeDefault={"60vh"}
              minTop={100}
              topHeightOnChange={setTopPanelHeight}
              panel={panel}
              storePanel={() => {}}
            />
          </>
        )
      ) : (
        <div className="text-center mx-auto">
          <div className="loading-img cursor-help my-5" title="Loading..">
            <img src={loading} alt="" />
          </div>
        </div>
      )}
      <ConvertSmallToBnb assets={selectedAccountAssets} selectedAccount={props.selectedAccount} />
      <AddExchangeModal
        apiKey={apiKey}
        setApiKey={setApiKey}
        apiSecret={apiSecret}
        setApiSecret={setApiSecret}
        exchange={exchange}
        setExchange={setExchange}
        editExchange={editExchange}
        fn={(exchange) => {
          props.setSelectedAccount(exchange);
        }}
      />
    </div>
  );
};

const AccountPanel = (props) => {
  let { state, dispatch } = useContext(globalContext);

  return (
    <>
      <div
        className={`row mx-2 mx-md-0 ${props.topPanelHeight ? "overflow-auto" : ""}`}
        style={props.topPanelHeight ? { height: props.topPanelHeight - 10 } : {}}
      >
        <div className="col-12 col-md-6 mb-2 px-0">
          <div className="d-flex justify-content-between mb-2">
            <Dropdown
              btnClasses="d-flex text-light hover-border-bottom info pl-0 pb-2"
              arrow
              arrowClasses="mt-1"
              selectedClasses="text-truncate max-w-270 mr-1"
              menuClasses="bg-new-dark border border-secondary rounded text-left small max-h-fullscreen w-150 py-1"
              menuItemsClasses="text-light hover-info px-3 py-1"
              selected={props.selectedAccount ? props.selectedAccount : "Accounts"}
              setSelected={(exchange) => {
                props.setSelectedAccount(exchange);
              }}
              items={Object.keys(state.exchanges)}
              additionalOptions={[
                {
                  label: (
                    <div className="d-flex">
                      Add account
                      <FontAwesomeIcon className="ml-auto mt-1" icon={faPlus} />
                    </div>
                  ),
                  classes: "text-info border-bottom border-secondary px-3 pt-1 pb-2",
                  fn: () => {
                    props.setExchange(exchanges[0]);
                    props.setEditExchange(false);
                    props.setApiKey("");
                    props.setApiSecret("");
                    if (!$("#addApiKeys").is(":visible")) {
                      $("#addApiKeys").modal("toggle");
                    }
                  },
                },
              ]}
            />

            <Dropdown
              btnClasses="text-info hover-light"
              selectedClasses=""
              menuClasses="bg-new-dark border border-secondary rounded text-left small text-nowrap max-h-fullscreen right-0 py-1"
              menuItemsClasses="text-light hover-info px-3 py-1"
              selected={<FontAwesomeIcon className="hover-light" icon={faCog} />}
              setSelected={() => {}}
              items={[]}
              additionalOptions={[
                {
                  label: "Edit API Keys",
                  classes: "text-info border-bottom border-secondary px-3 pt-1 pb-2",
                  fn: () => {
                    props.setExchange(props.selectedAccount);
                    props.setEditExchange(true);
                    props.setApiKey("");
                    props.setApiSecret("");
                    if (!$("#addApiKeys").is(":visible")) {
                      $("#addApiKeys").modal("toggle");
                    }
                  },
                },
                {
                  label: "Delete",
                  classes: "text-info px-3 pt-2 pb-1",
                  fn: () => {
                    showConfirmDialog(
                      dispatch,
                      <span className="text-info">
                        <FontAwesomeIcon icon={faQuestionCircle} /> Remove Exchange
                      </span>,
                      <>
                        Are you sure you want to remove <b>{props.selectedAccount}</b> exchange?
                      </>,
                      () => {
                        props.setSelectedAccount(null);
                        dispatch({
                          type: "removeExchange",
                          payload: props.selectedAccount,
                        });
                      }
                    );
                  },
                },
              ]}
            />
          </div>

          {state.exchanges[props.selectedAccount] && !state.exchanges[props.selectedAccount].valid && (
            <div className="text-secondary mb-3">
              Invalid API-key or your IP address is not in the trusted IPs list on Binance
            </div>
          )}

          {state.exchanges[props.selectedAccount] && state.exchanges[props.selectedAccount].valid && (
            <div className="table-responsive rounded overflow-auto border border-dark mb-4 user-select-none ">
              <table className="table rounded table-sm small table-new-dark table-hover sortable-table mb-0">
                <thead className="thead-new-dark">
                  <tr>
                    <th className="text-left pl-3">Asset</th>
                    <th className="text-left text-nowrap">Balance</th>
                    <th className="text-left text-nowrap">Free</th>
                    <th className="text-left text-nowrap">$ Value</th>
                  </tr>
                </thead>
                <tbody>
                  {props.selectedAccountAssets &&
                    props.selectedAccountAssets.map((asset, index) => {
                      return (
                        asset.usdValue > 0 && (
                          <tr key={index}>
                            <td className="text-left pl-3">{asset.asset}</td>
                            <td className="text-left">{asset.balance}</td>
                            <td className="text-left">{asset.free}</td>
                            <td className="text-left">{"$" + asset.usdValue}</td>
                          </tr>
                        )
                      );
                    })}
                </tbody>
              </table>
            </div>
          )}
        </div>
        {state.exchanges[props.selectedAccount] && state.exchanges[props.selectedAccount].valid && (
          <div className="col-12 col-md-6 mb-2 pb-3">
            <AssetsChart
              assets={props.selectedAccountAssets}
              prices={props.selectedAccount === "Binance" ? props.pricesBinance.prices : props.pricesBinanceUs.prices}
              exchange={props.exchange}
              accountValueUsd={props.accountsValueUsd[props.selectedAccount]}
              balance={
                props.accountsValueUsd[props.selectedAccount]
                  ? `Balance: $${props.accountsValueUsd[props.selectedAccount].toFixed(2)}`
                  : ""
              }
            />
            {props.selectedAccountAssets && props.selectedAccountAssets.length > 0 && (
              <a
                href="#/"
                className="btn btn-sm btn-info mt-3"
                onClick={(e) => {
                  e.preventDefault();
                  document.activeElement.blur();
                  if (!$("#convertSmallToBnb").is(":visible")) {
                    $("#convertSmallToBnb").modal("toggle");
                  }
                }}
              >
                Convert Small Balance to BNB
              </a>
            )}
          </div>
        )}
      </div>
    </>
  );
};

const ConvertSmallToBnb = (props) => {
  let { state, dispatch } = useContext(globalContext);
  const [coinsToConvert, setCoinsToConvert] = useState({});
  const [selectAll, setSelectAll] = useState(false);
  const [avaiableAssets, setAvaiableAssets] = useState([]);
  const componentIsMounted = useRef(true);

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    setCoinsToConvert({});
    setAvaiableAssets([]);
  }, [props.selectedAccount]);

  useEffect(() => {
    if (props.assets) {
      let assets = props.assets.filter((asset) => asset.usdValue > 0 && asset.usdValue <= 20);
      assets.sort((a, b) => b.usdValue - a.usdValue);
      setAvaiableAssets(assets);
    }
  }, [props.assets]);

  return (
    <ModalConfirm
      id="convertSmallToBnb"
      header={<span className="text-info">Convert Small Balance to BNB</span>}
      okText="CONVERT"
      closeFn={() => {
        if (componentIsMounted.current) {
          setCoinsToConvert({});
        }
      }}
      content={
        <div className="font-normal">
          <div className="mb-2">You can convert small balances to BNB once every 6 hours.</div>
          <div className="">
            {props.assets && (
              <div className="">
                <div className="table-responsive border-top border-dark overflow-auto white max-h-60-vh">
                  <table className="table table-sm small table-hover">
                    <thead className="border-dark border-bottom">
                      <tr>
                        <th className="text-left w-30 pl-2 pr-0">
                          <div className="custom-control custom-checkbox" key="All">
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              id="allCoins"
                              checked={selectAll}
                              onChange={(e) => {
                                if (!selectAll) {
                                  avaiableAssets.forEach((asset) => (coinsToConvert[asset.asset] = true));
                                } else {
                                  setCoinsToConvert({});
                                }
                                setSelectAll(!selectAll);
                              }}
                            />
                            <label type="text" className="custom-control-label" htmlFor="allCoins" />
                          </div>
                        </th>
                        <th className="text-left text-nowrap align-middle pl-0">Coin</th>
                        <th className="text-left text-nowrap align-middle">Balance</th>
                        <th className="text-left text-nowrap align-middle">$ Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      {avaiableAssets.map((asset) => (
                        <tr
                          key={asset.asset}
                          className="cursor-pointer"
                          onClick={(e) => {
                            if (coinsToConvert[asset.asset]) {
                              delete coinsToConvert[asset.asset];
                              setSelectAll(false);
                            } else {
                              coinsToConvert[asset.asset] = true;
                              if (Object.keys(coinsToConvert).length === avaiableAssets.length) {
                                setSelectAll(true);
                              }
                            }
                            setCoinsToConvert({ ...coinsToConvert });
                          }}
                        >
                          <td className="pl-2 pr-0 w-30">
                            <div className="custom-control custom-checkbox d-inline-block ">
                              <input
                                type="checkbox"
                                className="custom-control-input cursor-pointer"
                                id={`c${asset.asset}`}
                                checked={coinsToConvert[asset.asset] ? true : false}
                                onChange={(e) => {
                                  if (coinsToConvert[asset.asset]) {
                                    delete coinsToConvert[asset.asset];
                                    setSelectAll(false);
                                  } else {
                                    coinsToConvert[asset.asset] = true;
                                    if (Object.keys(coinsToConvert).length === avaiableAssets.length) {
                                      setSelectAll(true);
                                    }
                                  }
                                  setCoinsToConvert({ ...coinsToConvert });
                                }}
                              />
                              <label type="text" className="custom-control-label" htmlFor={`c${asset.asset}`} />
                            </div>
                          </td>
                          <td className="align-middle pl-0">{asset.asset}</td>
                          <td className="align-middle">{asset.balance}</td>
                          <td className="align-middle text-nowrap">${asset.usdValue}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </div>
        </div>
      }
      func={() => {
        let coins = Object.keys(coinsToConvert);
        if (coins.length === 0 || state.demo) {
          return;
        }
        getExchange(props.selectedAccount)
          .convertAssetsToBnb(coins)
          .then((result) => {
            if (result.errorMsg) {
              showInfoDialog(
                dispatch,
                <span className="text-danger">
                  <FontAwesomeIcon icon={faExclamationTriangle} /> Conversion Failed
                </span>,
                <>{result.errorMsg}</>
              );
            } else {
              showInfoDialog(
                dispatch,
                <span className="text-info">
                  <FontAwesomeIcon icon={faInfoCircle} /> Conversion Completed
                </span>,
                <>
                  Total received: <span className="text-info">{result.totalTransfered} BNB</span>
                  <br />
                  Conversion fee: <span className="text-info">{result.totalServiceCharge} BNB</span>
                  <br />
                </>
              );
            }
          });
        setCoinsToConvert({});
        setSelectAll(false);
      }}
    />
  );
};

export default Accounts;
