import React, { useContext, useState, useEffect, useRef } from "react";
import { globalContext } from "./Context";
import $ from "jquery";
import ReactEcharts from "echarts-for-react";
import echarts from "echarts/lib/echarts";
import loading from "./assets/images/loading-reverced.gif";
import { InspectStrategy } from "./Strategies.js";
import {
  formatDate2,
  formatDateTime2,
  getIndicatorSetup,
  getInterval,
  getTimeframeFromInterval,
  showInfoDialog,
  sleep,
  stringToDate2,
  attachCumulativesData,
  attachTradesData,
  getFromLocalStorage,
  getLimits,
  formatDateTime3,
} from "./utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDoubleRight,
  faChevronDown,
  faChevronUp,
  faExclamationTriangle,
  faInfoCircle,
  faQuestionCircle,
  faStop,
  faTimesCircle,
  faTrophy,
  faSave,
  faAngleDown,
  faAngleUp,
} from "@fortawesome/free-solid-svg-icons";
import { RadioButtons } from "./RadioButtons";
import { startOptimization, stopOptimization } from "./Bot";
import loadingReverced from "./assets/images/loading-reverced.gif";
import getEcbDatabase from "./EcbDatabase";
import { getTimeframes } from "./tatools";
import { StaticChartWithIndicators } from "./Charts";
import getExchange from "./Exchange.js";

const OptimizationProgress = (props) => {
  let { dispatch } = useContext(globalContext);
  return (
    <>
      {props.runningOptimization.completed === -1 ? (
        <div className="d-flex align-items-center">
          <div className="loading-white mr-1" title="Waiting for another Optimization to finish">
            <img src={loadingReverced} alt="" />
          </div>
          <div className="text-info flex-grow-1 cursor-help" title="Waiting for another Optimization to finish">
            Added to queue
          </div>
          <a
            href="#/"
            className="text-danger ml-1 font-normal"
            title="Stop Optimization"
            onClick={(e) => {
              e.preventDefault();
              document.activeElement.blur();
              stopOptimization(props.strategy.id, dispatch);
            }}
          >
            <FontAwesomeIcon icon={faStop} />
          </a>
        </div>
      ) : (
        <div className="d-flex position-relative align-items-center">
          <div className="progress flex-grow-1">
            <div
              className="progress-bar progress-bar-striped bg-info text-nowrap"
              role="progressbar"
              aria-valuenow={props.runningOptimization.completed}
              aria-valuemin={0}
              aria-valuemax={100}
              style={{
                width: props.runningOptimization.completed + "%",
              }}
            >
              {props.runningOptimization.completed}%&nbsp;&nbsp;&nbsp;
              {props.runningOptimization.completed > 70 && props.runningOptimization.eta}
            </div>
            {props.runningOptimization.downloaded >= 0 && props.runningOptimization.downloaded < 100 && (
              <div title="Getting Historical Data from Exchange" className="eta text-info">
                Getting data
                {props.runningOptimization.downloaded > 0 && <>: {Math.floor(props.runningOptimization.downloaded)}%</>}
              </div>
            )}
            {props.runningOptimization.completed === 0 && props.runningOptimization.downloaded === 100 && (
              <div title="Creating Variations" className="eta text-info">
                Creating variations...
              </div>
            )}
            {props.runningOptimization.completed >= 0 && props.runningOptimization.completed < 70 && (
              <div title="ETA" className="text-info mt-2 ml-2">
                {props.runningOptimization.eta}
              </div>
            )}
          </div>
          <a
            href="#/"
            className="text-danger ml-1 font-normal"
            title="Stop Optimization"
            onClick={(e) => {
              e.preventDefault();
              document.activeElement.blur();
              stopOptimization(props.strategy.id, dispatch);
            }}
          >
            <FontAwesomeIcon icon={faStop} />
          </a>
        </div>
      )}
    </>
  );
};

const OptimizationSettings = (props) => {
  const { state, dispatch } = useContext(globalContext);
  const [commissionRate, setCommissionRate] = useState(state.commissionRate);
  const [dateFrom, setDateFrom] = useState(formatDateTime2(state.dateFrom));
  const [dateTo, setDateTo] = useState(formatDateTime2(state.dateTo));
  const [tradeSize, setTradeSize] = useState(state.btSize);
  const [skipedBuyRules, setSkipedBuyRules] = useState([]);
  const [skipedSellRules, setSkipedSellRules] = useState([]);
  const [shownOptimizationResult, setShownOptimizationResult] = useState(false);
  const [recentOptimizations, setRecentOptimizations] = useState([]);
  const [optimizationBuyRules, setOptimizationBuyRules] = useState(getFromLocalStorage("optimizationBuyRules", "all"));
  const [optimizationSellRules, setOptimizationSellRules] = useState(
    getFromLocalStorage("optimizationSellRules", "all")
  );
  const [optimizationAdvanced, setOptimizationAdvanced] = useState(getFromLocalStorage("optimizationAdvanced", "n"));

  const strategyId = useRef(null);
  const componentIsMounted = useRef(true);

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (!props.strategy) {
      return;
    }
    if (optimizationBuyRules === "all") {
      setSkipedBuyRules([]);
    } else if (optimizationBuyRules === "none") {
      let skipedRulesTmp = props.strategy.buyRules.map((el) => el.id);
      setSkipedBuyRules(skipedRulesTmp);
    }
  }, [props.strategy, optimizationBuyRules]);

  useEffect(() => {
    if (!props.strategy) {
      return;
    }
    if (optimizationSellRules === "all") {
      setSkipedSellRules([]);
    } else if (optimizationSellRules === "none") {
      let skipedRulesTmp = props.strategy.sellRules.map((el) => el.id);
      setSkipedSellRules(skipedRulesTmp);
    }
  }, [props.strategy, optimizationSellRules]);

  useEffect(() => {
    if (!props.strategy) {
      strategyId.current = null;
      setRecentOptimizations([]);
      return;
    }
    strategyId.current = props.strategy.id;
    setRecentOptimizations([]);
    setShownOptimizationResult(props.runningOptimization && props.runningOptimization.completed === 100);
    let strategyIdTmp = props.strategy.id;
    getEcbDatabase()
      .getOptimizations(props.strategy.id)
      .then((opts) => {
        if (componentIsMounted.current && strategyId.current === strategyIdTmp) {
          setRecentOptimizations(opts);
        }
      });
  }, [props.strategy]);

  useEffect(() => {
    if (props.runningOptimization && props.runningOptimization.completed === 100 && !shownOptimizationResult) {
      let strategyIdTmp = props.strategy.id;
      getEcbDatabase()
        .getOptimizations(props.strategy.id)
        .then((opts) => {
          if (componentIsMounted.current && strategyId.current === strategyIdTmp) {
            setRecentOptimizations(opts);
            setShownOptimizationResult(true);
          }
        });
    }
  }, [props.optimizations]);

  return (
    props.strategy && (
      <div
        className={`${props.rightPanelWidth > 650 ? "d-flex flex-wrap" : "pr-1"} small user-select-none fade-in pl-1`}
      >
        <div className={props.rightPanelWidth > 650 ? "w-350 border-right" : "border-bottom text-center pb-4 mb-4"}>
          <div className="d-inline-block text-left w-350 ">
            <div className="pb-2">
              <div className="d-inline-block min-w-150">Commission rate:</div>
              <input
                type="number"
                className="setup-info w-165"
                value={commissionRate}
                placeholder="<Number>"
                onChange={(e) => {
                  let value = parseFloat(e.target.value);
                  value = isNaN(value) ? "" : Math.abs(value);
                  setCommissionRate(value);

                  dispatch({
                    type: "commissionRate",
                    payload: value ? value : 0,
                  });
                }}
              />
              <span className="text-info">%</span>
            </div>
            <div className="pb-2 pt-1">
              <div className="d-inline-block min-w-150">Start date:</div>
              <input
                type="datetime-local"
                className="setup-info w-165"
                value={dateFrom}
                onChange={(e) => {
                  if (state.demo) {
                    showInfoDialog(
                      dispatch,
                      <div className="text-info">
                        <FontAwesomeIcon icon={faExclamationTriangle} /> Demo mode
                      </div>,
                      "Cannot be changed in DEMO mode!"
                    );
                    return;
                  }
                  let dateFromTmp = stringToDate2(e.target.value);
                  if (!dateFromTmp || isNaN(dateFromTmp.getTime())) {
                    return;
                  }
                  let date = new Date();
                  date.setMonth(date.getMonth() - 1);
                  date.setHours(0, 0, 0, 0);
                  if (!state.app && date > dateFromTmp) {
                    showInfoDialog(
                      dispatch,
                      <div className="text-danger">
                        <FontAwesomeIcon icon={faExclamationTriangle} /> Invalid period
                      </div>,
                      "For older periods, use the ECB desktop application."
                    );
                    return;
                  }

                  if (state.user.isExpired && date > dateFromTmp) {
                    showInfoDialog(
                      dispatch,
                      <div className="text-danger">
                        <FontAwesomeIcon icon={faExclamationTriangle} /> Invalid period
                      </div>,
                      <>
                        Older optimization periods are not avaiable for the FREE subscription plan.
                        <br />
                        <br />
                        Please upgrade your subscription plan.
                      </>
                    );
                    return;
                  }

                  setDateFrom(e.target.value);
                  dispatch({
                    type: "dateFrom",
                    payload: dateFromTmp,
                  });
                }}
              />
            </div>
            <div className="pb-2 pt-1">
              <div className="d-inline-block min-w-150">End date:</div>
              <input
                type="datetime-local"
                className="setup-info w-165"
                value={dateTo}
                onChange={(e) => {
                  if (state.demo) {
                    showInfoDialog(
                      dispatch,
                      <div className="text-info">
                        <FontAwesomeIcon icon={faExclamationTriangle} /> Demo mode
                      </div>,
                      "Cannot be changed in DEMO mode!"
                    );
                    return;
                  }
                  let dateToTmp = stringToDate2(e.target.value);
                  if (!dateToTmp || isNaN(dateToTmp.getTime())) {
                    return;
                  }
                  let now = new Date();
                  now.setHours(0, 0, 0, 0);
                  if (!state.app && dateToTmp > now) {
                    return;
                  }
                  setDateTo(e.target.value);
                  dispatch({
                    type: "dateTo",
                    payload: dateToTmp,
                  });
                }}
              />
            </div>
            {(state.demo || props.freeBacktest) && (
              <div className="pb-2 pt-1 text-info">Custom periods are available only in the app</div>
            )}
            <div className="pb-2 pt-1">
              <div className="d-inline-block min-w-150">Trade size type:</div>
              <RadioButtons
                options={[
                  {
                    optionClasses: "text-light mr-md-4 pr-md-2",
                    checkedColor: "text-info",
                    uncheckedColor: "text-new-dark hover-info border rounded-circle border-info",
                    checked: tradeSize.type === "base",
                    check: () => {
                      let newSize = {
                        type: "base",
                        value: tradeSize.value,
                      };
                      setTradeSize(newSize);
                      dispatch({
                        type: "btSize",
                        payload: newSize,
                      });
                    },
                    text: "Base",
                  },
                  {
                    optionClasses: "text-light ml-md-2",
                    checkedColor: "text-info",
                    uncheckedColor: "text-new-dark hover-info border rounded-circle border-info",
                    checked: tradeSize.type === "quoted",
                    check: () => {
                      let newSize = {
                        type: "quoted",
                        value: tradeSize.value,
                      };
                      setTradeSize(newSize);
                      dispatch({
                        type: "btSize",
                        payload: newSize,
                      });
                    },
                    text: "Quote",
                  },
                ]}
              />
            </div>
            <div className="pb-2 pt-1 text-nowrap">
              <div className="d-inline-block min-w-150">Trade size:</div>
              <input
                type="number"
                className="setup-info w-165 optional"
                value={tradeSize.value}
                placeholder="<Optional>"
                onChange={(e) => {
                  let value = Math.abs(parseFloat(e.target.value));
                  let newSize = {
                    type: tradeSize.type,
                    value: isNaN(value) || value < 0 ? "" : value,
                  };
                  setTradeSize(newSize);
                  dispatch({
                    type: "btSize",
                    payload: newSize,
                  });
                }}
              />
              <span className="text-info">
                {tradeSize.type === "base"
                  ? props.strategy.pairDetails.baseAsset
                  : props.strategy.pairDetails.quoteAsset}
              </span>
            </div>

            <div className="pb-2 pt-1 text-nowrap">
              <div className="d-inline-block min-w-150">Min. trades count:</div>
              <input
                type="number"
                className="setup-info w-165 optional"
                value={state.optAdditionalOptions.minTrades}
                placeholder="<Optional>"
                onChange={(e) => {
                  let value = parseInt(e.target.value);
                  let newOptAdditionalOptions = {
                    ...state.optAdditionalOptions,
                    minTrades: isNaN(value) ? "" : Math.abs(value),
                  };
                  dispatch({
                    type: "optAdditionalOptions",
                    payload: newOptAdditionalOptions,
                  });
                }}
              />
            </div>

            <div className="pb-2 pt-1">
              <div className="d-inline-block min-w-150">Change trailing buy:</div>
              <RadioButtons
                options={[
                  {
                    optionClasses: "text-light mr-3",
                    checkedColor: "text-info",
                    uncheckedColor: "text-new-dark hover-info border rounded-circle border-info",
                    checked: !state.optAdditionalOptions.changeTBuy,
                    check: () => {
                      let newOptAdditionalOptions = {
                        ...state.optAdditionalOptions,
                        changeTBuy: false,
                      };
                      dispatch({
                        type: "optAdditionalOptions",
                        payload: newOptAdditionalOptions,
                      });
                    },
                    text: "No",
                  },
                  {
                    optionClasses: "text-light mr-3",
                    checkedColor: "text-info",
                    uncheckedColor: "text-new-dark hover-info border rounded-circle border-info",
                    checked: state.optAdditionalOptions.changeTBuy,
                    check: () => {
                      let newOptAdditionalOptions = {
                        ...state.optAdditionalOptions,
                        changeTBuy: true,
                      };
                      dispatch({
                        type: "optAdditionalOptions",
                        payload: newOptAdditionalOptions,
                      });
                    },
                    text: "Yes",
                  },
                ]}
              />
            </div>

            <div className="d-flex pb-2 pt-1">
              <div className="min-w-150">Change stoploss:</div>
              <div className="d-flex flex-nowrap">
                <RadioButtons
                  options={[
                    {
                      optionClasses: "text-light mr-3",
                      checkedColor: "text-info",
                      uncheckedColor: "text-new-dark hover-info border rounded-circle border-info",
                      checked: !state.optAdditionalOptions.changeStoploss,
                      check: () => {
                        let newOptAdditionalOptions = {
                          ...state.optAdditionalOptions,
                          changeStoploss: false,
                        };
                        dispatch({
                          type: "optAdditionalOptions",
                          payload: newOptAdditionalOptions,
                        });
                      },
                      text: "No",
                    },
                    {
                      optionClasses: "text-light mr-3",
                      checkedColor: "text-info",
                      uncheckedColor: "text-new-dark hover-info border rounded-circle border-info",
                      checked: state.optAdditionalOptions.changeStoploss,
                      check: () => {
                        let newOptAdditionalOptions = {
                          ...state.optAdditionalOptions,
                          changeStoploss: true,
                        };
                        dispatch({
                          type: "optAdditionalOptions",
                          payload: newOptAdditionalOptions,
                        });
                      },
                      text: "Yes",
                    },
                  ]}
                />
                {state.optAdditionalOptions.changeStoploss && (
                  <div className="d-flex flex-wrap fade-in-fast">
                    <div className="text-nowrap mb-1">
                      <span className="d-inline-block w-30">Min:</span>
                      <input
                        type="number"
                        className="setup-info optional w-35 h-20 mr-3"
                        value={state.optAdditionalOptions.stoplossMin}
                        placeholder=""
                        onChange={(e) => {
                          let value = parseFloat(e.target.value);
                          let newOptAdditionalOptions = {
                            ...state.optAdditionalOptions,
                            stoplossMin: isNaN(value) ? "" : Math.abs(value),
                          };
                          dispatch({
                            type: "optAdditionalOptions",
                            payload: newOptAdditionalOptions,
                          });
                        }}
                      />
                    </div>
                    <div className="text-nowrap">
                      <span className="d-inline-block w-30">Max:</span>
                      <input
                        type="number"
                        className="setup-info optional w-35 h-20"
                        value={state.optAdditionalOptions.stoplossMax}
                        placeholder=""
                        onChange={(e) => {
                          let value = parseFloat(e.target.value);
                          let newOptAdditionalOptions = {
                            ...state.optAdditionalOptions,
                            stoplossMax: isNaN(value) ? "" : Math.abs(value),
                          };
                          dispatch({
                            type: "optAdditionalOptions",
                            payload: newOptAdditionalOptions,
                          });
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="d-flex pb-2 pt-1">
              <div className="min-w-150">Change target:</div>
              <RadioButtons
                options={[
                  {
                    optionClasses: "text-light mr-3",
                    checkedColor: "text-info",
                    uncheckedColor: "text-new-dark hover-info border rounded-circle border-info",
                    checked: !state.optAdditionalOptions.changeTarget,
                    check: () => {
                      let newOptAdditionalOptions = {
                        ...state.optAdditionalOptions,
                        changeTarget: false,
                      };
                      dispatch({
                        type: "optAdditionalOptions",
                        payload: newOptAdditionalOptions,
                      });
                    },
                    text: "No",
                  },
                  {
                    optionClasses: "text-light mr-3",
                    checkedColor: "text-info",
                    uncheckedColor: "text-new-dark hover-info border rounded-circle border-info",
                    checked: state.optAdditionalOptions.changeTarget,
                    check: () => {
                      let newOptAdditionalOptions = {
                        ...state.optAdditionalOptions,
                        changeTarget: true,
                      };
                      dispatch({
                        type: "optAdditionalOptions",
                        payload: newOptAdditionalOptions,
                      });
                    },
                    text: "Yes",
                  },
                ]}
              />
              {state.optAdditionalOptions.changeTarget && (
                <div className="d-flex flex-wrap fade-in-fast">
                  <div className="text-nowrap mb-1">
                    <span className="d-inline-block w-30">Min:</span>
                    <input
                      type="number"
                      className="setup-info optional w-35 h-20 mr-3"
                      value={state.optAdditionalOptions.targetMin}
                      placeholder=""
                      onChange={(e) => {
                        let value = parseFloat(e.target.value);
                        let newOptAdditionalOptions = {
                          ...state.optAdditionalOptions,
                          targetMin: isNaN(value) ? "" : Math.abs(value),
                        };
                        dispatch({
                          type: "optAdditionalOptions",
                          payload: newOptAdditionalOptions,
                        });
                      }}
                    />
                  </div>
                  <div className="text-nowrap">
                    <span className="d-inline-block w-30">Max:</span>
                    <input
                      type="number"
                      className="setup-info optional w-35 h-20"
                      value={state.optAdditionalOptions.targetMax}
                      placeholder=""
                      onChange={(e) => {
                        let value = parseFloat(e.target.value);
                        let newOptAdditionalOptions = {
                          ...state.optAdditionalOptions,
                          targetMax: isNaN(value) ? "" : Math.abs(value),
                        };
                        dispatch({
                          type: "optAdditionalOptions",
                          payload: newOptAdditionalOptions,
                        });
                      }}
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="d-flex pb-2 pt-1">
              <div className="min-w-150">Change trailing t.:</div>
              <RadioButtons
                options={[
                  {
                    optionClasses: "text-light mr-3",
                    checkedColor: "text-info",
                    uncheckedColor: "text-new-dark hover-info border rounded-circle border-info",
                    checked: !state.optAdditionalOptions.changeTrailingTarget,
                    disabled: !(state.optAdditionalOptions.changeTarget || props.strategy.target),
                    check: () => {
                      let newOptAdditionalOptions = {
                        ...state.optAdditionalOptions,
                        changeTrailingTarget: false,
                      };
                      dispatch({
                        type: "optAdditionalOptions",
                        payload: newOptAdditionalOptions,
                      });
                    },
                    text: "No",
                  },
                  {
                    optionClasses: "text-light mr-3",
                    checkedColor: "text-info",
                    uncheckedColor: "text-new-dark hover-info border rounded-circle border-info",
                    checked: state.optAdditionalOptions.changeTrailingTarget,
                    disabled: !(state.optAdditionalOptions.changeTarget || props.strategy.target),
                    check: () => {
                      let newOptAdditionalOptions = {
                        ...state.optAdditionalOptions,
                        changeTrailingTarget: true,
                      };
                      dispatch({
                        type: "optAdditionalOptions",
                        payload: newOptAdditionalOptions,
                      });
                    },
                    text: "Yes",
                  },
                ]}
              />
              {state.optAdditionalOptions.changeTrailingTarget && (
                <div className="d-flex flex-wrap fade-in-fast">
                  <div className="text-nowrap mb-1">
                    <span className="d-inline-block w-30">Min:</span>
                    <input
                      type="number"
                      className="setup-info optional w-35 h-20 mr-3"
                      value={state.optAdditionalOptions.tTargetMin}
                      placeholder=""
                      onChange={(e) => {
                        let value = parseFloat(e.target.value);
                        let newOptAdditionalOptions = {
                          ...state.optAdditionalOptions,
                          tTargetMin: isNaN(value) ? "" : Math.abs(value),
                        };
                        dispatch({
                          type: "optAdditionalOptions",
                          payload: newOptAdditionalOptions,
                        });
                      }}
                    />
                  </div>
                  <div className="text-nowrap">
                    <span className="d-inline-block w-30">Max:</span>
                    <input
                      type="number"
                      className="setup-info optional w-35 h-20"
                      value={state.optAdditionalOptions.tTargetMax}
                      placeholder=""
                      onChange={(e) => {
                        let value = parseFloat(e.target.value);
                        let newOptAdditionalOptions = {
                          ...state.optAdditionalOptions,
                          tTargetMax: isNaN(value) ? "" : Math.abs(value),
                        };
                        dispatch({
                          type: "optAdditionalOptions",
                          payload: newOptAdditionalOptions,
                        });
                      }}
                    />
                  </div>
                </div>
              )}
              {!(state.optAdditionalOptions.changeTarget || props.strategy.target) && (
                <span className="small font-italic text-nowrap" style={{ paddingTop: "3px" }}>
                  No target
                </span>
              )}
            </div>

            <div className="pb-2 pt-1">
              <div className="d-inline-block min-w-150">Change time stop:</div>
              <RadioButtons
                options={[
                  {
                    optionClasses: "text-light mr-3",
                    checkedColor: "text-info",
                    uncheckedColor: "text-new-dark hover-info border rounded-circle border-info",
                    checked: !state.optAdditionalOptions.changeTimeClose,
                    check: () => {
                      let newOptAdditionalOptions = {
                        ...state.optAdditionalOptions,
                        changeTimeClose: false,
                      };
                      dispatch({
                        type: "optAdditionalOptions",
                        payload: newOptAdditionalOptions,
                      });
                    },
                    text: "No",
                  },
                  {
                    optionClasses: "text-light mr-3",
                    checkedColor: "text-info",
                    uncheckedColor: "text-new-dark hover-info border rounded-circle border-info",
                    checked: state.optAdditionalOptions.changeTimeClose,
                    check: () => {
                      let newOptAdditionalOptions = {
                        ...state.optAdditionalOptions,
                        changeTimeClose: true,
                      };
                      dispatch({
                        type: "optAdditionalOptions",
                        payload: newOptAdditionalOptions,
                      });
                    },
                    text: "Yes",
                  },
                ]}
              />
            </div>
            {props.strategy.conditionPair && (
              <div className="pb-2 pt-1">
                <div className="d-inline-block min-w-150">Change condition:</div>
                <RadioButtons
                  options={[
                    {
                      optionClasses: "text-light mr-3",
                      checkedColor: "text-info",
                      uncheckedColor: "text-new-dark hover-info border rounded-circle border-info",
                      checked: !state.optAdditionalOptions.changeCondition,
                      check: () => {
                        let newOptAdditionalOptions = {
                          ...state.optAdditionalOptions,
                          changeCondition: false,
                        };
                        dispatch({
                          type: "optAdditionalOptions",
                          payload: newOptAdditionalOptions,
                        });
                      },
                      text: "No",
                    },
                    {
                      optionClasses: "text-light mr-3",
                      checkedColor: "text-info",
                      uncheckedColor: "text-new-dark hover-info border rounded-circle border-info",
                      checked: state.optAdditionalOptions.changeCondition,
                      check: () => {
                        let newOptAdditionalOptions = {
                          ...state.optAdditionalOptions,
                          changeCondition: true,
                        };
                        dispatch({
                          type: "optAdditionalOptions",
                          payload: newOptAdditionalOptions,
                        });
                      },
                      text: "Yes",
                    },
                  ]}
                />
              </div>
            )}
            <div className="pb-2 pt-1">
              <a
                href="#/"
                className="text-info"
                title="Advanced Options"
                onClick={(e) => {
                  e.preventDefault();
                  document.activeElement.blur();
                  let option = optimizationAdvanced === "y" ? "n" : "y";
                  setOptimizationAdvanced(option);
                  localStorage.setItem("optimizationAdvanced", option);
                }}
              >
                {optimizationAdvanced === "y" ? (
                  <>
                    Advanced Options <FontAwesomeIcon icon={faChevronUp} />
                  </>
                ) : (
                  <>
                    Advanced Options <FontAwesomeIcon icon={faChevronDown} />
                  </>
                )}
              </a>
            </div>

            {optimizationAdvanced === "y" && (
              <>
                <div className="pb-2 pt-1 fade-in">
                  <div className="d-inline-block min-w-150">Buy rules to optimize:</div>
                  <RadioButtons
                    options={[
                      {
                        optionClasses: "text-light mr-3",
                        checkedColor: "text-info",
                        uncheckedColor: "text-new-dark hover-info border rounded-circle border-info",
                        checked: skipedBuyRules.length === props.strategy.buyRules.length,
                        check: () => {
                          if (optimizationBuyRules === "none") {
                            setSkipedBuyRules(props.strategy.buyRules.map((el) => el.id));
                          } else {
                            localStorage.setItem("optimizationBuyRules", "none");
                            setOptimizationBuyRules("none");
                          }
                        },
                        text: "No",
                      },
                      {
                        optionClasses: "text-light mr-3",
                        checkedColor: "text-info",
                        uncheckedColor: "text-new-dark hover-info border rounded-circle border-info",
                        checked: skipedBuyRules.length === 0,
                        check: () => {
                          if (optimizationBuyRules === "all") {
                            setSkipedBuyRules([]);
                          } else {
                            localStorage.setItem("optimizationBuyRules", "all");
                            setOptimizationBuyRules("all");
                          }
                        },
                        text: <>All&nbsp;</>,
                      },
                    ]}
                  />
                </div>
                {props.strategy.buyRules.map((rule) => (
                  <div className="pb-2 pt-1 fade-in" key={rule.id}>
                    <div className="d-inline-block min-w-150 max-w-150 text-nowrap text-truncate">
                      <FontAwesomeIcon className="text-light-green small" icon={faAngleDoubleRight} /> {rule.indicator}
                      <span>{getIndicatorSetup(rule).replace(/ /g, "")}</span> {getInterval(rule.timeframe)}
                    </div>
                    <RadioButtons
                      options={[
                        {
                          optionClasses: "text-light align-top mr-3",
                          checkedColor: "text-info",
                          uncheckedColor: "text-new-dark hover-info border rounded-circle border-info",
                          checked: skipedBuyRules.indexOf(rule.id) !== -1,
                          check: () => {
                            if (skipedBuyRules.includes(rule.id)) {
                              return;
                            }
                            let skipedRulesTmp = [...skipedBuyRules];
                            skipedRulesTmp.push(rule.id);
                            setSkipedBuyRules(skipedRulesTmp);
                          },
                          text: "No",
                        },
                        {
                          optionClasses: "text-light align-top mr-3",
                          checkedColor: "text-info",
                          uncheckedColor: "text-new-dark hover-info border rounded-circle border-info",
                          checked: skipedBuyRules.indexOf(rule.id) === -1,
                          check: () => {
                            let skipedRulesTmp = skipedBuyRules.filter((el) => el !== rule.id);
                            setSkipedBuyRules(skipedRulesTmp);
                          },
                          text: "Yes",
                        },
                      ]}
                    />
                  </div>
                ))}

                {props.strategy.sellRules.length > 0 && (
                  <>
                    <div className="pb-2 fade-in">
                      <div className="d-inline-block min-w-150">Sell rules to optimize:</div>
                      <RadioButtons
                        options={[
                          {
                            optionClasses: "text-light mr-3",
                            checkedColor: "text-info",
                            uncheckedColor: "text-new-dark hover-info border rounded-circle border-info",
                            checked: skipedSellRules.length === props.strategy.sellRules.length,
                            check: () => {
                              if (optimizationSellRules === "none") {
                                setSkipedSellRules(props.strategy.sellRules.map((el) => el.id));
                              } else {
                                localStorage.setItem("optimizationSellRules", "none");
                                setOptimizationSellRules("none");
                              }
                            },
                            text: "No",
                          },
                          {
                            optionClasses: "text-light mr-3",
                            checkedColor: "text-info",
                            uncheckedColor: "text-new-dark hover-info border rounded-circle border-info",
                            checked: skipedSellRules.length === 0,
                            check: () => {
                              if (optimizationSellRules === "all") {
                                setSkipedSellRules([]);
                              } else {
                                localStorage.setItem("optimizationSellRules", "all");
                                setOptimizationSellRules("all");
                              }
                            },
                            text: <>All&nbsp;</>,
                          },
                        ]}
                      />
                    </div>

                    {props.strategy.sellRules.map((rule) => (
                      <div className="pb-2 pt-1 fade-in" key={rule.id}>
                        <div className="d-inline-block min-w-150 max-w-150 text-nowrap text-truncate">
                          <FontAwesomeIcon className="text-light-green small" icon={faAngleDoubleRight} />{" "}
                          {rule.indicator}
                          <span>{getIndicatorSetup(rule).replace(/ /g, "")}</span> {getInterval(rule.timeframe)}
                        </div>
                        <RadioButtons
                          options={[
                            {
                              optionClasses: "text-light align-top mr-3",
                              checkedColor: "text-info",
                              uncheckedColor: "text-new-dark hover-info border rounded-circle border-info",
                              checked: skipedSellRules.indexOf(rule.id) !== -1,
                              check: () => {
                                let skipedRulesTmp = [...skipedSellRules];
                                skipedRulesTmp.push(rule.id);
                                setSkipedSellRules(skipedRulesTmp);
                              },
                              text: "No",
                            },
                            {
                              optionClasses: "text-light align-top mr-3",
                              checkedColor: "text-info",
                              uncheckedColor: "text-new-dark hover-info border rounded-circle border-info",
                              checked: skipedSellRules.indexOf(rule.id) === -1,
                              check: () => {
                                let skipedRulesTmp = skipedSellRules.filter((el) => el !== rule.id);
                                setSkipedSellRules(skipedRulesTmp);
                              },
                              text: "Yes",
                            },
                          ]}
                        />
                      </div>
                    ))}
                  </>
                )}
              </>
            )}
            {(!props.runningOptimization || props.runningOptimization.completed === 100) && (
              <div className="mt-4 text-center fade-in">
                <button
                  className="btn btn-sm btn-info min-w-150 mx-auto"
                  onClick={() => {
                    let dateToTmp = stringToDate2(dateTo);
                    let dateFromTmp = stringToDate2(dateFrom);
                    if (dateToTmp <= dateFromTmp) {
                      showInfoDialog(
                        dispatch,
                        <div className="text-danger">
                          <FontAwesomeIcon icon={faExclamationTriangle} /> Invalid period
                        </div>,
                        "Start date should be older than the End date."
                      );
                      return;
                    }
                    let now = new Date();
                    let date = new Date();
                    now.setHours(0, 0, 0, 0);
                    date.setMonth(date.getMonth() - 1);
                    date.setHours(0, 0, 0, 0);
                    if (!state.demo && !state.app && (date > dateFromTmp || dateToTmp > now)) {
                      showInfoDialog(
                        dispatch,
                        <div className="text-danger">
                          <FontAwesomeIcon icon={faExclamationTriangle} /> Invalid period
                        </div>,
                        "For custom periods, use the ECB desktop application"
                      );
                      return;
                    }

                    if (state.user.isExpired && date > dateFromTmp) {
                      showInfoDialog(
                        dispatch,
                        <div className="text-danger">
                          <FontAwesomeIcon icon={faExclamationTriangle} /> Invalid period
                        </div>,
                        <>
                          Older optimization periods are not avaiable for the FREE subscription plan.
                          <br />
                          <br />
                          Please upgrade your subscription plan.
                        </>
                      );
                      return;
                    }

                    if (!commissionRate) {
                      setCommissionRate(0);
                      dispatch({
                        type: "commissionRate",
                        payload: 0,
                      });
                    }

                    setShownOptimizationResult(false);
                    let hasManualOrder = props.strategy.buyRules.find((rule) => rule.indicator === "Manual order");
                    if (!hasManualOrder) {
                      hasManualOrder = props.strategy.sellRules.find((rule) => rule.indicator === "Manual order");
                    }
                    if (hasManualOrder) {
                      showInfoDialog(
                        dispatch,
                        <span className="text-danger">
                          <FontAwesomeIcon icon={faExclamationTriangle} /> Manual order
                        </span>,
                        "Cannot optimize a strategy that has manual order!"
                      );
                      return;
                    }
                    if (!state.optAdditionalOptions.stoplossMin) {
                      state.optAdditionalOptions.stoplossMin = 0.25;
                    }
                    if (!state.optAdditionalOptions.stoplossMax) {
                      state.optAdditionalOptions.stoplossMax = 10;
                    }
                    if (!state.optAdditionalOptions.targetMin) {
                      state.optAdditionalOptions.targetMin = 0.25;
                    }
                    if (!state.optAdditionalOptions.targetMax) {
                      state.optAdditionalOptions.targetMax = 10;
                    }
                    if (!state.optAdditionalOptions.tTargetMin) {
                      state.optAdditionalOptions.tTargetMin = 0.25;
                    }
                    if (!state.optAdditionalOptions.tTargetMax) {
                      state.optAdditionalOptions.tTargetMax = 10;
                    }
                    if (
                      state.optAdditionalOptions.changeStoploss &&
                      state.optAdditionalOptions.stoplossMin &&
                      state.optAdditionalOptions.stoplossMax &&
                      state.optAdditionalOptions.stoplossMin > state.optAdditionalOptions.stoplossMax
                    ) {
                      showInfoDialog(
                        dispatch,
                        <span className="text-danger">
                          <FontAwesomeIcon icon={faExclamationTriangle} /> Invalid stoploss range
                        </span>,
                        "Min value is greater than the Max value."
                      );
                      return;
                    }
                    if (
                      state.optAdditionalOptions.changeTarget &&
                      state.optAdditionalOptions.targetMin &&
                      state.optAdditionalOptions.targetMax &&
                      state.optAdditionalOptions.targetMin > state.optAdditionalOptions.targetMax
                    ) {
                      showInfoDialog(
                        dispatch,
                        <span className="text-danger">
                          <FontAwesomeIcon icon={faExclamationTriangle} /> Invalid target range
                        </span>,
                        "Min value is greater than the Max value."
                      );
                      return;
                    }
                    if (
                      state.optAdditionalOptions.changeTrailingTarget &&
                      state.optAdditionalOptions.tTargetMin &&
                      state.optAdditionalOptions.tTargetMax &&
                      state.optAdditionalOptions.tTargetMin > state.optAdditionalOptions.tTargetMax
                    ) {
                      showInfoDialog(
                        dispatch,
                        <span className="text-danger">
                          <FontAwesomeIcon icon={faExclamationTriangle} /> Invalid trailing target range
                        </span>,
                        "Min value is greater than the Max value."
                      );
                      return;
                    }

                    let skipedRules = skipedBuyRules.map((el) => el);
                    skipedSellRules.forEach((el) => skipedRules.push(el));

                    if (state.user.isExpired || state.user.subscriptionType === "beginner") {
                      getLimits(state.user.token, state, dispatch).then((limits) => {
                        if (
                          (state.user.isExpired && limits.opt > 0) ||
                          (state.user.subscriptionType === "beginner" && limits.opt >= 5)
                        ) {
                          showInfoDialog(
                            dispatch,
                            <div className="text-danger">
                              <FontAwesomeIcon icon={faExclamationTriangle} /> Plan Limit Reached
                            </div>,
                            <>
                              You have reached the maximum number of weekly optimizations of your subscription plan.
                              {limits.optDate && (
                                <>
                                  <br /> The limit will be resetted on {formatDateTime3(new Date(limits.optDate))}
                                </>
                              )}
                              <br />
                              For more optimizations please upgrade your subscription plan.
                            </>
                          );
                          return;
                        }
                        startOptimization(
                          props.strategy,
                          dateFromTmp,
                          dateToTmp,
                          commissionRate ? commissionRate : 0,
                          state.optAdditionalOptions.minTrades,
                          tradeSize,
                          {
                            tBuy: state.optAdditionalOptions.changeTBuy,
                            tBuyWait: state.optAdditionalOptions.changeTBuy,
                            stoploss: state.optAdditionalOptions.changeStoploss,
                            target: state.optAdditionalOptions.changeTarget,
                            trailingTarget:
                              state.optAdditionalOptions.changeTarget || props.strategy.target
                                ? state.optAdditionalOptions.changeTrailingTarget
                                : false,
                            timeClose: state.optAdditionalOptions.changeTimeClose,
                            conditionValue: props.strategy.conditionPair
                              ? state.optAdditionalOptions.changeCondition
                              : false,
                          },
                          {
                            stoplossMin: state.optAdditionalOptions.stoplossMin,
                            stoplossMax: state.optAdditionalOptions.stoplossMax,
                            targetMin: state.optAdditionalOptions.targetMin,
                            targetMax: state.optAdditionalOptions.targetMax,
                            tTargetMin: state.optAdditionalOptions.tTargetMin,
                            tTargetMax: state.optAdditionalOptions.tTargetMax,
                          },
                          skipedRules,
                          dispatch
                        );
                      });
                    } else {
                      startOptimization(
                        props.strategy,
                        dateFromTmp,
                        dateToTmp,
                        commissionRate ? commissionRate : 0,
                        state.optAdditionalOptions.minTrades,
                        tradeSize,
                        {
                          tBuy: state.optAdditionalOptions.changeTBuy,
                          tBuyWait: state.optAdditionalOptions.changeTBuy,
                          stoploss: state.optAdditionalOptions.changeStoploss,
                          target: state.optAdditionalOptions.changeTarget,
                          trailingTarget:
                            state.optAdditionalOptions.changeTarget || props.strategy.target
                              ? state.optAdditionalOptions.changeTrailingTarget
                              : false,
                          timeClose: state.optAdditionalOptions.changeTimeClose,
                          conditionValue: props.strategy.conditionPair
                            ? state.optAdditionalOptions.changeCondition
                            : false,
                        },
                        {
                          stoplossMin: state.optAdditionalOptions.stoplossMin,
                          stoplossMax: state.optAdditionalOptions.stoplossMax,
                          targetMin: state.optAdditionalOptions.targetMin,
                          targetMax: state.optAdditionalOptions.targetMax,
                          tTargetMin: state.optAdditionalOptions.tTargetMin,
                          tTargetMax: state.optAdditionalOptions.tTargetMax,
                        },
                        skipedRules,
                        dispatch
                      );
                    }
                  }}
                >
                  Start
                </button>
              </div>
            )}
          </div>
        </div>
        <div
          className={props.rightPanelWidth > 650 ? "pl-3" : ""}
          style={props.rightPanelWidth > 650 ? { width: props.rightPanelWidth - 395 } : {}}
        >
          <div className="mb-2">Recent:</div>
          <div>
            <div className="table-responsive">
              <table className="table table-sm table-borderless">
                <tbody>
                  {props.runningOptimization && !shownOptimizationResult && (
                    <tr className="text-nowrap fade-in">
                      <td className="text-left pl-0 " colSpan="2">
                        <div className="max-w-220">
                          <OptimizationProgress
                            runningOptimization={props.runningOptimization}
                            strategy={props.strategy}
                          />
                        </div>
                      </td>
                    </tr>
                  )}
                  {recentOptimizations.map((el, index) => {
                    return props.runningOptimization && !shownOptimizationResult && index === 9 ? null : (
                      <tr key={index} className="text-nowrap">
                        <td
                          className={`text-left rounded text-nowrap pl-0 r ${
                            props.runningOptimization &&
                            props.runningOptimization.completed === 100 &&
                            index === 0 &&
                            shownOptimizationResult
                              ? "bg-change-info"
                              : ""
                          }`}
                        >
                          <a
                            href="#/"
                            onClick={(e) => {
                              e.preventDefault();
                              document.activeElement.blur();
                              props.setStrategyActiveSubTabResult(true);
                              props.setResult(el.optimization);
                            }}
                            className="text-info"
                          >
                            {formatDate2(el.optimization.params.dateFrom)} -{" "}
                            {formatDate2(el.optimization.params.dateTo)}{" "}
                            {
                              <span
                                className={
                                  el.optimization.totalReturn > 0
                                    ? "text-success"
                                    : el.optimization.totalReturn < 0
                                    ? "text-danger"
                                    : "text-white"
                                }
                              >
                                {el.optimization.totalReturn !== null &&
                                  el.optimization.totalReturn !== undefined &&
                                  el.optimization.totalReturn.toFixed(1) + "%"}
                              </span>
                            }
                          </a>
                        </td>
                        <td className="text-left w-100 pl-0">
                          <a
                            href="#/"
                            className="text-danger pl-1"
                            title="Delete result"
                            onClick={(e) => {
                              e.preventDefault();
                              document.activeElement.blur();
                              getEcbDatabase().deleteOptimization(el.id);
                              let recentOptimizationsNew = recentOptimizations.filter((opt) => opt.id !== el.id);
                              if (props.currentOptimizationResult && props.currentOptimizationResult.id === el.id) {
                                props.setResult(
                                  recentOptimizationsNew.length > 0 ? recentOptimizationsNew[0].optimization : null
                                );
                              }
                              setRecentOptimizations(recentOptimizationsNew);
                            }}
                          >
                            <FontAwesomeIcon icon={faTimesCircle} />
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

const VariationResult = (props) => {
  let { dispatch } = useContext(globalContext);
  const [cumulativesSeries, setCumulativesSeries] = useState([]);
  const [emphasisStyle] = useState({
    itemStyle: {
      barBorderWidth: 1,
      shadowBlur: 10,
      shadowOffsetX: 0,
      shadowOffsetY: 0,
      shadowColor: "rgba(0,0,0,0.5)",
    },
  });
  const [showDetails, setShowDetails] = useState(false);
  const [timeframes, setTimeframes] = useState([]);
  const [selectedTimeframe, setSelectedTimeframe] = useState("");
  const [chartHistoricalData, setChartHistoricalData] = useState([]);
  const [tradeToShow, setTradeToShow] = useState([]);

  const strategyId = useRef(null);
  const componentIsMounted = useRef(true);
  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (props.strategy) {
      strategyId.current = props.strategy.id;
      let timeframes = getTimeframes(props.strategy).map((tf) => getInterval(tf));
      setTimeframes(timeframes);
      setSelectedTimeframe(timeframes[0]);
    } else {
      strategyId.current = null;
      setTimeframes([]);
      setSelectedTimeframe("");
      setChartHistoricalData([]);
    }
  }, [props.strategy]);

  useEffect(() => {
    if (props.cumulatives) {
      let cumulativesSeries = [
        {
          name: "P/L %",
          type: "line",
          symbol: "none",
          smooth: true,
          itemStyle: {
            color: "#1ebdd6",
          },

          areaStyle: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: "#1ebdd6",
              },
              {
                offset: 1,
                color: "#0a2240",
              },
            ]),
          },
          data: props.cumulatives.cumulatives.returns,
        },
        {
          name: props.pair,
          type: "line",
          symbol: "none",
          smooth: false,
          lineStyle: {
            opacity: 0.8,
            width: 1,
          },
          yAxisIndex: 1,
          data: props.cumulatives.cumulatives.prices,
        },
      ];
      setCumulativesSeries(cumulativesSeries);
    }
  }, [props.cumulatives]);

  useEffect(() => {
    if (props.historicalData) {
      let tf = getTimeframeFromInterval(selectedTimeframe);
      setChartHistoricalData(props.historicalData[tf]);
    }
  }, [props.historicalData, selectedTimeframe]);

  return (
    <>
      <div className={`row ${props.rightPanelWidth > 550 ? "row-cols-3" : "row-cols-1"}`}>
        <div className="col px-2">
          <h6 className="mb-2">
            <FontAwesomeIcon icon={faTrophy} /> {props.label}
          </h6>
          <div className="table-responsive border border-dark rounded mt-2 mb-2">
            <table className="table table-new-dark table-sm small">
              <tbody>
                <tr>
                  <td>Total Return:</td>
                  <td
                    className={
                      props.stats.totalReturn > 0 ? "text-success" : props.stats.totalReturn < 0 ? "text-danger" : ""
                    }
                  >
                    {props.stats.totalReturn.toFixed(2)}%
                  </td>
                </tr>
                <tr>
                  <td className="text-nowrap">
                    Consistent Return:
                    <a
                      className="text-info ml-2"
                      href="#/"
                      title="Consistent return"
                      onClick={(e) => {
                        e.preventDefault();
                        document.activeElement.blur();
                        showInfoDialog(
                          dispatch,
                          <div className="text-info">
                            <FontAwesomeIcon icon={faInfoCircle} /> Info
                          </div>,
                          <div>Consistent return - return from all trades that are within 2 standard deviations.</div>
                        );
                      }}
                    >
                      <FontAwesomeIcon icon={faInfoCircle} />
                    </a>
                  </td>
                  <td
                    className={
                      props.stats.totalReturnStdDev2 > 0
                        ? "text-success"
                        : props.stats.totalReturnStdDev2 < 0
                        ? "text-danger"
                        : ""
                    }
                  >
                    {props.stats.totalReturnStdDev2.toFixed(2)}%
                  </td>
                </tr>
                <tr>
                  <td>Max. Drawdown:</td>
                  <td className={props.stats.maxDrawdown < 0 ? "text-danger" : ""}>
                    {props.stats.maxDrawdown.toFixed(2)}%
                  </td>
                </tr>
                <tr>
                  <td>Win Rate:</td>
                  <td className={props.stats.winRate >= 50 ? "text-success" : "text-danger"}>
                    {props.stats.winRate.toFixed(2)}%
                  </td>
                </tr>
                <tr>
                  <td>Win/Loss Ratio:</td>
                  <td className={props.stats.winLossRatio >= 0.5 ? "text-success" : "text-danger"}>
                    {props.stats.winLossRatio.toFixed(2)}
                  </td>
                </tr>
                <tr>
                  <td>Av. Pos. Trade:</td>
                  <td className="text-success">
                    {props.stats.avPosTrade > 0 ? props.stats.avPosTrade.toFixed(2) + "%" : ""}
                  </td>
                </tr>
                <tr>
                  <td>Av. Neg.Trade:</td>
                  <td className="text-danger">
                    {props.stats.avNegTrade < 0 ? props.stats.avNegTrade.toFixed(2) + "%" : ""}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="col px-md-1">
          {cumulativesSeries.length > 0 ? (
            <ReactEcharts
              lazyUpdate={false}
              notMerge={false}
              style={{ height: "242px", left: 0, top: 0 }}
              opts={{ renderer: "svg" }}
              className="bg-new-dark"
              option={{
                replaceMerge: ["xAxis", "yAxis", "series"],
                animation: false,
                tooltip: {
                  backgroundColor: "#343a40",
                  borderColor: "#17a2b8",
                  borderWidth: 1,
                  axisPointer: {
                    type: "cross",
                    label: { color: "#fff", backgroundColor: "#343a40" },
                  },
                  textStyle: {
                    align: "left",
                    fontSize: 12,
                  },
                  trigger: "axis",
                },
                legend: {
                  data: cumulativesSeries.map((el) => el.name),
                  textStyle: { color: "#fff", fontSize: 11 },
                  icon: "pin",
                },
                axisPointer: {
                  link: { xAxisIndex: "all" },
                },
                grid: {
                  top: 28,
                  left: 40,
                  bottom: 20,
                  right: 10,
                },
                xAxis: {
                  type: "category",
                  data: props.cumulatives.cumulatives.dates,
                  scale: true,
                  splitNumber: 5,
                  boundaryGap: false,
                  axisLine: {
                    onZero: true,
                    lineStyle: { color: "transparent", width: "0.5" },
                  },
                  splitLine: {
                    show: true,
                    lineStyle: { color: "#17a2b8", width: "0.5" },
                  },
                  axisLabel: { show: true, color: "#fff", fontSize: 11 },
                },
                yAxis: [
                  {
                    scale: true,
                    splitNumber: 3,
                    splitArea: {
                      show: false,
                    },
                    axisLine: {
                      lineStyle: { color: "transparent", width: "0.5" },
                    },
                    splitLine: {
                      show: true,
                      lineStyle: { color: "#17a2b8", width: "0.5" },
                    },
                    axisLabel: {
                      show: true,
                      color: "#fff",
                      fontSize: 11,
                      align: "right",
                    },
                  },
                  {
                    scale: true,
                    splitNumber: 3,
                    splitArea: {
                      show: false,
                    },
                    axisLine: {
                      lineStyle: { color: "transparent", width: "0.5" },
                    },
                    splitLine: {
                      show: false,
                      lineStyle: { color: "#17a2b8", width: "0.5" },
                    },
                    axisLabel: {
                      show: true,
                      color: "#fff",
                      fontSize: 11,
                      align: "right",
                    },
                  },
                ],

                series: cumulativesSeries,
              }}
            />
          ) : (
            <div className="text-center">
              <div className="loading-white cursor-help m-5" title="Loading..">
                <img src={loading} alt="" />
              </div>
            </div>
          )}
        </div>
        {props.stats && props.stats.chartData && props.stats.chartData.trades && (
          <div className="col px-md-1">
            <ReactEcharts
              lazyUpdate={false}
              notMerge={false}
              opts={{ renderer: "svg" }}
              className="bg-new-dark"
              onEvents={{
                click: (e) => {
                  let trade = props.stats.trades[e.dataIndex - 1];
                  if (trade && setTradeToShow) {
                    setTradeToShow(trade);
                  }
                },
              }}
              style={{ height: "242px", left: 0, top: 0 }}
              option={{
                replaceMerge: ["xAxis", "yAxis", "series"],
                animation: false,
                tooltip: {},
                legend: {
                  data: ["Trades"],
                  textStyle: { color: "#fff", fontSize: 11 },
                  icon: "none",
                },
                axisPointer: {
                  link: { xAxisIndex: "all" },
                },
                dataZoom: [
                  {
                    type: "inside",
                    xAxisIndex: [0],
                    start: 0,
                    end: 100,
                  },
                ],
                grid: {
                  top: 28,
                  left: 40,
                  bottom: 20,
                  right: 10,
                },
                xAxis: {
                  type: "category",
                  data: props.stats.chartData.trades.xAxis,
                  scale: true,
                  splitNumber: 5,
                  boundaryGap: false,
                  axisLine: {
                    onZero: true,
                    lineStyle: { color: "transparent", width: "0.5" },
                  },
                  splitLine: {
                    show: true,
                    lineStyle: { color: "#17a2b8", width: "0.5" },
                  },
                  axisLabel: { show: true, color: "#fff", fontSize: 11 },
                },
                yAxis: {
                  min: props.stats.chartData.trades.min < 0 ? props.stats.chartData.trades.min * 1.3 : 0,
                  max: props.stats.chartData.trades.max > 0 ? props.stats.chartData.trades.max * 1.3 : 0,
                  scale: true,
                  splitArea: {
                    show: false,
                  },
                  axisLine: {
                    lineStyle: { color: "transparent", width: "0.5" },
                  },
                  splitLine: {
                    show: true,
                    lineStyle: { color: "#17a2b8", width: "0.5" },
                  },
                  axisLabel: {
                    show: true,
                    color: "#fff",
                    fontSize: 11,
                    align: "right",
                    showMinLabel: false,
                    showMaxLabel: false,
                  },
                },
                series: {
                  name: "Trades",
                  type: "bar",
                  stack: "one",
                  symbol: "none",
                  emphasis: emphasisStyle,
                  data: props.stats.chartData.trades.data,
                },
              }}
            />
          </div>
        )}
      </div>
      <div className="row mt-2">
        <div className="col pl-2">
          <a
            href="#/"
            className="btn btn-info btn-sm text-nowrap py-0 mr-3"
            onClick={(e) => {
              e.preventDefault();
              document.activeElement.blur();
              setShowDetails(showDetails === "strategy" ? "" : "strategy");
            }}
          >
            Strategy{" "}
            {showDetails === "strategy" ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />}
          </a>
          <a
            href="#/"
            className="btn btn-info btn-sm text-nowrap py-0 mr-3"
            onClick={(e) => {
              e.preventDefault();
              document.activeElement.blur();
              setShowDetails(showDetails === "chart" ? "" : "chart");
            }}
          >
            Chart{" "}
            {showDetails === "chart" ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />}
          </a>
          <a
            href="#/"
            className="btn btn-info btn-sm text-nowrap py-0 "
            onClick={(e) => {
              e.preventDefault();
              props.saveStrategy();
            }}
          >
            <FontAwesomeIcon icon={faSave} /> Save
          </a>

          {showDetails === "chart" && (
            <div className="fade-in my-3">
              <div className="text-right mb-2 mr-1">
                {timeframes.map((time) => {
                  return (
                    <a
                      key={time}
                      href="/#"
                      className={
                        selectedTimeframe === time ? "small bg-info text-light rounded px-1" : "small text-info px-1"
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        document.activeElement.blur();
                        setSelectedTimeframe(time);
                      }}
                    >
                      {time}
                    </a>
                  );
                })}
              </div>

              <StaticChartWithIndicators
                strategy={props.strategy}
                pair={props.strategy.pair}
                exchange={props.strategy.exchange}
                timeframe={selectedTimeframe}
                id={`optChart${props.label.replace("/", "").replace(/ /g, "")}`}
                showZoomSlider={true}
                height={Math.max(250, props.topPanelHeight - 200)}
                trades={props.stats ? props.stats.trades : null}
                historicalData={chartHistoricalData}
                markPointBig={true}
                tradeToShow={tradeToShow}
              />
            </div>
          )}

          {showDetails === "strategy" && (
            <div className="border border-info rounded fade-in small my-3 p-4 ">
              <InspectStrategy
                showName={false}
                labelsColor="text-light-green"
                strategy={props.strategy}
                setSaveFromOptimization={true}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const OptimizationResult = (props) => {
  const { state } = useContext(globalContext);
  const [results, setResults] = useState(null);
  const [cumulatives, setCumulatives] = useState(null);
  const [marketReturn, setMarketReturn] = useState("");
  const componentIsMounted = useRef(true);
  const strategyId = useRef(null);
  const [historicalData, setHistoricalData] = useState(null);

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);
  useEffect(() => {
    if (props.strategy) {
      strategyId.current = props.strategy.id;
    } else {
      strategyId.current = null;
    }
  }, [props.strategy]);

  useEffect(() => {
    setHistoricalData(null);
    if (props.optimization) {
      (async () => {
        let optResult = null;
        for (let i = 0; i < 10; i++) {
          optResult = await getEcbDatabase().getOptimization(props.optimization.id);
          if (optResult) {
            break;
          }
          await sleep(200);
        }

        if (componentIsMounted.current) {
          if (optResult) {
            let strategyIdTmp = strategyId.current;

            Object.keys(optResult).forEach((key) => {
              let result = optResult[key];
              if (result && result.stats && result.stats.totalReturn > 0) {
                attachTradesData(result.stats);
              }
            });
            setResults(optResult);

            let strategy = null;
            Object.keys(optResult).forEach((key) => {
              let result = optResult[key];
              if (result) {
                strategy = result.strategy;
              }
            });
            if (!strategy) {
              return;
            }
            let timeframes = getTimeframes(strategy);

            let dateTo = new Date(props.optimization.params.dateTo);
            let dateFrom = new Date(props.optimization.params.dateFrom);
            let datesDiff = (dateTo.getTime() - dateFrom.getTime()) / (1000 * 3600 * 24);
            let use1DayForCumulativesChart = datesDiff > 30;
            if (!state.demo && use1DayForCumulativesChart) {
              getExchange(strategy.exchange)
                .getHistoricalData(
                  `showResOpt-${Math.random() * 10000000 + 10000000}`,
                  strategy.pair,
                  ["1 day"],
                  dateFrom,
                  dateTo,
                  null,
                  false
                )
                .then((historicalData) => {
                  if (componentIsMounted.current && strategyIdTmp === strategyId.current && historicalData) {
                    let smallTfHistoricalData = historicalData["1 day"];
                    if (!smallTfHistoricalData || smallTfHistoricalData.length === 0) {
                      return;
                    }
                    let start = smallTfHistoricalData.find((el) => el.d >= props.optimization.params.dateFrom);
                    setMarketReturn(
                      ((smallTfHistoricalData[smallTfHistoricalData.length - 1].c - start.c) / start.c) * 100
                    );
                    let cumulatives = {};
                    Object.keys(optResult).forEach((key) => {
                      let result = optResult[key];
                      if (result && result.stats && result.stats.totalReturn > 0) {
                        cumulatives[key] = { trades: result.stats.trades };
                        attachCumulativesData(
                          cumulatives[key],
                          smallTfHistoricalData,
                          new Date(props.optimization.params.dateFrom)
                        );
                      }
                    });
                    setCumulatives(cumulatives);
                  }
                });
            }

            let tmpDate = new Date(dateTo.getTime());
            tmpDate.setDate(tmpDate.getDate() - 30);

            if (dateFrom < tmpDate) {
              dateFrom = tmpDate;
            }
            getExchange(strategy.exchange)
              .getHistoricalData(
                `showResOpt-${Math.random() * 10000000 + 10000000}`,
                strategy.pair,
                timeframes,
                dateFrom,
                dateTo,
                null,
                false
              )
              .then((historicalData) => {
                if (componentIsMounted.current && strategyIdTmp === strategyId.current && historicalData) {
                  let smallTfHistoricalData = historicalData[timeframes[0]];
                  if (!smallTfHistoricalData || smallTfHistoricalData.length === 0) {
                    return;
                  }
                  setHistoricalData(historicalData);
                  if (state.demo || !use1DayForCumulativesChart) {
                    let cumulatives = {};
                    Object.keys(optResult).forEach((key) => {
                      let result = optResult[key];
                      if (result && result.stats && result.stats.totalReturn > 0) {
                        cumulatives[key] = { trades: result.stats.trades };
                        attachCumulativesData(
                          cumulatives[key],
                          smallTfHistoricalData,
                          new Date(props.optimization.params.dateFrom)
                        );
                      }
                    });
                    setCumulatives(cumulatives);

                    let start = smallTfHistoricalData.find((el) => el.d >= props.optimization.params.dateFrom);
                    setMarketReturn(
                      ((smallTfHistoricalData[smallTfHistoricalData.length - 1].c - start.c) / start.c) * 100
                    );
                  }
                }
              });
          } else {
            setResults(null);
            setCumulatives(null);
            props.setResult(null);
            setMarketReturn("");
          }
        }
      })();
    } else {
      setResults(null);
      setCumulatives(null);
      setMarketReturn("");
    }
  }, [props.optimization]);

  useEffect(() => {
    if (!props.optimization) {
      let strategyIdTmp = strategyId.current;
      getEcbDatabase()
        .getOptimizations(props.strategy.id)
        .then((optimizations) => {
          if (componentIsMounted.current && strategyId.current === strategyIdTmp && optimizations.length > 0) {
            props.setResult(optimizations[0].optimization);
          }
        });
    }
  }, [props.optimizations]);

  return props.optimization ? (
    <div className="fade-in pl-2 pr-3">
      <div className="d-flex custom-hr mb-3 pb-2">
        <div className="flex-grow-1 text-center">
          <div>
            {formatDate2(props.optimization.params.dateFrom)} - {formatDate2(props.optimization.params.dateTo)}
          </div>
          {marketReturn !== "" && (
            <div className="text-center small">
              {props.strategy.pair}:
              <span
                className={`ml-2 mt-1 ${marketReturn > 0 ? "text-success" : marketReturn < 0 ? "text-danger" : ""}`}
              >
                {marketReturn.toFixed(2)}%
              </span>
            </div>
          )}{" "}
        </div>
        <div className="small cursor-help text-nowrap user-select-none tooltip-parent mr-2">
          <div className="text-info">
            Setup <FontAwesomeIcon icon={faQuestionCircle} />
          </div>

          <div className="tooltip-on-hover right-0 border border-info rounded bg-new-dark p-3">
            <div className="pb-1">
              <div className="d-inline-block min-w-150">Commission rate:</div>
              <div className="text-info d-inline-block">{props.optimization.params.commissionRate}%</div>
            </div>
            <div className="pb-1">
              <div className="d-inline-block min-w-150">Trade size:</div>
              <div className="text-info d-inline-block">
                {props.optimization.params.tradeSize && props.optimization.params.tradeSize.value > 0
                  ? `${props.optimization.params.tradeSize.value} ${
                      props.optimization.params.tradeSize.type === "base"
                        ? props.optimization.params.pairDetails.baseAsset
                        : props.optimization.params.pairDetails.quoteAsset
                    }`
                  : ""}
              </div>
            </div>
            <div className="pb-1">
              <div className="d-inline-block min-w-150">Min. trades count:</div>
              <div className="text-info d-inline-block">{props.optimization.params.minTrades}</div>
            </div>
            <div className="pb-1">
              <div className="d-inline-block min-w-150">Change trailing buy:</div>
              <div className="text-info d-inline-block">
                {props.optimization.params.additionalSellRules.tBuy ? "yes" : "no"}
              </div>
            </div>
            <div className="pb-1">
              <div className="d-inline-block min-w-150">Change stoploss:</div>
              <div className="text-info d-inline-block">
                {props.optimization.params.additionalSellRules.stoploss ? "yes" : "no"}
              </div>
            </div>
            <div className="pb-1">
              <div className="d-inline-block min-w-150">Change target:</div>
              <div className="text-info d-inline-block">
                {props.optimization.params.additionalSellRules.target ? "yes" : "no"}
              </div>
            </div>
            <div className="pb-1">
              <div className="d-inline-block min-w-150">Change trailing t.:</div>
              <div className="text-info d-inline-block">
                {props.optimization.params.additionalSellRules.trailingTarget ? "yes" : "no"}
              </div>
            </div>
            <div className="pb-1">
              <div className="d-inline-block min-w-150">Change time stop:</div>
              <div className="text-info d-inline-block">
                {props.optimization.params.additionalSellRules.timeClose ? "yes" : "no"}
              </div>
            </div>
            <div className="pb-1">
              <div className="d-inline-block min-w-150">Change condition:</div>
              <div className="text-info d-inline-block">
                {props.optimization.params.additionalSellRules.conditionValue ? "yes" : "no"}
              </div>
            </div>
            <div className="pb-1">
              <div className="d-inline-block min-w-150">Skipped buy rules:</div>
              <div className="text-info d-inline-block">
                {props.optimization.params.skipedRules.reduce(
                  (acc, el) => acc || props.strategy.buyRules.find((rule) => rule.id === el),
                  false
                )
                  ? ""
                  : "none"}
              </div>
            </div>
            {props.strategy.buyRules.map((rule) =>
              props.optimization.params.skipedRules.includes(rule.id) ? (
                <div className="pb-1 pl-2" key={rule.id}>
                  <div className="text-nowrap text-truncate">
                    <FontAwesomeIcon className="text-light-green small" icon={faAngleDoubleRight} /> {rule.indicator}
                    <span>{getIndicatorSetup(rule).replace(/ /g, "")}</span> {getInterval(rule.timeframe)}
                  </div>
                </div>
              ) : (
                ""
              )
            )}

            <div className="pb-1">
              <div className="d-inline-block min-w-150">Skipped sell rules:</div>
              <div className="text-info d-inline-block">
                {props.optimization.params.skipedRules.reduce(
                  (acc, el) => acc || props.strategy.sellRules.find((rule) => rule.id === el),
                  false
                )
                  ? ""
                  : "none"}
              </div>
            </div>
            {props.strategy.sellRules.map((rule) =>
              props.optimization.params.skipedRules.includes(rule.id) ? (
                <div className="pb-1 pl-2" key={rule.id}>
                  <div className="text-nowrap text-truncate">
                    <FontAwesomeIcon className="text-light-green small" icon={faAngleDoubleRight} /> {rule.indicator}
                    <span>{getIndicatorSetup(rule).replace(/ /g, "")}</span> {getInterval(rule.timeframe)}
                  </div>
                </div>
              ) : (
                ""
              )
            )}
          </div>
        </div>
      </div>

      {results ? (
        (results.consistentReturn && results.consistentReturn.stats.totalReturn > 0) ||
        (results.riskReward && results.riskReward.stats.totalReturn > 0) ||
        (results.maxReturn && results.maxReturn.stats.totalReturn > 0) ? (
          <div>
            {results.consistentReturn && results.consistentReturn.stats.totalReturn > 0 && (
              <div className="custom-hr">
                <VariationResult
                  pair={results.consistentReturn.strategy.pair}
                  strategy={results.consistentReturn.strategy}
                  stats={results.consistentReturn.stats}
                  cumulatives={cumulatives && cumulatives.consistentReturn}
                  label="Consistent Return"
                  historicalData={historicalData}
                  dateFrom={props.optimization.params.dateFrom}
                  saveStrategy={() => {
                    if ($("#optimizationResultModal").is(":visible")) {
                      $("#optimizationResultModal").modal("toggle");
                    }
                    props.saveStrategy(results.consistentReturn.strategy);
                  }}
                  topPanelHeight={props.topPanelHeight}
                  rightPanelWidth={props.rightPanelWidth}
                />
              </div>
            )}
            {results.riskReward && results.riskReward.stats.totalReturn > 0 && (
              <div className="custom-hr">
                <VariationResult
                  pair={results.riskReward.strategy.pair}
                  strategy={results.riskReward.strategy}
                  stats={results.riskReward.stats}
                  cumulatives={cumulatives && cumulatives.riskReward}
                  label="Risk/Reward"
                  historicalData={historicalData}
                  dateFrom={props.optimization.params.dateFrom}
                  saveStrategy={() => {
                    if ($("#optimizationResultModal").is(":visible")) {
                      $("#optimizationResultModal").modal("toggle");
                    }
                    props.saveStrategy(results.riskReward.strategy);
                  }}
                  topPanelHeight={props.topPanelHeight}
                  rightPanelWidth={props.rightPanelWidth}
                />
              </div>
            )}
            {results.maxReturn && results.maxReturn.stats.totalReturn > 0 && (
              <VariationResult
                pair={results.maxReturn.strategy.pair}
                strategy={results.maxReturn.strategy}
                stats={results.maxReturn.stats}
                cumulatives={cumulatives && cumulatives.maxReturn}
                label="Max Total Return"
                historicalData={historicalData}
                dateFrom={props.optimization.params.dateFrom}
                saveStrategy={() => {
                  if ($("#optimizationResultModal").is(":visible")) {
                    $("#optimizationResultModal").modal("toggle");
                  }
                  props.saveStrategy(results.maxReturn.strategy);
                }}
                topPanelHeight={props.topPanelHeight}
                rightPanelWidth={props.rightPanelWidth}
              />
            )}
          </div>
        ) : (
          <div className="text-center" style={props.topPanelHeight ? { minHeight: props.topPanelHeight - 200 } : {}}>
            <h5 className="text-secondary px-5">
              The optimization could not find a positive variation of your strategy!
            </h5>
          </div>
        )
      ) : (
        <div className="text-center" style={props.topPanelHeight ? { minHeight: props.topPanelHeight - 200 } : {}}>
          <div className="loading-img cursor-help my-5" title="Loading..">
            <img src={loading} alt="" />
          </div>
        </div>
      )}
    </div>
  ) : props.runningOptimization ? (
    <div className="text-center">
      <h5 className="text-secondary mt-5 mb-3">Running Optimization</h5>
      <div className="max-w-200 mx-auto">
        <OptimizationProgress runningOptimization={props.runningOptimization} strategy={props.strategy} />
      </div>
    </div>
  ) : (
    <div className="text-center">
      <h5 className="text-secondary px-5">Optimization results will be displayed here</h5>
      <a
        href="#/"
        className="text-info mt-5"
        title="New Optimization"
        onClick={(e) => {
          e.preventDefault();
          document.activeElement.blur();
          props.setStrategyActiveSubTabResult(false);
        }}
      >
        Setup new optimization
      </a>
    </div>
  );
};
export { OptimizationSettings, OptimizationResult };
