import React, { useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import $ from "jquery";
import { showInfoDialog, showConfirmDialog } from "./utils.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudDownloadAlt, faHandPointRight, faCog, faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import logoReverced from "./assets/images/logo-reverced.png";
import { globalContext } from "./Context.js";

const SubscriptionWarning = (props) => {
  let { state, dispatch } = useContext(globalContext);
  return state.subscriptionIsExpiring || state.user.isExpired ? (
    <div
      title={state.user.isExpired ? "Subscription has expired" : "Subscription is expiring"}
      className="blink update"
    >
      <a
        href="#/"
        className={state.user.isExpired ? "text-danger" : "text-warning"}
        title={state.user.isExpired ? "Subscription has expired" : "Subscription is expiring"}
        onClick={(e) => {
          e.preventDefault();
          document.activeElement.blur();
          showInfoDialog(
            dispatch,
            <span className={state.user.isExpired ? "text-danger" : "text-warning"}>
              <FontAwesomeIcon icon={faExclamationTriangle} /> Subscription warning
            </span>,
            <div>
              Your subscription {state.user.isExpired ? "expired" : "will expire"} on {state.user.expireDate}.<br />
              {state.user.isExpired && "Your account is now limited to the Free plan."}
            </div>
          );
        }}
      >
        <FontAwesomeIcon icon={faExclamationTriangle} />
      </a>
    </div>
  ) : (
    ""
  );
};

function showUpdateNowMessage(dispatch, state) {
  showConfirmDialog(
    dispatch,
    <div className="text-info">
      <FontAwesomeIcon icon={faCloudDownloadAlt} /> Update available
    </div>,
    <div>
      {Object.keys(state.serverVersion.info)
        .filter((el) => el > state.version)
        .map((el, index) =>
          state.serverVersion.info[el].map((subEl, index) => (
            <div key={`${el}-${index}`}>
              <FontAwesomeIcon className="text-info" icon={faHandPointRight} /> {subEl}
            </div>
          ))
        )}
      <hr />
      <div className="text-center">
        <h5 className="text-info">Update Now?</h5>
        <span className="">The application will restart.</span>
      </div>
    </div>,
    () => {
      if (navigator.serviceWorker) {
        navigator.serviceWorker.getRegistrations().then((registrations) => {
          for (let registration of registrations) {
            registration.unregister();
          }
          window.location.reload();
        });
      } else {
        window.location.reload();
      }
    }
  );
}
const UpdateAvailable = (props) => {
  let { state, dispatch } = useContext(globalContext);
  return state.serverVersion && state.serverVersion.version && state.version < state.serverVersion.version ? (
    <div title="Update available" className="blink update">
      <a
        href="#/"
        className="text-info"
        title="Update available"
        onClick={(e) => {
          e.preventDefault();
          document.activeElement.blur();
          showUpdateNowMessage(dispatch, state);
        }}
      >
        <FontAwesomeIcon icon={faCloudDownloadAlt} />
      </a>
    </div>
  ) : (
    ""
  );
};

const TopNav = (props) => {
  let { state } = useContext(globalContext);
  return (
    <div className={props.classes}>
      <div id={props.id}>
        <div className="nav-menu bg-new-darker">
          <ul className="navbar-nav">
            {props.routes.map((route) => {
              if (route.skipLink) {
                return "";
              }
              return (
                <li className="nav-item pull-right" key={route.name}>
                  <NavLink
                    className={`nav-link ${route.link ? "bg-info text-white rounded" : ""}`}
                    as={NavLink}
                    to={route.path}
                    exact={route.name !== "Manual"}
                    activeClassName="active"
                    onClick={(e) => {
                      document.activeElement.blur();
                    }}
                  >
                    {route.name === "Settings" && (
                      <>
                        <FontAwesomeIcon icon={faCog} />
                        &nbsp;
                      </>
                    )}
                    {route.name}
                    {route.name === "Contest" && state.nextContest && state.nextContest.month && (
                      <span className="bg-light-green align-top text-dark x-small rounded-circle blink-slow px-1 ml-1">
                        N
                      </span>
                    )}
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};
const Navigation = (props) => {
  let { state } = useContext(globalContext);
  useEffect(() => {
    const onBodyClick = (e) => {
      const ids = ["topNavBtn", "topNavBars"];
      if ($("#topNav").is(":visible")) {
        if (
          ids.indexOf(e.target.id) !== -1 ||
          ids.indexOf(e.target.parentElement.id) !== -1 ||
          ids.indexOf(e.target.parentElement.parentElement.id) !== -1
        ) {
          return;
        }
        $("#topNav").hide();
        $("#topNavBars").removeClass("change");
      }
    };
    document.body.addEventListener("click", onBodyClick);
    return () => {
      document.body.removeEventListener("click", onBodyClick);
    };
  }, []);

  return (
    <nav className="navbar navbar-expand-md bg-new-darker navbar-dark fixed-top user-select-none" id="topNavbar">
      <div className="nav-header bg-new-darker">
        <NavLink className="navbar-brand text-nowrap p-0" to="/" exact activeClassName="active">
          <img className="pb-1" src={logoReverced} alt="logo" style={{ width: "24px", height: "auto" }} />
          <span id="topNavLogoText">
            &nbsp;&nbsp;EasyCrypto<span className="text-info">Bot</span>
          </span>
        </NavLink>
        <button
          className="navbar-toggler border-0"
          type="button"
          id="topNavBtn"
          onClick={(e) => {
            if ($("#topNav").is(":visible")) {
              $("#topNav").slideUp("200");
              $("#topNavBars").removeClass("change");
            } else {
              $("#topNavBars").toggleClass("change");
              $("#topNav").slideDown("200");
            }
          }}
        >
          <div id="topNavBars">
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
          </div>
        </button>
      </div>
      <TopNav classes="small-nav" id="topNav" routes={props.routes} />
      <TopNav classes="big-nav mr-auto" id="topNavBig" routes={props.routes.filter((el) => !el.right)} />
      <TopNav classes="big-nav navbar-right pr-3" id="topNavBig" routes={props.routes.filter((el) => el.right)} />
      {!state.subscriptionIsExpiring && <UpdateAvailable />}
      <SubscriptionWarning />
    </nav>
  );
};

export default Navigation;
export { Navigation, showUpdateNowMessage };
