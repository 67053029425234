import React, { useState, useEffect } from "react";
import ReactDOMServer from "react-dom/server";
import "video-react/dist/video-react.css";
import { Meta } from "./App.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faBookOpen,
  faTimes,
  faPlay,
  faBan,
  faPlusCircle,
  faVolumeMute,
  faVolumeUp,
  faPlus,
  faCog,
  faRedoAlt,
  faTimesCircle,
  faFolderPlus,
  faFolderMinus,
  faClone,
  faEdit,
  faSave,
  faCaretDown,
  faExclamationTriangle,
  faChartPie,
  faStop,
  faUndo,
  faCaretUp,
  faTable,
  faChartArea,
  faChartLine,
  faExpandArrowsAlt,
  faCompressAlt,
  faCaretRight,
  faCaretLeft,
} from "@fortawesome/free-solid-svg-icons";
import acc from "./assets/images/docum/acc.png";
import accMenus from "./assets/images/docum/accMenus.png";
import charts from "./assets/images/docum/charts.png";
import chartsChart from "./assets/images/docum/chartsChart.png";
import bt from "./assets/images/docum/bt.png";
import btResult1 from "./assets/images/docum/btResult1.png";
import btRun from "./assets/images/docum/btRun.png";
import btResult2 from "./assets/images/docum/btResult2.png";
import howToScheme from "./assets/images/docum/howToScheme.png";
import howToSchemeMob from "./assets/images/docum/howToScheme-mob.png";
import layoutTools from "./assets/images/docum/layoutTools.png";
import layoutViews from "./assets/images/docum/layoutViews.png";
import live from "./assets/images/docum/live.png";
import liveSetup from "./assets/images/docum/liveSetup.png";
import liveControl from "./assets/images/docum/liveControl.png";
import liveResult from "./assets/images/docum/liveResult.png";
import manTr from "./assets/images/docum/manTr.png";
import manTrMenus from "./assets/images/docum/manTrMenus.png";
import manTrBS from "./assets/images/docum/manTrBS.png";
import op from "./assets/images/docum/op.png";
import opRun from "./assets/images/docum/opRun.png";
import opResult from "./assets/images/docum/opResult.png";
import opSave from "./assets/images/docum/opSave.png";
import rBolb from "./assets/images/docum/rBolb.png";
import rPrice from "./assets/images/docum/rPrice.png";
import rCandle from "./assets/images/docum/rCandle.png";
import rDoji from "./assets/images/docum/rDoji.png";
import rBullPat from "./assets/images/docum/rBullPat.png";
import rBearPat from "./assets/images/docum/rBearPat.png";
import rKelt from "./assets/images/docum/rKelt.png";
import rMacd from "./assets/images/docum/rMacd.png";
import rMom from "./assets/images/docum/rMom.png";
import rMomSq from "./assets/images/docum/rMomSq.png";
import rRsi from "./assets/images/docum/rRsi.png";
import rSma from "./assets/images/docum/rSma.png";
import rStoch from "./assets/images/docum/rStoch.png";
import sCrt from "./assets/images/docum/sCrt.png";
import sGr from "./assets/images/docum/sGr.png";
import sGrAdd from "./assets/images/docum/sGrAdd.png";
import sGrSettings from "./assets/images/docum/sGrSettings.png";
import actionPanel from "./assets/images/docum/actionPanel.png";
import sMode from "./assets/images/docum/sMode.png";
import sRuleCrt from "./assets/images/docum/sRuleCrt.png";
import sRuleCrtCond from "./assets/images/docum/sRuleCrtCond.png";
import sRuleCrtRule from "./assets/images/docum/sRuleCrtRule.png";
import sRuleCrt2 from "./assets/images/docum/sRuleCrt2.png";
import sTrBuy from "./assets/images/docum/sTrBuy.png";
import sSellOpt from "./assets/images/docum/sSellOpt.png";
import sStl from "./assets/images/docum/sStl.png";
import sTar from "./assets/images/docum/sTar.png";
import sTst from "./assets/images/docum/sTst.png";
import sCond from "./assets/images/docum/sCond.png";
import sim from "./assets/images/docum/sim.png";
import simSetup from "./assets/images/docum/simSetup.png";
import simControl from "./assets/images/docum/simControl.png";
import simResult from "./assets/images/docum/simResult.png";
import tChartMultiTime from "./assets/images/docum/tChartMultiTime.png";
import tOrdersSim from "./assets/images/docum/tOrdersSim.png";
import tChartView from "./assets/images/docum/tChartView.png";
import tPanel from "./assets/images/docum/tPanel.png";
import tTable from "./assets/images/docum/tTable.png";
import tTab from "./assets/images/docum/tTab.png";
import tStatus from "./assets/images/docum/tStatus.png";
import wMenu from "./assets/images/docum/wMenu.png";
import wMenu2 from "./assets/images/docum/wMenu2.png";
import wPairs from "./assets/images/docum/wPairs.png";
import wChart from "./assets/images/docum/wChart.png";
import Videos from "./Videos.js";
import { isVisibleById } from "./utils.js";
import { NavLink, Route, Redirect, Switch } from "react-router-dom";
import Footer from "./Footer.js";
import Questions from "./Questions.js";

let allRoutes = [
  {
    title: "How to get started",
    path: "/manual/how-to-get-started",
    pageTitle: "EasyCryptoBot - How to Get Started",
    metaContent:
      "Few simple steps and you will be ready to trade with your crypto strategy. If you need us, we are here for you on every step of the way - just give us a note.",
    content: [
      {
        image: howToScheme,
        imageMob: howToSchemeMob,
        alt: "EasyCryptoBot - crypto trading bot - How to get started scheme",
        text: (
          <ul className="list-unstyled">
            <li>
              <span className="text-info font-weight-bold">1.</span> Create your
              account via the Sign Up form. You will get 7 Days Full Access for
              FREE. After that your account will be limited to the Free
              subscription plan. No credit card needed on creation of the
              account.
            </li>
            <li>
              <span className="text-info font-weight-bold">2.</span> Validate
              your email by clicking on the verification link send to your email
              and sign in.
            </li>
            <li>
              <span className="text-info font-weight-bold">3.</span> Download
              the app for your operating system. The bot runs entirely on your
              computer. That maximises security - no one but you has access to
              your exchange credentials. Access to your API keys and
              connection to your exchange accounts happen only from your
              computer. <br />
              If you need a cloud service, contact us for more information.
            </li>
            <li>
              <span className="text-info font-weight-bold">4.</span> Go to BotTrading.
            </li>
            <li>
              <span className="text-info font-weight-bold">5.</span> Create a strategy or load a template.
            </li>
            <li>
              <span className="text-info font-weight-bold">6.</span> Optional - backtest and/or optimize your strategy.
            </li>
            <li>
              <span className="text-info font-weight-bold">7.</span> Start your strategy in simulation or live trading.
              For live trading you will need to add your exchange key and secret. They are stored only on your computer
              and no one has access to them except you.
            </li>
            <li>
              <span className="text-info font-weight-bold">8.</span> After 7 days if you wish to continue using the app,
              go to Settings and subscribe for your preferred time.
            </li>
          </ul>
        ),
      },
    ],
  },
  {
    title: "Layout",
    path: "/manual/layout",
    pageTitle: "EasyCryptoBot - How to Change the Layout",
    metaContent:
      "EasyCryptoBot offers an adjustable layout to suit your way of using the platform and for maximum comfort when working with it. Learn how to set it.",
    content: [
      {
        image: layoutTools,
        alt: "EasyCryptoBot - crypto trading bot - Layout setup",
        text: (
          <>
            The layout of the platform can be adjusted to maximise your comfort while working with it. Main components
            include:
            <ul>
              <li>
                <span className="border-2 border-left border-right border-info pl-1 mr-3" />
                <div className="d-inline-block border-2 border-left border-right border-info rotate-90 pl-1 mr-2" />-
                Dragable lines - use them to adjust the height and width of the panels
              </li>
              <li>
                <FontAwesomeIcon icon={faExpandArrowsAlt} className="text-green mr-1" />- expand the panel
              </li>
              <li>
                <FontAwesomeIcon icon={faCompressAlt} className="text-green mr-1" />- contract the panel
              </li>
              <li>
                <FontAwesomeIcon icon={faCaretLeft} className="text-green mr-1" />
                <FontAwesomeIcon icon={faCog} className="text-green mr-1" />- hide setup
              </li>
              <li>
                <FontAwesomeIcon icon={faCog} className="text-green mr-1" />
                <FontAwesomeIcon icon={faCaretRight} className="text-green mr-1" />- show setup
              </li>
              <li>
                <span className="text-green">Layout</span>
                <FontAwesomeIcon icon={faCog} className="text-green mx-1" />- customize the position of the panels
              </li>
            </ul>
          </>
        ),
      },
      {
        image: layoutViews,
        alt: "EasyCryptoBot - crypto trading bot - Layout views setup",
        text: (
          <>
            The position of the panels in BotTrading can be further adjusted by{" "}
            <span className="text-green">Layout</span>
            <FontAwesomeIcon icon={faCog} className="text-green mx-1" />
            to maximize your comfort when working with strategies. It's easy to set up and you can choose between
            different views according to your preferences.
            <div className="my-2">
              The default layout is <span className="text-info">Multi Panels View</span> with two page views. You can
              remove views, add new ones, change their names, type and position of panels. Each view you add will appear
              in the menu above the panels with its corresponding name. When you click on it, it will open the page in
              the corresponding view type with the content you selected for that view.
            </div>
            <div>
              In <span className="text-info">Single Panel View</span> all panels appear as type 1. You can navigate to
              the desired panel via the menu.
            </div>
          </>
        ),
      },
    ],
  },
  {
    id: "manualTradingBtn",
    title: "Trading",
    mainForSub: true,
  },
  {
    main: "Trading",
    title: "Trading tab",
    path: "/manual/trading",
    pageTitle: "EasyCryptoBot - All You Need for Your Trading",
    metaContent:
      "Learn how to create watchlists, mini-charts groups, add indicators to charts, trade manually through the platform and more.",
    content: [
      {
        image: tTab,
        alt: "EasyCryptoBot - crypto trading bot - trading tools",
        text: (
          <>
            On the <span className="text-info">Trading section</span> you will find useful tools for your trading:
            <ul>
              <li>
                <a href="/manual/watchlists" className="text-info underline mx-1">
                  Watchlists
                </a>
                - you can create watchlists with your favorite coins so you can easily see what's going on in the
                market.
              </li>
              <li>
                <a href="/manual/charts" className="text-info underline mx-1">
                  Charts
                </a>
                - you can create groups of charts to visually track changes in different coins or to track changes in
                trends in the same coin.
              </li>
              <li>
                <a href="/manual/accounts" className="text-info underline mx-1">
                  Accounts
                </a>
                - add an exchange account and easily follow your account balance.
              </li>
              <li>
                <a href="/manual/manual-trading" className="text-info underline mx-1">
                  Manual trading
                </a>
                - execute manual trades through the platform. You can manually close them or add a stoploss, target or
                trailing target and let the bot take care of your position.
              </li>
            </ul>
          </>
        ),
      },
    ],
  },
  {
    main: "Trading",
    title: "Watchlists panel",
    path: "/manual/watchlists",
    pageTitle: "EasyCryptoBot - How to Create Watchlists",
    metaContent:
      "Organize your favourite pairs into watchlists to easily see what's happening in the market. Learn how now!",
    content: [
      {
        image: wMenu,
        alt: "EasyCryptoBot - crypto trading bot - strategies groups",
        text: (
          <>
            <span className="text-info">Watchlists</span> panel allows you to organize your favorite coins into groups
            so you can easily track changes in the market. You can create your own watchlists or use the default ones.
          </>
        ),
        textAfterPic: (
          <>
            Click on the name of the watchlist and a menu will appear. You can create a new watchlist from there or load
            an existing one.
            <br />
            Type in the <span className="text-info">Add pair to list +</span> search menu to search for coin-pairs.
            Click on a coin in the menu to add it to your list.
            <br />
            The
            <FontAwesomeIcon icon={faCog} className="text-info mx-1" />
            (Settings) menu lets you rename and delete a list.
            <div className="text-center my-3">
              <img
                className="w-custom"
                src={wMenu2}
                alt="EasyCryptoBot - crypto trading bot - add pair to watchlist, manage list"
              />
            </div>
          </>
        ),
      },
      {
        image: wPairs,
        alt: "EasyCryptoBot - crypto trading bot - how to work with a watchlist",
        text: (
          <>
            You can sort the watchlist table by clicking on the column you want to sort by.
            <br />
            Click on a row to load the chart of the coin pair.
            <br />
            <FontAwesomeIcon icon={faTimesCircle} className="text-danger mr-1" />- removes the coin pair from the list.
          </>
        ),
      },
      {
        image: wChart,
        alt: "EasyCryptoBot - crypto trading bot - coin-pair chart",
        text: (
          <>
            You can also change the pair of the chart by clicking on the pair on the chart and then type to search for a
            new one. Different timeframes are available - select the one you need.
            <br />
            Use the
            <FontAwesomeIcon icon={faChartLine} className="text-green mx-1" />
            to add an indicator to the chart.
          </>
        ),
      },
    ],
  },
  {
    main: "Trading",
    title: "Charts panel",
    path: "/manual/charts",
    pageTitle: "EasyCryptoBot - How to Create Groups of Charts",
    metaContent:
      "Create groups of charts to visually track changes in different coins or to track changes in trends in the same coin. Learn how now!",
    content: [
      {
        image: charts,
        alt: "EasyCryptoBot - crypto trading bot - charts groups",
        text: (
          <>
            The <span className="text-info">Charts</span> panel allows you to create groups of charts to visually track
            changes in different coins or to track changes in trends in the same coin. For example, you can make a group
            of one major coin and some alt coins to track the correlation between them, whether they move together,
            whether any of them lag behind. This way you can quickly enter into positon. Another possible group is a
            group of charts for the same coin, but on different timeframes. In this way, you can notice changes in the
            short-term trend compared to the long-term trend.
          </>
        ),
        textAfterPic: (
          <>
            The <span className="text-info">Chart group menu</span> is located in the upper left corner of the panel.
            Click on the group name and the menu will appear. You can create a new group or load an existing one, change
            the name of the currently loaded one, duplicate it or delete it.
            <br />
            In the upper right corner are the controls for the <span className="text-info">width</span> and{" "}
            <span className="text-info">height</span> of the charts. Adjust them to your preferences.
          </>
        ),
      },
      {
        image: chartsChart,
        alt: "EasyCryptoBot - crypto trading bot - coin-pair chart",
        text: (
          <>
            For every chart in the group you can:
            <ul>
              <li>change the coin-pair - click on the pair and type to search for a new one.</li>
              <li>
                <FontAwesomeIcon icon={faChartLine} className="text-green mr-1" />- add an indicator to the chart.
              </li>
              <li>
                <FontAwesomeIcon icon={faTimesCircle} className="text-danger mr-1" />- remove the chart from the group.
              </li>
            </ul>
          </>
        ),
      },
    ],
  },
  {
    main: "Trading",
    title: "Accounts panel",
    path: "/manual/accounts",
    pageTitle: "EasyCryptoBot - How to Add Your Exchange Account",
    metaContent:
      "Learn how to add your exchange account, so you can track your account balance, run live bot trading and trade manually directly through the platform.",
    content: [
      {
        image: acc,
        alt: "EasyCryptoBot - crypto trading bot - Exchange Account",
        text: (
          <>
            On the <span className="text-info">Accounts</span> panel you can add accounts for the available exchanges.
            To add an account first, create an API key in your exchange account. Then, click on the{" "}
            <span className="text-info">Add account</span>. In the pop-up enter the API key and secret. No one has
            access to them except your computer.
            <br />
            For Binance exchange you can create API keys by following this
            <a
              href="https://www.binance.com/en/support/articles/360002502072"
              rel="nofollow noopener noreferrer"
              target="_blank"
              className="text-info underline mx-1"
            >
              tutorial.
            </a>
          </>
        ),
      },
      {
        image: accMenus,
        alt: "EasyCryptoBot - crypto trading bot - account menus",
        text: (
          <>
            The Account panel has two menus:
            <ul>
              <li>
                <span className="text-info">accounts menu</span> - click on the name of the account and the menu will
                appear. You can add a new account from there or load an existing one.
              </li>
              <li>
                <FontAwesomeIcon icon={faCog} className="text-info mr-1" />- the settings menu allows you edit Api keys
                and delete the account.
              </li>
            </ul>
          </>
        ),
      },
    ],
  },
  {
    main: "Trading",
    title: "Manual trading panel",
    path: "/manual/manual-trading",
    pageTitle: "EasyCryptoBot - Direct Trading via the Platform",
    metaContent:
      "Learn how to trade manually, non-automatically, through the platform. Add stoploss and target to your positions and let the bot take care of them. ",
    content: [
      {
        image: manTr,
        alt:
          "EasyCryptoBot - crypto trading bot - Manual trading through the platform",
        text: (
          <>
            In the <span className="text-info">Manual trading</span> panel you
            can make direct purchases and sales without strategies, without
            automation. The manual trading panel can be found as a separate
            sub-tab panel and as a bottom panel under
            <a href="/manual/watchlists" className="text-info underline mx-1">
              Wachlists
            </a>
            and
            <a href="/manual/watchlists" className="text-info underline mx-1">
              Accounts
            </a>
            panels.
          </>
        ),
      },
      {
        image: manTrMenus,
        alt: "EasyCryptoBot - crypto trading bot - manual trading panel menus",
        text: (
          <>
            The Manual trading menus:
            <ul>
              <li>
                <span className="text-info">accounts menu</span> - you can add a
                new account from there or load an existing one.
              </li>
              <li>
                <span className="text-info">pair selection</span> - type to
                search for a pair to trade with.
              </li>
              <li>
                <span className="text-info">Open trades tab</span> - keeps all
                currently active manual positions. You can close them or add
                stoploss and target. If you don't need to see the trade as an
                active position you can simply remove it with the
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  className="text-danger ml-1"
                />
                . This will not close the position and the bought coin will
                remain in your balance.
              </li>
              <li>
                <span className="text-info">History tab</span> - keeps all
                closed manual positions. You can remove them with the
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  className="text-danger ml-1"
                />
                .
              </li>
            </ul>
          </>
        ),
      },
      {
        image: manTrBS,
        alt: "EasyCryptoBot - crypto trading bot - manual trading panel menus",
        text: (
          <>
            After selecting the pair you want to trade with, click on the
            <span className="btn btn-sm btn-info px-3 mx-1">Buy</span>or
            <span className="btn btn-sm btn-info px-3 mx-1">Sell</span> button.
            The
            <span className="btn btn-sm btn-info px-3 mx-1">Buy</span>will buy
            the base coin of the pair and you will have it in your account
            balance. The
            <span className="btn btn-sm btn-info px-3 mx-1">Sell</span>will sell
            the base coin and you will receive the quote coin in your balance.
            The Base coin is the coin on the left side of the coin pair. The
            Quote coin is the coin on the right side of the coin pair. For
            example, BTCUSDT - base is BTC, quote is USDT.
          </>
        ),
        textAfterPic: (
          <>
            You can choose your trading size and type of the order. The order
            type applies only to the immediate action - buy or sell. The
            stoploss and target in manual trading are executed with
            <b> Market </b>
            orders.
            <ul>
              <li>
                <span className="text-info">market</span> – the filling of the
                Market order can move the price of the trading pair if there is
                not enough trading volume and your trading size is too big. The
                order will continue to fulfil on portions during the rising of
                the price until it is complete. The final price will be the
                average of all mini-portions. This is the way exchanges operate.
              </li>
              <li>
                <span className="text-info">limit</span> - you can choose the
                price for the Limit order. On the exchange the buying will
                happen only on this price. If the price moves, the completion of
                the order will stop. This is the way exchanges operate.
              </li>
            </ul>
          </>
        ),
      },
    ],
  },
  {
    id: "manualBotTradingBtn",
    title: "BotTrading",
    mainForSub: true,
  },
  {
    main: "BotTrading",
    title: "Create strategy",
    path: "/manual/create-strategy",
    pageTitle: "EasyCryptoBot - How to Create a Strategy",
    metaContent:
      "Learn how to create a strategy by using famous candle patterns, indicators, trends and moves, set stoploss, target, trailing target and trailing buy and more!",
    content: [
      {
        image: sCrt,
        alt: "EasyCryptoBot - Create a trading strategy",
        text: (
          <>
            Create different strategies to run in different executions. You can start from scratch or load a template.
            Use the template as is or change it according to your preferences.
          </>
        ),
      },
      {
        image: sRuleCrt,
        alt: "EasyCryptoBot - Crypto trading strategy settings - adding a technical instrument",
        text: (
          <>
            <div className="text-info">Rules:</div>
            For a valid strategy you have to add at least 1 buy rule and 1 sell option. Manual buy is also part of the
            instruments. You can add as many rules as you want. When all Buy rules are met, the bot will place a buy
            order. Click the{" "}
            <span className="bg-dark text-light small rounded p-1">
              <FontAwesomeIcon className="text-light-green" icon={faPlusCircle} /> Add
            </span>{" "}
            button next to Buy rules to see the list with all instruments available.
          </>
        ),
      },
      {
        image: sRuleCrtCond,
        alt: "Crypto trading strategy settings - Momentum Squeeze options for rule",
        text: (
          <>
            After selecting your preferred instrument all trading conditions for that instrument will be displayed. Hit
            the{" "}
            <span className="bg-dark rounded small p-1">
              <FontAwesomeIcon className="text-light-green" icon={faPlus} />
            </span>{" "}
            to select one or more options that you need for your buy rule. Active (selected) conditions are brighter and
            the inactive conditions are greyed-out.
          </>
        ),
      },
      {
        image: sRuleCrtRule,
        alt: "EasyCryptoBot - Crypto trading strategy settings - Momentum Squeeze rule",
        text: (
          <>
            Now, fill the blanks of the active conditions. First select a time-frame for the instrument. You can have up
            to 2 different time-frames for the whole strategy. All instruments have different blank fields according to
            specifics of trading with them. If you no longer want a condition to be active, click on the{" "}
            <FontAwesomeIcon className="text-danger small" icon={faTimes} />.
          </>
        ),
      },
      {
        image: sRuleCrt2,
        alt: "EasyCryptoBot - Crypto trading strategy settings - setting a rule",
        text: (
          <>
            Via the instrument-settings-icon{" "}
            <span className="bg-dark rounded small p-1">
              <FontAwesomeIcon className="text-light-green" icon={faCog} />
            </span>{" "}
            you can edit the instrument parameters, e.g. the period of the SMA.
          </>
        ),
      },
      {
        image: sMode,
        alt: "Crypto trading strategy settings - Trade immediately or with Confirmation",
        text: (
          <>
            <span className="text-info">Validation mode</span>
            <br />- <span className="text-info">On candle close</span> - checks the rule only on candle close. This is
            the final value of the technical instrument for the candle.
            <br />- <span className="text-info">On current price</span> - checks the rule on every price tick. As this
            is a current state of the instrument it may or may not be confirmed on close of the candle. The Live charts
            show the changing value for the current candle. All other past values are the values of instrument on close
            of the candle. Backward looking of the indictors may seem as if the rule shouldn't have been fulfilled.
            However, during real time the instrument value actually fulfilled the rule.
            <br />
            <br />
            Example: Strategy with only one rule "Buy when RSI is crossing 35 from bottom to top". The previous candle
            RSI is 30 and the price moves upwards making the current value of the RSI 36. The "validate on current
            price" mode will place a Buy order. The "validate on candle close" mode will wait the candle to close and
            then will check if the RSI is still above 35.
          </>
        ),
      },
      {
        image: sTrBuy,
        alt: "EasyCryptoBot - Crypto trading strategy settings - setting a rule",
        text: (
          <>
            <div className="text-info">Trailing Buy:</div>
            When all buy rules are met the bot will wait for the price to go up by the provided percentage. The
            percentage is calculated from the lowest point after fulfillment of the buy rules. The trailing buy timer
            sets how long the bot will wait for the price to reach the set percentage. If the price does not go up
            within the provided time the BUY signal will be disregarded. Then the bot will check the rules again and
            start over.
            <br />
            <br />
            Examples:
            <p>
              Trailing buy is 1% and the timer is set to 5 minutes.
              <br />
              All BUY rules are met and the current price of the pair is 100.
            </p>
            <ul>
              <li className="mb-1">
                <span className="underlined">Scenario 1: </span>
                <br />
                While the timer is active the price starts to rise. It reaches 101 (1% rise from 100). The bot places
                the BUY order.
              </li>
              <li className="mb-1">
                <span className="underlined">Scenario 2: </span>
                <br />
                While the timer is active, first the price starts to fall and goes to 95. Then it changes direction and
                starts to rise. It reaches 95.95 (1% rise from 95, percentage is calculated from the lowest point after
                fulfillment of the buy rules). The bot places the BUY order.
              </li>
              <li className="mb-1">
                <span className="underlined">Scenario 3: </span>
                <br />
                While the timer is active, the price starts to fall. After the timer finishes the price is still below
                the 1% rise. The bot disregards the BUY signal. Then the bot starts over with check of the BUY rules.
              </li>
            </ul>
          </>
        ),
      },
      {
        image: sSellOpt,
        alt: "EasyCryptoBot - Crypto trading strategy settings - sell options",
        text: (
          <>
            <div className="text-info">Sell options:</div>
            You can choose among <span className="text-info">sell rules</span>,{" "}
            <span className="text-info">stoploss</span>, <span className="text-info">target</span> and{" "}
            <span className="text-info">trailing target</span>, and <span className="text-info">time stop</span>. Select
            one or more options. The first one met triggers a sell order. <br />
            The same principles apply to the sell rules as to the buy rules.
          </>
        ),
      },
      {
        image: sStl,
        alt: "EasyCryptoBot - Crypto trading strategy settings - setting a stoploss",
        text: (
          <>
            The <span className="text-info">Stoploss</span> is a sell price under the opening price set to avoid big
            losses. When the price reaches the stoploss, the bot will place a MARKET sell order.
          </>
        ),
      },
      {
        image: sTar,
        alt: "Crypto trading strategy settings - setting a target, trailing target",
        text: (
          <>
            The <span className="text-info">Target</span> is a set sell price above the opening price. When the price
            reaches the target, the bot will place a sell order.
            <br />
            Additionally you can set a <span className="text-info">Trailing target</span>. The bot will not place a SELL
            order when the Target is reached. Instead, when the target is reached the bot will place a trailing sell
            order (trailing target). The percent is the distance between the trailing sell order and the price. The
            trailing target will move and follow only a rising price at the set distance. It will stay in place if the
            price is falling. If the price reaches the trailing target on its way down, the bot will execute the sell
            order.
            <br />
            <br />
            Example:
            <p>
              Target is 2%. Trailing target is 1%. <br />
              The BUY rules are met and the current price is 100. <br />
              Then the price rises to 102 (2% above 100, target is reached). The trailing target is activated and set
              initially at 100.98 (1% below 102).
            </p>
            <ul>
              <li className="mb-1">
                <span className="underlined">Scenario 1: </span>
                <br />
                The price directly starts to fall. It reaches the trailing target at 100.98. The bot executes a sell
                order.
              </li>
              <li className="mb-1">
                <span className="underlined">Scenario 2: </span>
                <br />
                First, the price continues to rise. The trailing target follows it at 1% below. Then at some point, the
                price changes direction and starts to fall. At this moment the trailing target is at 110 and holds this
                value while the price is falling. The price reaches 110. The bot executes a sell order.
              </li>
            </ul>
          </>
        ),
      },
      {
        image: sTst,
        alt: "Crypto trading strategy settings - setting a time stop on a trade",
        text: (
          <>
            The <span className="text-info">Time stop</span> is an end time of an open trade. If other sell options were
            not set or fulfilled during that time, the bot will place a Market sell order.
          </>
        ),
      },
      // {
      //   image: sCond,
      //   alt:
      //     "Crypto trading strategy settings - using a global condition in a strategy",
      //   text: (
      //     <>
      //       The <span className="text-info">Condition</span> lets you
      //       automatically pause the trading of the strategy. The pause can be
      //       set for drops or rises in the price of another pair (or the
      //       strategy's trading pair). The condition will come into effect if the
      //       rolling 24 hour price move of the set coin is equal to the set
      //       percent and direction. For example, if BTC is falling from a cliff
      //       you might want to pause your strategy for the time being.
      //     </>
      //   ),
      // },
    ],
  },
  {
    main: "BotTrading",
    title: "Technical instruments",
    path: "/manual/indicators",
    pageTitle: "EasyCryptoBot - How to Use Technical Analysis Instruments",
    metaContent:
      "We offer the most valuable instruments used to build famous technical strategies. Use price moves, candle patterns, crosses, trends, reversals, and more.",
    content: [
      {
        image: rPrice,
        alt: "Crypto signals/ technical instruments - price moves and crosses - strategy and results",
        text: (
          <>
            <div className="font-italic text-center">Contact us if you don't find your favourite instrument.</div>
            <br />
            You can use <span className="text-info">the price</span> percent movements as buy and sell signals. This is
            useful for swing trading. You can also use price crosses with technical indicators like SMA, EMA, Bollinger
            Bands, Keltner Channel. The up/down percentages are calculated from the lowest/highest points of the price
            after the initial start of the strategy. Then they are calculated from the lowest/highest points of the
            price after the last trade close. If the execution is stopped it will "remember" its last point for
            calculation and will use it on restart. That is done so the strategy will not be disrupted if the bot is
            restarted, if there is loss of power or connection. If you want the execution to start from the point of
            restart instead, you have to reset it (
            <FontAwesomeIcon className="text-info mx-1" icon={faRedoAlt} />) before restart.
          </>
        ),
      },
      {
        image: rCandle,
        alt: "EasyCryptoBot - Crypto signals/ technical instruments - candle patterns",
        text: (
          <>
            You can include 
            <span className="text-info">candle moves and candle patterns</span>
             in your strategies. Candle moves include open to close/current price, low to high, upper shadow and lower
            shadow. They are a great way to make a rule with the pure price movement. <br />
            You can also use classical candle patterns. The bot implements the following patterns: doji, hammer and
            inverted hammer, hanging man, shooting star, bullish and bearish engulfs, bullish and bearish harami,
            morning and evening star. The bot takes into account the trend (for patterns with trend) and recognises the
            pattern of the candlestick.
          </>
        ),
      },
      {
        image: rDoji,
        alt: "EasyCryptoBot - Candle pattern - Doji",
        text: (
          <>
            For example:
            <br />
            The <span className="text-info">Doji</span> is a reversal trend pattern in both up and down trends.
          </>
        ),
      },
      {
        image: rBullPat,
        alt: "EasyCryptoBot - Bullish candle patterns",
        text: (
          <>
            <span className="text-info">Bullish patterns:</span>
            <br />
            <br />
            The <span className="text-info">Hammer</span> found in a downtrend indicates a potential price reversal to
            the upside. The price should go up following the hammer.
            <br />
            The <span className="text-info">Inverted hammer</span> found in a downtrend indicates a trend-reversal
            signal. The price should go up following the inverted hammer.
            <br />
            The <span className="text-info">Bullish engulfing</span> pattern forms when a big green candle completely
            overlaps the previous red candle. When it occurs in a downtrend it is a strong indicator for reversing to an
            uptrend.
            <br />
            The <span className="text-info">Bullish harami</span> is a sign of a reversal in a bear price movement. The
            pattern consists of a small green candle that is contained within the previous red candle.
            <br />
            The <span className="text-info">Morning star</span> pattern is a bullish reversal pattern. It appears at the
            end of a downtrend. It’s a three-candle pattern, made of a red candle, followed by a small red or green
            candle that opens below the previous close. The third candle is a bigger green candle that closes in the
            body of the first candle.
          </>
        ),
      },
      {
        image: rBearPat,
        alt: "EasyCryptoBot - Bearish candle patterns",
        text: (
          <>
            <span className="text-info">Bearish patterns:</span>
            <br />
            <br />
            The <span className="text-info">Hanging man</span> found in an uptrend is a bearish reversal pattern. The
            price should go down after that.
            <br />
            The <span className="text-info">Shooting star</span> is a bearish reversal candlestick pattern that occurs
            at the top of uptrends. The price should go down afterwards.
            <br />
            The <span className="text-info">Bullish engulfing</span> pattern forms when a red green candlestick
            completely overlaps the previous green candlestick. When it occurs in an uptrend it is a strong indicator
            for reversing to a downtrend.
            <br />
            The <span className="text-info">Bearish harami</span> is a sign of a reversal in a bull price movement. The
            pattern consists of a small red candle that can be contained within the previous green candle.
            <br />
            The <span className="text-info">Evening star</span> pattern is a bearish reversal pattern. It appears at the
            end of an uptrend. It’s a three-candle pattern, made of a green candle, followed by a small red or green
            candle that opens above the previous close. The third candle is a bigger red candle that closes in the body
            of the first candle.
          </>
        ),
      },
      {
        image: rSma,
        alt: "EasyCryptoBot - SMA and EMA strategy and results",
        text: (
          <>
            <span className="text-info">Simple Moving Average (SMA)</span> and{" "}
            <span className="text-info">Exponential Moving Average (EMA)</span> are an average of the closing prices of
            the last N candles. N is the number that you select in the settings of the indicator. EMA is a moving
            average that gives higher weights to recent prices. Thus, it reacts more quickly to the changes of the
            price.
            <br />
            Moving averages are an important analytical tool used to identify trends and the potential for a change in
            an established trend. The bot has two ways for determining the trends:
            <ul>
              <li>
                consecutive values - the SMA/EMA must have N consecutive SMA/EMA values in the choosen direction (N is
                defined by you);
              </li>
              <li>
                uptrend/downtrend - the SMA/EMA should have risen or fallen by N percents and most of the values in that
                rise or fall must be in a pronounced direction (N is defined by you). The reversing condition also uses
                this type of trends.
              </li>
            </ul>
            Possible strategies are a crossing strategy or a strategy with reversing SMA. You can combine SMAs with
            different periods or on different timeframes. You can also add their trend condition as an additional
            confirmation to other rules. Run the Optimization to find the best parameters.
          </>
        ),
      },
      {
        image: rRsi,
        alt: "EasyCryptoBot - RSI strategy and results",
        text: (
          <>
            The <span className="text-info">Relative Strength Index (RSI)</span> measures the size of recent price
            changes to evaluate overbought or oversold conditions. RSI can have a reading from 0 to 100. The 0
            corresponds to extreme lows of the price ("oversold"asset) and 100 to extreme highs of the price
            ("overbought" asset). Popular RSI strategies include buying when the RSI is crossing its lows from bottom to
            top and selling when crossing its highs from top to bottom (a.k.a. reversing). Or you can combine it with
            SMA, Stochastic and other instruments.
          </>
        ),
      },
      {
        image: rMacd,
        alt: "EasyCryptoBot - MACD strategy and results",
        text: (
          <>
            The <span className="text-info">Moving Average Convergence Divergence (MACD)</span> has a MACD line and a
            signal line. The MACD line is the result of subtracting a slow moving EMA from a fast moving EMA. The Signal
            line is an EMA of the MACD line. You can set the periods for all EMAs in the settings of the indicator. The
            indicator points whether the bullish or bearish movement is strengthening or weakening. A simple strategy
            with MACD is to buy when the MACD crosses above the signal line and to sell when the MACD crosses below the
            signal line. For better signals MACD you can combine it with SMAs or RSI.
          </>
        ),
      },
      {
        image: rBolb,
        alt: "EasyCryptoBot - Bollinger Bands strategy and results",
        text: (
          <>
            <span className="text-info">Bollinger Bands</span> use standard deviation levels of SMA. They help in
            determining whether the price is moving in its highs or lows. BB work very well in combinations with other
            instruments.
            <br />
            The BB Squeeze occurs when volatility is low and the bands narrow. A squeeze is ofthen followed by high
            volatility and therefore big moves in either direction. Once the bands are in a squeeze, a subsequent band
            break is the start of a new move.
          </>
        ),
      },
      {
        image: rKelt,
        alt: "EasyCryptoBot - Keltner channel strategy and results",
        text: (
          <>
            <span className="text-info">Keltner channel</span> uses an average true range of the price and EMA. The
            "multiplier" sets how many times the ATR will be added to/subtracted from the EMA to form the upper and
            lower band of the channel.
            <br />
            The channel is in a squeeze when volatility is low and the channel width is small.
          </>
        ),
      },
      {
        image: rStoch,
        alt: "EasyCryptoBot - Stochastic oscillator strategy and results",
        text: (
          <>
            The <span className="text-info">Stochastic oscillator</span> is a momentum indicator that ranges between 0
            and 100. The 0 corresponds to extreme lows of the price ("oversold"asset) and 100 to extreme highs of the
            price ("overbought" asset). The indicator consists of two lines: one reflecting the actual value of the
            oscillator calculated for the price, and one reflecting its simple moving average. 
            <br />
            The <span className="text-info">Stochastic RSI</span> is calculated with the RSI values instead of the
            price. This makes it more sensitive to price changes and gives it advantage in catching trend reversals.
          </>
        ),
      },
      {
        image: rMom,
        alt: "EasyCryptoBot - Momentum strategy and results",
        text: (
          <>
            The <span className="text-info">Momentum</span> determines the strength or weakness of the current price
            movement. It identifies when the price is moving upward or downward and the force of the movement. 
            <br />
            The indicator can be standard or smoothed.
          </>
        ),
      },
      {
        image: rMomSq,
        alt: "EasyCryptoBot - Momentum squeeze strategy and results",
        text: (
          <>
            The <span className="text-info">Momentum Squeeze</span> is a combination of Bollinger Bands, Keltner
            Channels and Momentum. During periods of low volatility, Bollinger Bands narrow and enter inside the Keltner
            Channel. Bollinger Bands breaking from this squeeze is a strong signal for big price movements.
            <br />
            The <span className="text-info">squeeze</span> is displayed as black dots on the histogram zero level. When
            there is no squeeze the dot is empty (white).
          </>
        ),
      },
    ],
  },
  {
    main: "BotTrading",
    title: "Groups panel",
    path: "/manual/groups",
    pageTitle: "EasyCryptoBot - How to Organize Your Strategies",
    metaContent:
      "Organize your strategies in groups to easily manage them. One click and you are running a backtest, optimization, simulation or live trading. Learn how now!",
    content: [
      {
        image: sGr,
        alt: "EasyCryptoBot - crypto trading bot - strategies groups",
        text: (
          <>
            <span className="text-info">Groups</span> let you organize your strategies so you can easily manage them.
            You can create your own groups or use the default ones.
          </>
        ),
      },
      {
        image: sGrAdd,
        alt: "EasyCryptoBot - crypto trading bot - add strategy to group",
        text: (
          <>
            Type in the <span className="text-info">Add to group +</span> search menu to search for and add strategies
            to your group. You can select multiple strategies and add them to a new group
            <FontAwesomeIcon icon={faFolderPlus} className="text-info mx-1" />
            or remove
            <FontAwesomeIcon icon={faFolderMinus} className="text-info mx-1" />
            them from the current one. Be careful - the red
            <FontAwesomeIcon icon={faTimesCircle} className="text-danger mx-1" />
            will delete the selected strategies. The
            <FontAwesomeIcon icon={faClone} className="text-info mx-1" />
            will duplicate the strategy and will add the new strategy to the current group.
          </>
        ),
      },
      {
        image: sGrSettings,
        alt: "EasyCryptoBot - crypto trading bot - group settings",
        text: (
          <>
            You can sort the table by column or you can search for strategies by name, pair, indicator or timeframe.
            <br />
            Edit or delete your group via the group settings menu.
          </>
        ),
      },
    ],
  },
  {
    main: "BotTrading",
    title: "Actions panel",
    path: "/manual/actions",
    pageTitle: "EasyCryptoBot - What to Do with Your Strategy",
    metaContent:
      "Learn how to analyse your strategy's behaviour with backtesting, improve it with the optimization and run it in simulation or live trading.",
    content: [
      {
        image: actionPanel,
        alt: "EasyCryptoBot - crypto trading bot - actions with strategies",
        text: (
          <>
            <span className="text-info">Actions panel</span> gives you everything about a{" "}
            <span className="text-info">selected</span> strategy. Select a strategy by clicking on it in the group
            table.
            <br />
            <span className="text-info">Details</span> will show you the strategy. You can edit it via the
            <FontAwesomeIcon icon={faEdit} className="text-info mx-1" />. You can setup new{" "}
            <span className="text-info">backtest</span> and <span className="text-info">optimization</span> and check
            the results of the recently done ones. This is the place where you can start a new{" "}
            <span className="text-info">simulation</span> or <span className="text-info">live</span> trading.
          </>
        ),
      },
    ],
  },
  {
    main: "BotTrading",
    title: "Trading panel",
    path: "/manual/trading-panel",
    pageTitle: "EasyCryptoBot - How to Manage Running Strategies",
    metaContent: "Learn how to manage running strategies. Easy to set up, easy to manage!",
    content: [
      {
        image: tPanel,
        alt: "EasyCryptoBot - crypto trading bot - trading with strategies",
        text: (
          <>
            <span className="text-info">Trading panel</span> shows all your running strategies in one place. Simulations
            go under the <span className="text-info">Simulations</span> tab and Live trading strategies go under the{" "}
            <span className="text-info">Trade Live</span> tab. <span className="text-info">Selecting</span> a strategy
            in the trading panel also selects it in the
            <a href="/manual/groups" className="text-info underline mx-1">
              Groups panel
            </a>
            and places it in the
            <a href="/manual/actions" className="text-info underline ml-1">
              Actions panel
            </a>
            . The selected
            <a href="/manual/groups" className="text-info underline mx-1">
              group
            </a>
            is also applied to the trading panel.
          </>
        ),
        textAfterPic: (
          <>
            For a strategy to appear in the Trading panel, you need to start it from the
            <a href="/manual/actions" className="text-info underline mx-1">
              Actions panel
            </a>
            for the first time. After that you can control it from both panels.
            <br />
          </>
        ),
      },
      {
        image: tTable,
        alt: "EasyCryptoBot - crypto trading bot - trading with strategies",
        text: (
          <>
            In
            <span className="text nowrap border-bottom border-info px-2 mx-2">
              <FontAwesomeIcon icon={faTable} className="mr-1" />
              Table view
            </span>
            you can <span className="text-info">sort</span> the Trading table by strategy name, trading pair, result %,
            or status. You can also search by keyword in the name, pair, indicator or timeframe of the strategy. You can
            choose the columns of the table by clicking the
            <FontAwesomeIcon icon={faCog} className="text-info mx-1" />
            in the top left corner of the table.
          </>
        ),
        textAfterPic: (
          <>
            You can select many strategies at once and control them together or you can use the buttons on just one.
            <br />
            <FontAwesomeIcon icon={faPlay} className="text-green mr-2" />- starts the simulation or live trading
            <br />
            <FontAwesomeIcon icon={faStop} className="text-warning mr-2" />- stops the simulation or live trading
            <br />
            <FontAwesomeIcon icon={faUndo} className="text-info mr-2" />- removes the trades of the simulation or live
            trading
            <br />
            <FontAwesomeIcon icon={faTimesCircle} className="text-danger mr-2" />
            - removes the simulation or live trading
            <br />
            <FontAwesomeIcon icon={faVolumeMute} className="text-danger mr-2" />
            - the notification for open and close of trades is off. Click it to turn it on.
            <br />
            <FontAwesomeIcon icon={faVolumeUp} className="text-success mr-2" />- the notification for open and close of
            trades is on. Click it to turn it off.
            <br />
            <FontAwesomeIcon icon={faPlusCircle} className="text-green mr-2" />- manually open a trade with the set
            trading parameters
            <br />
            <FontAwesomeIcon icon={faBan} className="text-danger mr-2" />- manually close a trade
            <div className="my-2">
              When there is an open trade the current profit/loss of the open trade will be shown. The bot uses the BID
              prices for the calculation of the open trade profit/loss percentages. It also includes the commissions for
              both trades (Buy and Sell). The commission depends on your exchange settings (generally it will be between
              -0.15% and -0.20%). The profit/loss is updated every 10 seconds and not on every BID change. The actual
              result after close of the trade may differ from the last shown profit/loss of the open trade. This can be
              due to the liquidity/volatility character of the traded coin, lag in the update of the result of the open
              trade, and etc. <br />
              In <span className="text-info font-weight-bold">Live trading</span> if you need to close the open trade
              manually do it by clicking on the
              <FontAwesomeIcon icon={faBan} className="text-danger mx-1" />
              instead of selling the amount directly on the website of the exchange. Otherwise, later the bot may try to
              close the trade according to the sell options. If there is enough balance in your account, it will sell
              it. The <span className="text-info">manual</span> sell will be executed with a Market sell order.
            </div>
            <div>
              The <span className="text-info">Status</span> shows the current condition of every execution. An icon will
              appear for connection problems, problem with the execution or strategy condition in effect. A critical
              error in the execution will stop it from running. In case of other events the execution will resume
              automatically when the event is no longer in effect.
            </div>
            <div className="text-center my-3">
              <img
                className="w-custom"
                src={tStatus}
                alt="EasyCryptoBot - crypto trading bot - status of running strategy"
              />
            </div>
          </>
        ),
      },
    ],
  },
  {
    main: "BotTrading",
    title: "Charts panel",
    path: "/manual/charts-panel",
    pageTitle: "EasyCryptoBot - How to Manage Running Strategies - Chart view",
    metaContent: "Learn how to manage running strategies in chart view. Easy to set up, easy to manage!",
    content: [
      {
        image: tChartView,
        alt: "EasyCryptoBot - crypto trading bot - charts of trading strategies",
        text: (
          <>
            On the
            <span className="text nowrap border-bottom border-info px-2 mx-2">
              <FontAwesomeIcon icon={faChartArea} className="mr-1" />
              Charts
            </span>
            panel the running strategies in the selected group are shown via live charts. The indicators appear under
            the corresponding timeframe for the rule.
          </>
        ),
        textAfterPic: (
          <>
            <FontAwesomeIcon icon={faPlay} className="text-green mr-2" />- starts the simulation or live trading
            <br />
            <FontAwesomeIcon icon={faStop} className="text-warning mr-2" />- stops the simulation or live trading
            <br />
            <FontAwesomeIcon icon={faPlusCircle} className="text-green mr-2" />- manually open a trade with the set
            trading parameters
            <br />
            <FontAwesomeIcon icon={faBan} className="text-danger mr-2" />- manually close a trade
            <div className="my-2">
              When there is an open trade the current profit/loss of the open trade will be shown. The bot uses the BID
              prices for the calculation of the open trade profit/loss percentages. It also includes the commissions for
              both trades (Buy and Sell). The commission depends on your exchange settings (generally it will be between
              -0.15% and -0.20%). The profit/loss is updated every 10 seconds and not on every BID change. The actual
              result after close of the trade may differ from the last shown profit/loss of the open trade. This can be
              due to the liquidity/volatility character of the traded coin, lag in the update of the result of the open
              trade, and etc. <br />
              In <span className="text-info font-weight-bold">Live trading</span> if you need to close the open trade
              manually do it by clicking on the
              <FontAwesomeIcon icon={faBan} className="text-danger mx-1" />
              instead of selling the amount directly on the website of the exchange. Otherwise, later the bot may try to
              close the trade according to the sell options. If there is enough balance in your account, it will sell
              it. The <span className="text-info">manual</span> sell will be executed with a Market sell order.
            </div>
          </>
        ),
      },
    ],
  },
  {
    main: "BotTrading",
    title: "Backtest",
    path: "/manual/backtest",
    pageTitle: "EasyCryptoBot - How to Backtest Your Strategy",
    metaContent:
      "Check how your strategy would have performed if you had run it in the past! Get meaningful insights - performance, statistics, charts. Learn how now!",
    content: [
      {
        image: bt,
        alt: "EasyCryptoBot - crypto trading bot - backtest",
        text: (
          <>
            <div className="mb-2">
              <FontAwesomeIcon icon={faExclamationTriangle} className="text-info mr-2" />
              Backtest is a RAM-consuming operation. How much RAM it uses depends on the complexity of the strategy and
              the length of the period. The maximum period for backtests depends on your subscription plan. "Unlimited
              periods" means that the maximum period for which you can run a backtest depends only on the capabilities
              of the computer running the application. We recommend that you use a computer with at least 8 GB of RAM
              for long-term backtests and complex strategies.
            </div>
            Backtest is a very important part of creating a strategy. It will allow you to see how your strategy behaves
            in different market conditions. You can perform backtests for different periods with a variety of market
            behavior. This will show you the strengths and weaknesses of your strategy, when to start it and when to
            hold it down.
          </>
        ),
        textAfterPic: (
          <>
            Backtest trades and live trading trades or simulation trades may not be exactly the same. Historical ASK/BID
            prices are not provided by the exchanges. So exact open and close prices cannot be reproduced. This may lead
            to a different open price in the backtest, which in turn can lead to different close prices and consequently
            different subsequent trades. However, in general, in the long run, backtest results should be very simillar
            to the real results of trading, performed in close market conditions to those of the backtested period.
          </>
        ),
      },
      {
        image: btRun,
        alt: "EasyCryptoBot - crypto trading bot - start backtest",
        text: (
          <>
            To run a backtest first
            <a href="/manual/actions" className="text-info underline mx-1">
              select the strategy
            </a>
            from the group table. On the
            <a href="/manual/actions" className="text-info underline mx-1">
              Actions
            </a>
            panel click on <span className="text-info">Backtest</span>. Enter your setup and start the backtest. The
            backtest will start under recent. When it is ready click it to see the results or directly go to the{" "}
            <span className="text-info">Results</span> section. The last 15 backtests of the strategy will appear under{" "}
            <span className="text-info">Recent</span>. If you edit the strategy recent backtests will be deleted.
            <br />
          </>
        ),
        textAfterPic: (
          <>
            The <span className="text-info">backtest setup</span> lets you choose:
            <ul>
              <li>
                <span className="text-info">time period</span> for which you want the coin to be backtested. Choose
                larger periods to capture diverse price moves or rerun the backtest on different small periods.
              </li>
              <li>
                <span className="text-info">trade size</span> is good to be set. During real trading it can be hard to
                get in and out of position with big trade size on illiquid coin. Big trade sizes on illiquid coins can
                move the price of the coin while they are being filled. This can turn profitable positions into losing
                ones. If you enter trade size, the backtest will account for it in the open and close price of the
                trades.
              </li>
              <li>
                <span className="text-info">commission rate</span> - you can adjust it according to the commission for
                your exchange account
              </li>
            </ul>
          </>
        ),
      },
      {
        image: btResult1,
        alt: "crypto trading strategy backtest - performance and statistical distribution of trades",
        text: (
          <>
            In the <span className="text-info">Backtest results</span> you can see the performance, check the trades on
            the chart and inspect their details.
          </>
        ),
      },
      {
        image: btResult2,
        alt: "crypto trading strategy backtest - trades chart, candle chart, trades list",
        text: (
          <>
            On the table with positive/ negative trades or in the Trades List click on a trade to see it on the candle
            chart. Trades list gives you details about all trades made by the strategy. Results include the set
            commission rate.
          </>
        ),
      },
      {
        image: tChartMultiTime,
        alt: "EasyCryptoBot - strategy rules and trading candle chart",
        text: (
          <>
            <div className="text-info mb-2">Strategies with two timeframes:</div>
            When your strategy has two different timeframes the candlestick chart will also have two timeframes. The
            indicators will be displayed on the timeframe corresponding to their rule timeframe.
            <br />
            Only one trade can happen during one candle on the smaller timeframe. One candle on the bigger timeframe can
            hold many trades.
          </>
        ),
      },
    ],
  },
  {
    main: "BotTrading",
    title: "Optimization",
    path: "/manual/optimization",
    pageTitle: "EasyCryptoBot - Optimization Is Key to Success",
    metaContent:
      "Let the bot do the job for you - let it find the most profitable variation of your strategy. Your idea - best performance! Learn how now",
    content: [
      {
        image: op,
        alt: "EasyCryptoBot - crypto trading bot - strategy optimization",
        text: (
          <>
          <div className="mb-2">
              <FontAwesomeIcon icon={faExclamationTriangle} className="text-info mr-2" />
              Optimization is a RAM-consuming operation. How much RAM it uses depends on the complexity of the strategy and
              the length of the period. The maximum period for which you can run an optimization depends only on the capabilities
              of the computer running the application. We recommend that you use a computer with at least 8 GB of RAM
              for long-term optimizations and complex strategies.
            </div>
            <span className="text-info">Optimization</span> will find the most effective variants of your strategy for
            the given past period. You can do many different optimizations, with different settings, for different
            periods with different market behavior. Choose your favourite variants and save them as separate strategies
            so you can easily switch between them when the market changes. The last 15 optimizations of a strategy will
            appear under the <span className="text-info">Recent</span> field so you can check them at any time and
            compare. Unlike
            <a href="/manual/backtest" className="text-info underline ml-1">
              recent backtests
            </a>
            , recent optimizations will be preserved if you edit or overwrite the strategy.
          </>
        ),
      },
      {
        image: opRun,
        alt: "EasyCryptoBot - crypto trading bot - strategy optimization setup",
        text: (
          <>
            To run an optimization first
            <a href="/manual/actions" className="text-info underline mx-1">
              select the strategy
            </a>
            from the group table. On the
            <a href="/manual/actions" className="text-info underline mx-1">
              Actions
            </a>
            panel click on <span className="text-info">Optimization</span>. Enter your setup and start the optimization.
            The optimization will start under recent. When it is ready click it see the results or directly go to the{" "}
            <span className="text-info">Results</span> section.
          </>
        ),
        textAfterPic: (
          <>
            The <span className="text-info">optimization setup</span> lets you choose:
            <ul>
              <li>
                <span className="text-info">time period</span> for which you want the strategy to be optimized. The more
                diverse are the moves of the coin in that period, the more robust your strategy will be to market
                changes. However, it may not be the best strategy for strong up or down moves. We recommend reruning the
                optimization periodically to see if your strategy needs to be adjusted or to save different variants of
                the strategy and run them according to changes in market conditions.
              </li>
              <li>
                <span className="text-info">trade size</span> is good to be included depending on your preferred coin
                and preferred size for trading. During real trading it can be hard to get in and out of position with
                big trade size on illiquid coin. Big trade sizes on illiquid coins can move the price of the coin while
                they are being filled. This can turn profitable positions into losing ones. If you enter trade size the
                optimization will account for it and will select the best strategies for your preferred trade size.
              </li>
              <li>
                <span className="text-info">commission rate</span> - you can adjust it according to the commission for
                your exchange account
              </li>
              <li>
                <span className="text-info">rules and options</span> of the strategy to optimize - what to be changed
                and what not. The Advanced options lets you select which rules exactly you want to be optimized.
              </li>
            </ul>
          </>
        ),
      },
      {
        image: opResult,
        alt: "EasyCryptoBot - crypto trading bot - strategy optimization result",
        text: (
          <>
            The optimization result includes 3 strategies in 3 different fields:
            <br />
            <span className="text-info">Consistent return </span>- stable results in a consistent pattern – in 95% of
            the time the strategy has a chance to return a similar result.
            <br />
            <span className="text-info">Risk/Reward</span> – result has the best return for the lowest risk.
            <br />
            <span className="text-info">Max total return</span> – result has the biggest total return including trades
            with extreme low or high results.
          </>
        ),
        textAfterPic: (
          <>
            <span className="btn btn-sm btn-info text-nowrap py-0 mr-1">
              Strategy
              <FontAwesomeIcon icon={faCaretDown} className="ml-1" />
            </span>
            - shows the variant of the strategy.
            <br />
            <span className="btn btn-sm btn-info text-nowrap py-0 mr-1">
              Chart
              <FontAwesomeIcon icon={faCaretDown} className="ml-1" />
            </span>
            - shows the candle chart with the trades for the variant of the strategy.
            <br />
            <span className="btn btn-sm btn-info text-nowrap py-0 mr-1">
              <FontAwesomeIcon icon={faSave} className="mr-1" />
              Save
            </span>
            - will open a save window. You can choose to overwrite the existing strategy or save it as a new one.
            <div className="text-center my-3">
              <img className="w-custom" src={opSave} alt="EasyCryptoBot - Save optimized strategy" />
            </div>
          </>
        ),
      },
    ],
  },
  {
    main: "BotTrading",
    title: "Simulation",
    path: "/manual/simulation",
    pageTitle: "EasyCryptoBot - How to Run a Simulation",
    metaContent:
      "Learn how to run a Simulation and see your strategy performing in live market without risk of losing money.",
    content: [
      {
        image: sim,
        alt: "EasyCryptoBot - crypto trading bot - strategy simulation",
        text: (
          <>
            <div className="mb-2">
              <FontAwesomeIcon icon={faExclamationTriangle} className="text-info mr-2" />
              The number of simultaneously running simulations depends on your subscription plan. "Unlimited"
              simulations means that the maximum number depends only on the capabilities of the computer running the
              application. We don't recommend running more than 100 at a time unless the computer can handle more than
              these.
            </div>
            <div className="mb-2">
              Before commiting to a strategy with real money, it's a good idea to see how it performs in real life
              without any risk. <span className="text-info">Simulation</span> is the ideal tool for the job. You can
              analyze performance, manually open and close trades and get used to the user interface before you start
              Live trading.
            </div>
            <div>
              Simulation results do not correspond 100% to the Live trading results. This is because the simulation does
              not place a real trade on the exchange. The bot uses BID prices and their volumes to simulate open and
              close of trades. However, the final open/close price can be determined only after a real completion of the
              order on the exchange. This is because of an unexpected market orders of other traders, “iceberg” orders,
              and etc.
              <br />
              Simulation can give you a perspective on the general performance of your strategy, if something needs to
              be adjusted. You can duplicate your strategy and have multiple Simulations with different position sizes.
              This can lead you to the best trading size that suits your needs and does not move the market.
            </div>
          </>
        ),
      },
      {
        image: simSetup,
        alt: "EasyCryptoBot - crypto trading bot - strategy simulation setup",
        text: (
          <>
            To run a simulation first
            <a href="/manual/actions" className="text-info underline mx-1">
              select the strategy
            </a>
            from the group table. On the
            <a href="/manual/actions" className="text-info underline mx-1">
              Actions panel
            </a>
            click on <span className="text-info">Simulation</span>. Enter your setup and start the simulation.
          </>
        ),
        textAfterPic: (
          <>
            <span className="text-info">Simulation setup:</span>
            <ul>
              <li>
                <span className="text-info">trade size</span> - can be in the Base or in Quote. The Base coin is the
                coin on the left side of the coin pair. The Quote coin is the coin on the right side of the coin pair.
                For example, BTCUSDT - base is BTC, quote is USDT.
              </li>
              <li>
                <span className="text-info">commission rate</span> - you can adjust it according to the commission for
                your exchange account
              </li>
              <li>
                <span className="text-info">additional stop options</span> - drawdown, total loss and negative trades in
                a row. They do not stop the current trade with a sell, but stop the whole simulation. The bot evaluates
                them after every close of a trade.
                <ul>
                  <li>
                    <span className="text-info">Drawdown</span> stops the simulation if the total loss of one or more
                    than one consecutively closed trades exceeds the set percentage.
                  </li>
                  <li>
                    <span className="text-info">Total loss</span> stops the simulation if the total loss from all trades
                    exceeds the set percentage.
                  </li>
                  <li>
                    <span className="text-info">Negative trades in a row</span> - the simulation will stop after the set
                    number of consecutive negative trades.
                  </li>
                </ul>
              </li>
            </ul>
          </>
        ),
      },
      {
        image: tOrdersSim,
        alt: "crypto trading bot - candle chart with trade that would have moved the price of the coin",
        text: (
          <>
            <span className="text-info">Orders type</span> is not available in Simulation. In simulation when rules are
            fulfilled the bot simulates <span className="text-info">Market</span> buy/sell order. It calculates the
            average price of the open/close according to the current real BID prices and the volumes under them. As real
            buy/sell is not actually placed on the exchange, the open/close on the chart may be outside the
            candlesticks. That means that the same trade in real trading at that moment would have moved the price of
            the coin pair.
          </>
        ),
      },
      {
        image: simControl,
        alt: "EasyCryptoBot - crypto trading bot - strategy simulation controls",
        text: (
          <>
            After you start a simulation, it will appear in the
            <a href="/manual/trading" className="text-info underline mx-1">
              Trading panel
            </a>
            under <span className="text-info">Simulations</span>. You can control the simulation from there or from the
            <a href="/manual/actions" className="text-info underline mx-1">
              Actions panel
            </a>
            as well.
          </>
        ),
        textAfterPic: (
          <>
            <FontAwesomeIcon icon={faPlay} className="text-green mr-2" />- starts the simulation
            <br />
            <FontAwesomeIcon icon={faStop} className="text-warning mr-2" />- stops the simulation
            <br />
            <FontAwesomeIcon icon={faUndo} className="text-info mr-2" />- removes the trades of the simulation
            <br />
            <FontAwesomeIcon icon={faTimesCircle} className="text-danger mr-2" />
            - removes the simulation <br />
            <FontAwesomeIcon icon={faPlusCircle} className="text-green mr-2" />- manually open a trade with the set
            trading parameters
            <br />
            <FontAwesomeIcon icon={faBan} className="text-danger mr-2" />- manually close a trade
            <br />
            When there is an open trade the current profit/loss of the open trade will be shown. The bot uses the BID
            prices for the calculation of the open trade profit/loss percentages. It also includes the commissions for
            both trades (Buy and Sell). The commission depends on your exchange settings (generally it will be between
            -0.15% and -0.20%). The profit/loss is updated every 10 seconds and not on every BID change. The actual
            result after close of the trade may differ from the last shown profit/loss of the open trade. This can be
            due to the liquidity/volatility character of the traded coin, lag in the update of the result of the open
            trade, and etc.
          </>
        ),
      },
      {
        image: simResult,
        alt: "EasyCryptoBot - crypto trading bot - strategy simulation result",
        text: (
          <>
            Simulation results can be seen in the <span className="text-info">Results</span> section of the
            <a href="/manual/actions" className="text-info underline mx-1">
              Actions panel
            </a>
            or by clicking the
            <FontAwesomeIcon icon={faChartPie} className="text-info mx-1" />
            in the
            <a href="/manual/trading" className="text-info underline mx-1">
              Trading panel
            </a>
            table.
          </>
        ),
      },
    ],
  },
  {
    main: "BotTrading",
    title: "Trade Live",
    path: "/manual/bot-live-trading",
    pageTitle: "EasyCryptoBot - How to Trade Live with the Bot",
    metaContent:
      "Ready for real trading? Learn how to make real percentages with your strategies. Easy to set up, easy to manage.",
    content: [
      {
        image: live,
        alt: "EasyCryptoBot - crypto trading bot - live trading",
        text: (
          <>
            <div className="mb-2">
              <FontAwesomeIcon icon={faExclamationTriangle} className="text-info mr-2" />
              The number of simultaneously running live trading strategies depends on your subscription plan.
              "Unlimited" live trading strategies means that the maximum number depends only on the capabilities of the
              computer running the application. We don't recommend running more than 100 at a time unless the computer
              can handle more than these.
            </div>
            <span className="text-info">Live trading</span> is the ultimate goal of your strategies, what you created
            them for - to make you money. After diligently improving your strategy by optimizing, backtesting it and
            letting it run in Simulation, now is the time to let it "live".
          </>
        ),
      },
      {
        image: liveSetup,
        alt: "EasyCryptoBot - crypto trading bot - strategy live trading setup",
        text: (
          <>
            To run Live trading first
            <a href="/manual/actions" className="text-info underline mx-1">
              select the strategy
            </a>
            from the group table. On the
            <a href="/manual/actions" className="text-info underline mx-1">
              Actions panel
            </a>
            click on <span className="text-info">Trade live</span>. Enter your setup and start the trading.
          </>
        ),
        textAfterPic: (
          <>
            <span className="text-info">Live trading setup:</span>
            <ul>
              <li>
                <span className="text-info">trading size</span> - can be in the Base, in Quote or in Percent.
                <ul>
                  <li>The Base coin is the coin on the left side of the coin pair.</li>
                  <li>
                    The Quote coin is the coin on the right side of the coin pair. For example, BTCUSDT - base is BTC,
                    quote is USDT.{" "}
                  </li>
                  <li>
                    Percents sets the size as a percentage of the amount of the quote coin of the strategy pair in your
                    account. If trading size percent for the same quote coin is set on more than one strategy, the
                    sequence of their orders does not affect their actual trading size. For example, let's say that
                    strategy A is set to trade with 10% of the available 100 ETH in the account. Strategy B is set with
                    15% of the 100 ETH. Strategy A will set buy orders with 10 ETH and B will set buy orders with 15
                    ETH. It does not matter if A places its order before B or the other way around. After close of a
                    trade the win/loss of ETHs will trigger recalculation of the trade sizes for the next buy orders for
                    both strategies.
                    <br />
                  </li>
                </ul>
                <div className="my-1">
                  Bear in mind that it can be hard to get in and out of position with a big trade size on illiquid coin.
                  Big trade sizes on illiquid coins can move the price of the coin while they are being filled. This can
                  turn profitable positions into losing ones.
                </div>
                If you run many strategies with the same quote coin the sum of all trade sizes can exceed 100%. In that
                situation at some point of the trading one or more strategies may lack the needed amount for trading. In
                such case the strategy(ies) will continue to run. It/They will try to buy again after short amount of
                time if the rules are still fulfilled.
              </li>
              <li>
                <span className="text-info">orders type:</span>
                <ul>
                  <li>
                    <span className="text-info">market</span> – all orders will be Market orders. The filling of the
                    Market order can move the price of the trading pair if there is not enough trading volume and your
                    trading size is too big. The order will continue to fulfil on portions during the rising of the
                    price until it is complete. The final price will be the average of all mini portions. This is not
                    bot dependant, but the way exchanges operate.
                  </li>
                  <li>
                    <span className="text-info">limit</span> - the bot will place Limit orders for the opening Buy,
                    Target sell and Trailing target sell. Stoploss and time stop are always executed as Market orders.
                    The price that triggers the buy becomes the price for the Limit order. On the exchange the buying
                    will happen only on this price. If the price moves, the completion of the order will stop. This is
                    not bot dependant, but the way exchanges operate. To give some space so the full or close to full
                    size of the order can fill, you can set <span className="text-info">orders price tolerance</span>.
                    This is a price range up to which the buy/sell can continue to complete. If the open (buy) position
                    is partial, the closing (sell) will also be with the same amount. After 1 minute if a Buy order is
                    not even partially filled, the bot will cancel the order.
                  </li>
                </ul>
                Both types of orders may generate <span className="text-info">leftovers</span> (amount under the minimum
                requirement of the exchange for a valid trade). Leftovers are not managed by the bot and you have to
                trade them manually.
              </li>
              <li>
                <span className="text-info">additional stop options</span> - drawdown, total loss and negative trades in
                a row. They do not stop the current trade with a sell, but stop the whole live trading of the strategy.
                The bot evaluates them after every close of a trade.
                <ul>
                  <li>
                    <span className="text-info">Drawdown</span> stops the live trading if the total loss of one or more
                    than one consecutively closed trades exceeds the set percentage.
                  </li>
                  <li>
                    <span className="text-info">Total loss</span> stops the live trading if the total loss from all
                    trades exceeds the set percentage.
                  </li>
                  <li>
                    <span className="text-info">Negative trades in a row</span> - the live trading will stop after the
                    set number of consecutive negative trades.
                  </li>
                </ul>
              </li>
            </ul>
          </>
        ),
      },
      {
        image: liveControl,
        alt: "EasyCryptoBot - crypto trading bot - strategy simulation controls",
        text: (
          <>
            After you start live trading with a strategy, the strategy will appear in the
            <a href="/manual/trading" className="text-info underline mx-1">
              Trading panel
            </a>
            under <span className="text-info">Trade Live</span>. You can control the trading from there or from the
            <a href="/manual/actions" className="text-info underline mx-1">
              Actions panel
            </a>
            as well.
          </>
        ),
        textAfterPic: (
          <>
            <FontAwesomeIcon icon={faPlay} className="text-green mr-2" />- starts the trading
            <br />
            <FontAwesomeIcon icon={faStop} className="text-warning mr-2" />- stops the trading
            <br />
            <FontAwesomeIcon icon={faUndo} className="text-info mr-2" />- removes the trades of the trading, but does
            not close an open trade. Close it manually via the
            <FontAwesomeIcon icon={faBan} className="text-danger mx-2" />
            before that.
            <br />
            <FontAwesomeIcon icon={faTimesCircle} className="text-danger mr-2" />
            - removes the trading, but does not close an open trade. Close it manually via the
            <FontAwesomeIcon icon={faBan} className="text-danger mx-2" />
            before that.
            <br />
            <FontAwesomeIcon icon={faPlusCircle} className="text-green mr-2" />- manually open a trade with the set
            trading parameters. You will be able to choose the order type - Market or Limit.
            <br />
            <FontAwesomeIcon icon={faBan} className="text-danger mr-2" />- manually close a trade
            <br />
            When there is an open trade the current profit/loss of the open trade will be shown. The bot uses the BID
            prices for the calculation of the open trade profit/loss percentages. It also includes the commissions for
            both trades (Buy and Sell). The commission depends on your exchange settings (generally it will be between
            -0.15% and -0.20%). The profit/loss is updated every 10 seconds and not on every BID change. The actual
            result after close of the trade may differ from the last shown profit/loss of the open trade. This can be
            due to the liquidity/volatility character of the traded coin, lag in the update of the result of the open
            trade, and etc. If you need to close the open trade manually do it by clicking on the
            <FontAwesomeIcon icon={faBan} className="text-danger mx-1" />
            instead of selling the amount directly on the website of the exchange. Otherwise, later the bot may try to
            close the trade according to the sell options. If there is enough balance in your account, it will sell it.
            The <span className="text-info">manual</span> sell will be executed with a Market sell order.
          </>
        ),
      },
      {
        image: liveResult,
        alt: "EasyCryptoBot - crypto trading bot - strategy simulation result",
        text: (
          <>
            Live trading results can be seen in the <span className="text-info">Results</span> section of the
            <a href="/manual/actions" className="text-info underline mx-1">
              Actions panel
            </a>
            or by clicking the
            <FontAwesomeIcon icon={faChartPie} className="text-info mx-1" />
            in the
            <a href="/manual/trading" className="text-info underline mx-1">
              Trading panel
            </a>
            table.
          </>
        ),
      },
    ],
  },
  {
    title: "Videos",
    path: "/manual/videos",
    pageTitle: "Easycryptobot - How to Use the Bot (all in 1 Min Videos)",
    metaContent:
      "Check out our videos on how to create a strategy, backtest, optimize and trade with EasyCryptoBot. Fast and efficiently!",
  },
  {
    title: "FAQs",
    path: "/manual/questions",
    pageTitle: "EasyCryptoBot - Frequently Asked Questions",
    metaContent:
      "Find answers to your questions. Do not hesitate to contact us with additional ones. We will be happy to work with you on your trading ideas.",
  },
];

const SubNav = (props) => {
  const [clickedMain, setClickedMain] = useState("");
  useEffect(() => {
    let route = props.routes.find((el) => el.path === window.location.pathname);
    setClickedMain(route.main ? route.main : "");
  }, []);
  return (
    <ul className={`nav nav-pills flex-column user-select-none ${props.classes}`} id={props.id}>
      {props.routes.map((route) => {
        return route.mainForSub ? (
          <li className="nav-item" key={route.id}>
            <a
              id={route.id}
              className={`nav-link ${props.classLink}`}
              href="/manual"
              onClick={(e) => {
                e.preventDefault();
                document.activeElement.blur();
                setClickedMain(clickedMain !== route.title ? route.title : "");
              }}
            >
              {route.title}
              <FontAwesomeIcon icon={clickedMain === route.title ? faCaretUp : faCaretDown} className="ml-1 ml-md-3" />
            </a>
          </li>
        ) : (
          <li
            className={`nav-item ${route.main && clickedMain !== route.main && props.noSearch ? "d-none" : "d-block"}`}
            key={route.path}
          >
            <NavLink
              className={`nav-link ${route.main || !props.noSearch ? "text-secondary" : props.classLink}`}
              as={NavLink}
              to={route.path}
              exact
              activeClassName="active"
              onClick={(e) => {
                document.activeElement.blur();
                window.scrollTo(0, 0);
                document.getElementById("docContent").scrollTop = 0;

                setClickedMain(route.main ? route.main : "");
              }}
            >
              {route.main && props.noSearch ? `- ${route.title}` : route.title}
            </NavLink>
          </li>
        );
      })}
    </ul>
  );
};

const TextContent = (props) => {
  let content = allRoutes.find((el) => el.title === props.title);
  return (
    <>
      <h2 className="text-info mt-3 mt-md-2">{content.main ? content.main : content.title}</h2>
      {content.main && <h3 className="h4 my-3">{content.title}</h3>}
      <div className="d-block">
        {content.content.map((el, index) => {
          if (props.search.length > 0) {
            if (
              ReactDOMServer.renderToString(el.text).toLowerCase().indexOf(props.search.toLowerCase()) > -1 ||
              ReactDOMServer.renderToString(el.textAfterPic).toLowerCase().indexOf(props.search.toLowerCase()) > -1
            ) {
              return (
                <div key={index}>
                  <div className="my-3 text-left">{el.text}</div>
                  <div className="my-3 text-center">
                    {el.imageMob ? (
                      <>
                        <img className="w-custom d-block d-sm-none" src={el.imageMob} alt={el.alt} />
                        <img className="w-custom d-none d-sm-block" src={el.image} alt={el.alt} />
                      </>
                    ) : (
                      <img className="w-custom" src={el.image} alt={el.alt} />
                    )}
                  </div>
                  {el.textAfterPic && <div className="text-left my-3">{el.textAfterPic}</div>}
                </div>
              );
            } else {
              return "";
            }
          } else {
            return (
              <div key={index}>
                <div className="my-3 text-left">{el.text}</div>
                <div className="my-3 text-center">
                  {el.imageMob ? (
                    <>
                      <img className="w-custom d-block d-sm-none" src={el.imageMob} alt={el.alt} />
                      <img className="w-custom d-none d-sm-block" src={el.image} alt={el.alt} />
                    </>
                  ) : (
                    <img className="w-custom" src={el.image} alt={el.alt} />
                  )}
                </div>
                {el.textAfterPic && <div className="text-left my-3">{el.textAfterPic}</div>}
              </div>
            );
          }
        })}
      </div>
    </>
  );
};

const Manual = (props) => {
  const [search, setSearch] = useState("");
  const [routes, setRoutes] = useState(allRoutes);

  useEffect(() => {
    if (search.length > 0) {
      let positiveSearchContent = allRoutes.filter((el) => {
        if (el.content) {
          return el.content.find(
            (item) =>
              ReactDOMServer.renderToString(item.text).toLowerCase().indexOf(search.toLowerCase()) > -1 ||
              ReactDOMServer.renderToString(item.textAfterPic).toLowerCase().indexOf(search.toLowerCase()) > -1
          );
        } else {
          return false;
        }
      });
      if (!isVisibleById("docNav")) {
        let docNav = document.getElementById("docNav");
        docNav.style.display = "block";
        let docNavBars = document.getElementById("docNavBars");
        docNavBars.classList.add("change");
      }
      setRoutes(positiveSearchContent);
    } else {
      if (isVisibleById("docNav")) {
        let docNav = document.getElementById("docNav");
        docNav.style.display = "none";
        let docNavBars = document.getElementById("docNavBars");
        docNavBars.classList.remove("change");
      }
      setRoutes(allRoutes);
    }
  }, [search]);

  useEffect(() => {
    const onBodyClick = (e) => {
      const ids = ["docNavBtn", "docNavBars", "manualBotTradingBtn", "manualTradingBtn"];
      if (isVisibleById("docNav")) {
        if (
          ids.indexOf(e.target.id) !== -1 ||
          ids.indexOf(e.target.parentElement.id) !== -1 ||
          ids.indexOf(e.target.parentElement.parentElement.id) !== -1
        ) {
          return;
        }
        let docNav = document.getElementById("docNav");
        docNav.style.display = "none";
        let bars = document.getElementById("docNavBars");
        bars.classList.remove("change");
      }
    };
    document.body.addEventListener("click", onBodyClick);
    return () => {
      document.body.removeEventListener("click", onBodyClick);
    };
  }, []);
  return (
    <>
      <div className="page-alt bg-white text-dark px-1 pl-md-4 pr-md-1 pb-4">
        <div className="d-block d-md-none pt-2 mb-2">
          <h1 className="major strech mt-md-4 pb-2 pb-md-4">
            <FontAwesomeIcon icon={faBookOpen} /> Manual
          </h1>
        </div>

        <div className="d-md-flex">
          <div className="position-relative text-nowrap border-right-md px-0 mb-2 mt-3">
            <div className="d-flex text-left pl-3 pl-md-0">
              <FontAwesomeIcon className="text-info mt-2" icon={faSearch} />
              <div className="position-relative flex-grow-1 pl-2 mb-2" id="searchDm">
                <input
                  type="text"
                  className="btn-bottom-outline-info w-100-p h-33"
                  id="docSearch"
                  value={search}
                  placeholder="Search"
                  onChange={(e) => setSearch(e.target.value)}
                />
                {search.length > 0 && (
                  <a
                    className="position-absolute top-right-custom"
                    href="/#"
                    title="Clear search"
                    id="docSearchBtn"
                    onClick={(e) => {
                      e.preventDefault();
                      document.activeElement.blur();
                      setSearch("");
                    }}
                  >
                    <FontAwesomeIcon className="text-danger small" icon={faTimes} />
                  </a>
                )}
              </div>
              <button
                className="navbar-toggler sub-menu align-bottom"
                type="button"
                id="docNavBtn"
                onClick={(e) => {
                  if (isVisibleById("docNav")) {
                    let docNav = document.getElementById("docNav");
                    docNav.style.display = "none";
                    let docNavBars = document.getElementById("docNavBars");
                    docNavBars.classList.remove("change");
                  } else {
                    let docNav = document.getElementById("docNav");
                    docNav.style.display = "block";
                    let docNavBars = document.getElementById("docNavBars");
                    docNavBars.classList.add("change");
                  }
                }}
              >
                <div id="docNavBars">
                  <div className="bar1"></div>
                  <div className="bar2"></div>
                  <div className="bar3"></div>
                </div>
              </button>
            </div>
            <div className="small-nav position-absolute overflow-auto white max-h-55-vh w-100 ">
              <SubNav
                classes="small-nav active-info-trans-bold bg-grad-white-info-2 px-3 pt-2 "
                classLink="text-secondary font-weight-bold"
                id="docNav"
                routes={routes}
                noSearch={search.length === 0}
              />
            </div>
            <SubNav
              classes={`big-nav text-left overflow-auto white w-200 h-nav mr-2 ${
                search.length ? "active-info-trans" : "active-info"
              }`}
              classLink="text-info font-weight-bold"
              routes={routes}
              noSearch={search.length === 0}
            />
          </div>
          <div className="flex-grow-1 overflow-auto white h-custom px-3 mb-2 mt-3" id="docContent">
            <Switch>
              {routes.map((route) => {
                if (route.mainForSub) {
                  return null;
                }
                return (
                  <Route key={route.path} path={route.path} exact>
                    <>
                      <Meta title={route.pageTitle} metaContent={route.metaContent} />
                      {route.title === "Videos" ? (
                        <>
                          <h2 className="text-info mt-2 mb-3">How to:</h2>
                          <div className="d-flex flex-wrap justify-content-center">
                            <Videos classesDiv="col min-w-280 max-w-450" />
                          </div>
                        </>
                      ) : route.title === "FAQs" ? (
                        <Questions classH2="text-info mt-md-2 my-3" />
                      ) : (
                        <TextContent title={route.title} search={search} />
                      )}
                    </>
                  </Route>
                );
              })}
              <Redirect to="/manual/how-to-get-started"></Redirect>
            </Switch>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Manual;
