import React, { useState, useContext, useEffect, useRef } from "react";
import { globalContext } from "./Context.js";
import { Dropdown } from "./Dropdown.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faMailBulk,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { showInfoDialog, fetchWithRetry, sleep } from "./utils.js";
import loading from "./assets/images/loading.gif";
import { RadioButtons } from "./RadioButtons.js";
import Footer from "./Footer.js";
import getEcbDatabase from "./EcbDatabase";

async function sendMessage(
  id,
  name,
  email,
  userToken,
  build,
  version,
  messageTitle,
  bugType,
  message,
  relatedStrategies,
  dispatch,
  state,
  setIsSending,
  resetState,
  componentIsMounted
) {
  const endpoint = "https://api2.easycryptobot.com/contact-form.php";
  let params = {
    name: name,
    email: email,
    version: version,
    build: build,
    title: messageTitle,
    message: message,
  };
  let report = [];
  if (messageTitle === "Bug report") {
    params.bugType = bugType;
    params.relatedStrategies = Object.values(relatedStrategies).join("\n");
    for (let strategyId in relatedStrategies) {
      let reported = {
        strategy: state.strategies.find((el) => el.id === strategyId),
      };
      if (!reported) {
        continue;
      }

      if (bugType === "Strategies") {
        let simStatus = state.simStatuses[strategyId];
        if (simStatus) {
          let simDetails = await getEcbDatabase().getSimStrategyDetails(
            strategyId
          );
          let simTrades = await getEcbDatabase().getSimStrategyTrades(
            strategyId
          );
          let simEvents = await getEcbDatabase().getSimStrategyEvents(
            strategyId
          );
          reported.simStatus = simStatus;
          reported.simDetails = simDetails;
          reported.simTrades = simTrades;
          reported.simEvents = simEvents;
        }
        let liveStatus = state.liveStatuses[strategyId];
        if (liveStatus) {
          let liveDetails = await getEcbDatabase().getLiveStrategyDetails(
            strategyId
          );
          let liveTrades = await getEcbDatabase().getLiveStrategyTrades(
            strategyId
          );
          let liveEvents = await getEcbDatabase().getLiveStrategyEvents(
            strategyId
          );
          reported.liveStatus = liveStatus;
          reported.simDetails = liveDetails;
          reported.simTrades = liveTrades;
          reported.simEvents = liveEvents;
        }
      }
      report.push(reported);
    }

    fetchWithRetry(
      "https://api2.easycryptobot.com/reported.php",
      "post",
      {
        report: JSON.stringify(report),
        reportType: bugType,
        message: message,
        build: build,
      },
      null,
      null,
      { Authorization: `Bearer ${userToken}` }
    );
  }

  try {
    await sleep(3000);
    await fetchWithRetry(
      endpoint,
      "post",
      params,
      () => {
        if (componentIsMounted.current) {
          setIsSending(false);
          resetState();
          showInfoDialog(
            dispatch,
            <span className="text-info">
              <FontAwesomeIcon icon={faEnvelope} /> Thank you for contacting us!
            </span>,
            <div>A reply will be sent to your email.</div>
          );
        }
      },
      () => {
        if (componentIsMounted.current) {
          setIsSending(false);
          showInfoDialog(
            dispatch,
            <span className="text-danger">
              <FontAwesomeIcon icon={faEnvelope} /> Message send failed!
            </span>,
            <div>
              Please try again or contact us directly at{" "}
              <span className="text-info">support@easycryptobot.com</span>
            </div>
          );
        }
      },
      { Authorization: `Bearer ${userToken}` }
    );
  } catch (e) {
    setIsSending(false);
  }
}

const Contact = (props) => {
  let { state, dispatch } = useContext(globalContext);
  const [messageTitle, setMessageTitle] = useState("Bug report");
  const bugTypes = ["General", "Strategies", "Exchanges", "Subscription"];
  const [selectAreaLabel] = useState("Select problem area");
  const [bugType, setBugType] = useState(selectAreaLabel);
  const [message, setMessage] = useState("");
  const [relatedStrategies, setRelatedStrategies] = useState({ default: "" });
  const [isSending, setIsSending] = useState(false);
  const componentIsMounted = useRef(true);
  const [filter, setFilter] = useState("");
  const [filteredStrategiesIds, setFilteredStrategiesIds] = useState([]);
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  const [itemsPerPage] = useState(11);
  const [pageCol2, setPageCol2] = useState(0);
  const [pagesCol2, setPagesCol2] = useState([]);
  const [startIndex2, setStartIndex2] = useState(0);
  const [endIndex2, setEndIndex2] = useState(0);
  const [itemsPerPageCol2] = useState(13);

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (bugType === "Strategies") {
      setFilteredStrategiesIds(state.strategies.map((el) => el.id));
    }
  }, [state.strategies, bugType]);

  useEffect(() => {
    let pages = [];
    for (let i = 0; i < filteredStrategiesIds.length / itemsPerPage; i++) {
      pages.push(i);
    }
    setPages(pages);
    setStartIndex(itemsPerPage * page);
    setEndIndex(itemsPerPage * page + itemsPerPage);
  }, [page, filteredStrategiesIds]);

  useEffect(() => {
    let pages = [];
    for (
      let i = 0;
      i < Object.keys(relatedStrategies).length / itemsPerPageCol2;
      i++
    ) {
      pages.push(i);
    }
    setPagesCol2(pages);
    setStartIndex2(itemsPerPageCol2 * pageCol2);
    setEndIndex2(itemsPerPageCol2 * pageCol2 + itemsPerPageCol2);
  }, [pageCol2, relatedStrategies]);

  const markRelatedStrategies = (id, name) => {
    if (!relatedStrategies[id]) {
      relatedStrategies[id] = name;
    } else {
      delete relatedStrategies[id];
    }
    setRelatedStrategies({ ...relatedStrategies });
  };

  return (
    <>
      <div className="page-contact bg-white text-dark rounded user-select-none pb-5">
        <div className="pt-1">
          <h1 className="major m-4 pb-4">
            <FontAwesomeIcon icon={faMailBulk} /> Contact Us
          </h1>
        </div>
        <div className="d-flex flex-wrap justify-content-start justify-content-md-between text-left w-contact">
          <RadioButtons
            options={[
              {
                optionClasses: "text-dark mb-3",
                checkedColor: "text-info",
                uncheckedColor: "text-white border rounded-circle border-info",
                checked: messageTitle === "Bug report",
                check: () => setMessageTitle("Bug report"),
                text: "Report a problem",
              },
              {
                optionClasses: "text-dark mb-3",
                checkedColor: "text-info",
                uncheckedColor: "text-white border rounded-circle border-info",
                checked: messageTitle === "Info request",
                check: () => {
                  setMessageTitle("Info request");
                  setBugType(selectAreaLabel);
                },
                text: "Ask for information",
              },
              {
                optionClasses: "text-dark mb-3",
                checkedColor: "text-info",
                uncheckedColor: "text-white border rounded-circle border-info",
                checked: messageTitle === "Idea",
                check: () => {
                  setMessageTitle("Idea");
                  setBugType(selectAreaLabel);
                },
                text: "Have an idea",
              },
              {
                optionClasses: "text-dark mb-3",
                checkedColor: "text-info",
                uncheckedColor: "text-white border rounded-circle border-info",
                checked: messageTitle === "Individual plan",
                check: () => {
                  setMessageTitle("Individual plan");
                  setBugType(selectAreaLabel);
                },
                text: "Individual plan",
              },
              {
                optionClasses: "text-dark mb-3",
                checkedColor: "text-info",
                uncheckedColor: "text-white border rounded-circle border-info",
                checked: messageTitle === "Cloud service",
                check: () => {
                  setMessageTitle("Cloud service");
                  setBugType(selectAreaLabel);
                },
                text: "Cloud service",
              },
            ]}
          />
        </div>
        <div className="tab-content w-contact text-left mt-3">
          {messageTitle === "Bug report" && (
            <div className="">
              <Dropdown
                btnClasses="btn btn-info min-w-280"
                arrow={true}
                arrowClasses="mt-1 ml-auto"
                menuClasses="bg-white border border-info rounded min-w-280"
                menuItemsClasses="text-dark hover-bg-info py-1"
                selected={bugType}
                setSelected={(item) => {
                  setBugType(item);
                  setRelatedStrategies({ default: "" });
                  setPage(0);
                  setPageCol2(0);
                }}
                items={bugTypes}
              />

              {bugType !== selectAreaLabel &&
                (bugType === "General" ||
                bugType === "Exchanges" ||
                bugType === "Subscription" ? (
                  <div className="mt-3">
                    <textarea
                      className="form-control"
                      placeholder="Tell us what went wrong"
                      rows="10"
                      value={message}
                      onChange={(e) => {
                        setMessage(e.target.value);
                      }}
                    ></textarea>
                  </div>
                ) : (
                  <div className="mt-3">
                    {state.strategies.length > 0 && (
                      <div>
                        {filteredStrategiesIds.length > 0 ? (
                          <div className="row row-cols-1 row-cols-sm-2 h-md-400">
                            <div className="col d-md-flex align-items-start flex-column">
                              <div className="text-secondary mr-2 mt-1">
                                Select related strategies, if applicable:
                              </div>
                              <div className="my-2 text-left">
                                <FontAwesomeIcon
                                  className="text-info mr-2"
                                  icon={faSearch}
                                />
                                <div className="d-inline-block input-group-sm">
                                  <input
                                    type="text"
                                    className="btn-bottom-outline-info"
                                    value={filter}
                                    placeholder="Search"
                                    onChange={(e) => {
                                      setFilter(e.target.value);
                                      setFilteredStrategiesIds(
                                        state.strategies
                                          .filter(
                                            (el) =>
                                              el.name
                                                .toLowerCase()
                                                .indexOf(
                                                  e.target.value.toLowerCase()
                                                ) !== -1
                                          )
                                          .map((el) => el.id)
                                      );
                                    }}
                                  />
                                </div>
                              </div>
                              {filteredStrategiesIds
                                .map((id) =>
                                  state.strategies.find(
                                    (strategy) => strategy.id === id
                                  )
                                )
                                .sort((a, b) => a.name.localeCompare(b.name))
                                .slice(startIndex, endIndex)
                                .map((strategy) => {
                                  return (
                                    <div
                                      className="custom-control custom-checkbox w-100 text-truncate"
                                      key={strategy.id}
                                    >
                                      <input
                                        type="checkbox"
                                        className="custom-control-input"
                                        id={`c${strategy.id}`}
                                        checked={
                                          relatedStrategies[strategy.id]
                                            ? relatedStrategies[strategy.id]
                                            : relatedStrategies.default
                                        }
                                        onChange={(e) => {
                                          markRelatedStrategies(
                                            strategy.id,
                                            strategy.name
                                          );
                                        }}
                                      />
                                      <label
                                        type="text"
                                        className="custom-control-label"
                                        htmlFor={`c${strategy.id}`}
                                      >
                                        {strategy.name}
                                      </label>
                                    </div>
                                  );
                                })}
                              {pages.length > 1 && (
                                <div className="overflow-auto my-2 mt-md-auto">
                                  <nav aria-label="page navigation">
                                    <ul className="pagination">
                                      <li className="page-item" key="prev">
                                        <a
                                          className="page-link small px-2 py-1"
                                          aria-label="Previous"
                                          href="#/"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            if (page > 0) {
                                              setPage(page - 1);
                                            }
                                          }}
                                        >
                                          <span aria-hidden="true">
                                            &laquo;
                                          </span>
                                          <span className="sr-only">
                                            Previous
                                          </span>
                                        </a>
                                      </li>
                                      {pages.map((el) => {
                                        return (
                                          <li
                                            className={
                                              "page-item " +
                                              (page === el ? "active" : "")
                                            }
                                            key={el}
                                          >
                                            <a
                                              className="page-link small px-2 py-1"
                                              href="#/"
                                              onClick={(e) => {
                                                e.preventDefault();
                                                setPage(el);
                                              }}
                                            >
                                              {el + 1}
                                            </a>
                                          </li>
                                        );
                                      })}

                                      <li className="page-item" key="next">
                                        <a
                                          className="page-link small px-2 py-1"
                                          href="#/"
                                          aria-label="Next"
                                          onClick={(e) => {
                                            e.preventDefault();
                                            if (
                                              page <
                                              Math.floor(
                                                filteredStrategiesIds.length /
                                                  itemsPerPage
                                              )
                                            ) {
                                              setPage(page + 1);
                                            }
                                          }}
                                        >
                                          <span aria-hidden="true">
                                            &raquo;
                                          </span>
                                          <span className="sr-only">Next</span>
                                        </a>
                                      </li>
                                    </ul>
                                  </nav>
                                </div>
                              )}
                            </div>
                            {Object.keys(relatedStrategies).length > 1 && (
                              <div className="col pl-md-5 align-items-start flex-column">
                                <div className="text-info mr-2">
                                  Selected strategies:
                                </div>
                                {state.strategies
                                  .filter((strategy) =>
                                    Object.keys(relatedStrategies).includes(
                                      strategy.id
                                    )
                                  )
                                  .sort((a, b) => a.name.localeCompare(b.name))
                                  .slice(startIndex2, endIndex2)
                                  .map((strategy) => {
                                    return (
                                      <div
                                        className="custom-control custom-checkbox w-100 text-truncate"
                                        key={strategy.id}
                                      >
                                        <input
                                          type="checkbox"
                                          className="custom-control-input"
                                          id={`c${strategy.id}`}
                                          checked={
                                            relatedStrategies[strategy.id]
                                              ? relatedStrategies[strategy.id]
                                              : relatedStrategies.default
                                          }
                                          onChange={(e) => {
                                            markRelatedStrategies(
                                              strategy.id,
                                              strategy.name
                                            );
                                          }}
                                        />
                                        <label
                                          type="text"
                                          className="custom-control-label"
                                          htmlFor={`c${strategy.id}`}
                                        >
                                          {strategy.name}
                                        </label>
                                      </div>
                                    );
                                  })}
                                {pagesCol2.length > 1 && (
                                  <div className="overflow-auto my-2 mt-auto">
                                    <nav aria-label="page navigation">
                                      <ul className="pagination">
                                        <li className="page-item" key="prev">
                                          <a
                                            className="page-link small px-2 py-1"
                                            aria-label="Previous"
                                            href="#/"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              if (pageCol2 > 0) {
                                                setPageCol2(pageCol2 - 1);
                                              }
                                            }}
                                          >
                                            <span aria-hidden="true">
                                              &laquo;
                                            </span>
                                            <span className="sr-only">
                                              Previous
                                            </span>
                                          </a>
                                        </li>
                                        {pagesCol2.map((el) => {
                                          return (
                                            <li
                                              className={
                                                "page-item " +
                                                (pageCol2 === el
                                                  ? "active"
                                                  : "")
                                              }
                                              key={el}
                                            >
                                              <a
                                                className="page-link small px-2 py-1"
                                                href="#/"
                                                onClick={(e) => {
                                                  e.preventDefault();
                                                  setPageCol2(el);
                                                }}
                                              >
                                                {el + 1}
                                              </a>
                                            </li>
                                          );
                                        })}

                                        <li className="page-item" key="next">
                                          <a
                                            className="page-link small px-2 py-1"
                                            href="#/"
                                            aria-label="Next"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              if (
                                                pageCol2 <
                                                Math.floor(
                                                  filteredStrategiesIds.length /
                                                    itemsPerPage
                                                )
                                              ) {
                                                setPageCol2(pageCol2 + 1);
                                              }
                                            }}
                                          >
                                            <span aria-hidden="true">
                                              &raquo;
                                            </span>
                                            <span className="sr-only">
                                              Next
                                            </span>
                                          </a>
                                        </li>
                                      </ul>
                                    </nav>
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        ) : (
                          <div>No strategies</div>
                        )}
                      </div>
                    )}
                    <div className="mt-3">
                      <textarea
                        className="form-control"
                        placeholder={`Tell us what went wrong.\n\nFor example:\nStrange backtest results\nI cannot save strategies\nBacktest/Optimization is not starting\nSimulation/Live trading is not starting\nand etc.`}
                        rows="10"
                        value={message}
                        onChange={(e) => {
                          setMessage(e.target.value);
                        }}
                      ></textarea>
                    </div>
                  </div>
                ))}
            </div>
          )}
          {messageTitle === "Info request" && (
            <div className="">
              <div className="input-group mt-3">
                <textarea
                  className="form-control"
                  placeholder="You need more information about..."
                  rows="10"
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                ></textarea>
              </div>
            </div>
          )}
          {messageTitle === "Idea" && (
            <div className="">
              <div className="input-group mt-3">
                <textarea
                  className="form-control"
                  placeholder="Tell us how ECB can be better..."
                  rows="10"
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                ></textarea>
              </div>
            </div>
          )}
          {(messageTitle === "Cloud service" ||
            messageTitle === "Individual plan") && (
            <div className="">
              <div className="input-group mt-3">
                <textarea
                  className="form-control"
                  placeholder="Tell us what you need for your trading..."
                  rows="10"
                  value={message}
                  onChange={(e) => {
                    setMessage(e.target.value);
                  }}
                ></textarea>
              </div>
            </div>
          )}
          {(bugType !== selectAreaLabel || messageTitle !== "Bug report") &&
            (isSending ? (
              <div className="mt-3 text-info">
                Sending..{" "}
                <div className="loading-white" title="Loading..">
                  <img src={loading} alt="" />
                </div>
              </div>
            ) : (
              <button
                id="sendBtn"
                className="btn btn-info btn-block mt-3 w-25"
                type="submit"
                disabled={message === "" ? true : false}
                onClick={(e) => {
                  e.preventDefault();
                  setIsSending(true);
                  sendMessage(
                    state.user.id,
                    state.user.name,
                    state.user.email,
                    state.user.token,
                    state.build,
                    state.version,
                    messageTitle,
                    bugType,
                    message,
                    relatedStrategies,
                    dispatch,
                    state,
                    setIsSending,
                    () => {
                      setBugType(selectAreaLabel);
                      setMessage("");
                      setRelatedStrategies({ default: "" });
                    },
                    componentIsMounted
                  );
                }}
              >
                Send
              </button>
            ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contact;
