import React, { useContext, useEffect, useState } from "react";
import ContestRankingTab from "./ContestRanking.js";
import ContestRules from "./ContestRules.js";
import Footer from "./Footer";
import { globalContext } from "./Context";
import { getContests } from "./utils";
import { Dropdown } from "./Dropdown.js";

const Contest = (props) => {
  let { state, dispatch } = useContext(globalContext);
  const [subPages] = useState(["Ranking", "Rules & Prizes"]);
  const [activeSubPage, setActiveSubPage] = useState(subPages[0]);
  const [month, setMonth] = useState();

  useEffect(() => {
    getContests(dispatch);
  }, []);

  useEffect(() => {
    if (state.contests.length > 0 && !state.selectedContest.month) {
      setMonth(state.contests[state.contests.length - 1].month);
    }
  }, [state.contests.length]);

  useEffect(() => {
    if (
      state.contests.length > 0 &&
      month &&
      month !== state.selectedContest.month
    ) {
      dispatch({
        type: "selectedContest",
        payload: month,
      });
    }
  }, [state.contests.length, month]);
  return (
    <>
      <div className="page-alt">
        {/* <CommingSoon title="Contest" /> */}

        <div className="">
          <div className="d-inline-flex active-border hover-white user-select-none mb-2">
            {subPages.map((subPage) => {
              return (
                <a
                  key={subPage}
                  className={`text-info p-2 mr-2 ${
                    activeSubPage === subPage ? "active" : ""
                  }`}
                  href="#/"
                  onClick={(e) => {
                    e.preventDefault();
                    document.activeElement.blur();
                    setActiveSubPage(subPage);
                  }}
                >
                  {subPage}
                </a>
              );
            })}
          </div>
        </div>

        <div className="">
          {activeSubPage === "Rules & Prizes" && <ContestRules />}
          {activeSubPage === "Ranking" && (
            <>
              <Dropdown
                btnClasses="d-flex text-light hover-border-bottom info pl-0 pb-2"
                arrow
                arrowClasses="mt-1"
                selectedClasses="text-truncate max-w-270 mr-1"
                menuClasses="bg-new-dark border border-secondary rounded text-left small max-h-fullscreen w-150 py-1"
                menuItemsClasses="text-light hover-info px-3 py-1"
                selected={month}
                setSelected={(month) => {
                  setMonth(month);
                }}
                items={state.contests.map((el) => el.month)}
              />
              <ContestRankingTab />
            </>
          )}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Contest;
