import React, { useState, useEffect, useRef, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBug,
  faChartArea,
  faLayerGroup,
  faLeaf,
  faRandom,
  faSearch,
  faSearchPlus,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import { globalContext } from "./Context.js";
import { formatDateTime, showConfirmDialog, fetchWithRetry } from "./utils.js";
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons";
import { InspectStrategyPopup } from "./Strategies.js";
import { ExecutionResults } from "./Trading.js";
import $ from "jquery";
import { NavLink } from "react-router-dom";

const ReportedBugs = (props) => {
  let { state, dispatch } = useContext(globalContext);
  const [strategyToInspect, setStrategyToInspect] = useState(null);
  const [strategyToShow, setStrategyToShow] = useState(null);
  const [status, setStatus] = useState(null);
  const [details, setDetails] = useState(null);
  const [trades, setTrades] = useState(null);
  const [events, setEvents] = useState(null);
  const [type, setType] = useState(null);
  const componentIsMounted = useRef(true);

  return (
    <div className="page-alt text-center px-3 px-md-5 pb-md-5">
      {!state.strategiesInitialized ? (
        <div className="text-center mx-auto mt-5 pt-5">
          <h2 className="text-secondary text-center mt-3 mb-4">No user to show</h2>
          <NavLink
            className="text-info mt-5"
            as={NavLink}
            to="/"
            exact
            activeClassName="active"
            onClick={() => {
              if ($("#infoDialog").is(":visible")) {
                $("#infoDialog").modal("toggle");
              }
            }}
          >
            SELECT NOW
          </NavLink>
        </div>
      ) : (
        <div className="pt-1">
          <h1 className="major h2 alt strech user-select-none py-4 ">
            <FontAwesomeIcon icon={faBug} /> Reports
          </h1>
          {strategyToInspect && (
            <InspectStrategyPopup
              setStrategyToInspect={(strategy) => {
                if (componentIsMounted.current) {
                  setStrategyToInspect(strategy);
                }
              }}
              strategy={strategyToInspect}
            />
          )}

          {strategyToShow && (
            <ExecutionResults
              strategy={strategyToShow}
              status={status}
              details={details}
              trades={trades}
              events={events}
              bugReport="bugReport"
              type={type}
              closeFn={() => {
                setStrategyToShow(null);
                setStatus(null);
                setDetails(null);
                setTrades(null);
                setEvents(null);
                setType(null);
              }}
            />
          )}
          {state.reportedBugs.length > 0 && (
            <div className="mb-5 mt-4 user-select-none">
              <div className="my-2 text-left">
                <FontAwesomeIcon className="text-info" icon={faSearch} />
                &nbsp;
                <div className="d-inline-block input-group-sm">
                  <input
                    type="text"
                    className="btn-bottom-outline-info"
                    value={""}
                    placeholder="Search"
                    onChange={(e) => {}}
                  ></input>
                </div>
              </div>

              <div className="table-responsive overflow-auto border border-dark rounded ">
                <table className="table table-sm table-new-dark rounded sortable-table small mb-0">
                  <thead>
                    <tr>
                      <th className="text-left text-nowrap">Creation Date</th>
                      <th className="text-left">Build</th>
                      <th className="text-left">Type</th>
                      <th className="text-left">Message</th>
                      <th className="text-left">Related</th>
                      <th className=""></th>
                    </tr>
                  </thead>
                  <tbody>
                    {state.reportedBugs.map((bug) => {
                      return (
                        <tr key={bug.id}>
                          <td className="text-left pl-3">{formatDateTime(new Date(bug.creationDate * 1000))}</td>
                          <td className="text-left">{bug.build}</td>
                          <td className="text-left">{bug.reportType}</td>
                          <td className="text-left d-none d-lg-table-cell">{bug.message}</td>
                          <td className="text-left d-none d-lg-table-cell">
                            <ul className="p-0 m-0">
                              {bug.report.map((report) => {
                                if (!report || !report.strategy) {
                                  return null;
                                }
                                return (
                                  <li className="" key={report.strategy.id}>
                                    {report.strategy.name}
                                    <a
                                      href="#/"
                                      className="text-info pl-2"
                                      title="Show Strategy"
                                      onClick={(e) => {
                                        e.preventDefault();
                                        document.activeElement.blur();
                                        setStrategyToInspect(report.strategy);
                                        console.log("Strategy", report.strategy);
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faSearchPlus} />
                                    </a>
                                    {report.simStatus && (
                                      <a
                                        href="#/"
                                        className="text-info pl-2"
                                        title="Show Execution"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          document.activeElement.blur();
                                          setStrategyToShow(report.strategy);
                                          setStatus(report.simStatus);
                                          setDetails(report.simDetails);
                                          setTrades(report.simTrades);
                                          setEvents(report.simEvents);
                                          setType("simulation");
                                          console.log("Strategy", report.strategy);
                                          console.log("SimStatus", report.simStatus);
                                          console.log("SimDetails", report.simDetails);
                                          console.log("SimTrades", report.simTrades);
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faRandom} />
                                      </a>
                                    )}
                                    {report.liveStatus && (
                                      <a
                                        href="#/"
                                        className="text-info pl-2"
                                        title="Show Execution"
                                        onClick={(e) => {
                                          e.preventDefault();
                                          document.activeElement.blur();
                                          setStrategyToShow(report.strategy);
                                          setStatus(report.liveStatus);
                                          setDetails(report.liveDetails);
                                          setTrades(report.liveTrades);
                                          setEvents(report.liveEvents);
                                          setType("trading");
                                          console.log("Strategy", report.strategy);
                                          console.log("SimStatus", report.liveStatus);
                                          console.log("SimDetails", report.liveDetails);
                                          console.log("SimTrades", report.liveTrades);
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faChartArea} />
                                      </a>
                                    )}
                                  </li>
                                );
                              })}
                            </ul>
                          </td>
                          <td className="text-right">
                            <a
                              href="#/"
                              className="text-danger align-middle mr-1"
                              title="Remove"
                              onClick={(e) => {
                                e.preventDefault();
                                document.activeElement.blur();

                                showConfirmDialog(
                                  dispatch,
                                  <span className="text-info">
                                    <FontAwesomeIcon icon={faQuestionCircle} /> Remove Report
                                  </span>,
                                  <div>Are you sure you want to remove the bug report?</div>,
                                  () => {
                                    fetchWithRetry(
                                      `https://api2.easycryptobot.com/admin/reported.php`,
                                      "delete",
                                      [bug.id],
                                      null,
                                      null,
                                      { Authorization: `Bearer ${state.user.token}` }
                                    );
                                    dispatch({
                                      type: "reportedBugs",
                                      payload: state.reportedBugs.filter((el) => el.id !== bug.id),
                                    });
                                  }
                                );
                              }}
                            >
                              <FontAwesomeIcon icon={faTimesCircle} />
                            </a>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      )}{" "}
    </div>
  );
};
export default ReportedBugs;
