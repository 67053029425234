import React from "react";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faQuestion,
  faMailBulk,
  faCheck,
  faDollarSign,
  faArrowAltCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import { RadioButtons } from "./RadioButtons";

const proPlan = { m: 50, y: 300, l: 900 };
const beginnerPlan = 10;

const PricingPlans = (props) => {
  return (
    <>
      <div className="d-block d-lg-none text-left">
        <div className="card mt-3">
          <div className="card-body">
            <div className="h5 card-title">Free</div>
            <div className="text-muted small">
              <div>1 week full access.</div>
              <div>Limited functionality after that.</div>
            </div>
            <div className="h1">$0</div>
            <div className="px-3 ml-1"> </div>
            <div className="px-3 ml-1"> </div>
          </div>
        </div>
        <div className="card mt-3">
          <div className="card-body">
            <h5 className="card-title">
              Beginner
              {props.discount.b ? <span className="text-danger ml-3">-{props.discount.b}%</span> : ""}
            </h5>
            <div className="text-muted small">
              <div>For crypto traders that are just starting with technical analysis.</div>
            </div>
            <div className="h1 d-inline-block">
              ${props.discount.b ? beginnerPlan * (1 - props.discount.b / 100) : beginnerPlan}
            </div>
            <div className="d-inline-block text-muted ml-1">/ month</div>
            {props.discount.b && <div className="text-muted scratched">${beginnerPlan} / month</div>}
            <div className="text-muted small">
              billed <b>monthly</b>
            </div>
            <button
              type="button"
              className="btn btn-sm btn-info w-25 mt-3"
              onClick={() => {
                props.btnFn.b();
              }}
            >
              {props.btnLabel}
            </button>
          </div>
        </div>
        <div className="card mt-3">
          <div className="card-body">
            <h5 className="card-title">
              Pro
              {props.discount.pro ? <span className="text-danger ml-3">-{props.discount.pro}%</span> : ""}
            </h5>
            <div className="text-muted small">
              <div>For intensive trading on multiple coins, with multiple instruments.</div>
            </div>

            <RadioButtons
              options={[
                {
                  id: "radioProPlanY",
                  optionClasses: "d-block cursor-pointer mr-md-4 pr-md-2",
                  checkedColor: "h4 text-info align-middle",
                  uncheckedColor: "text-white hover-info border rounded-circle border-info ml-2 mr-1",
                  checked: props.selectedProPlanType === "y",
                  check: () => {
                    props.setSelectedProPlanType("y");
                  },
                  text: (
                    <div className="d-inline-block">
                      <div className="d-flex flex-column">
                        <div>
                          <div className={`d-inline-block ${props.selectedProPlanType === "y" ? "h1" : "h5"}`}>
                            ${props.discount.pro ? (proPlan.y / 12) * (1 - props.discount.pro / 100) : proPlan.y / 12}
                          </div>
                          <div className="d-inline-block text-muted ml-1">/ month</div>
                        </div>
                        {props.discount.pro && <div className="text-muted scratched">${proPlan.y / 12} / month</div>}
                        <div className="text-muted text-wrap mb-1 ml-1">
                          billed <b>annually</b> - total{" "}
                          <b>${props.discount.pro ? proPlan.y * (1 - props.discount.pro / 100) : proPlan.y}</b>
                        </div>
                      </div>
                    </div>
                  ),
                },
                {
                  id: "radioProPlanM",
                  optionClasses: "d-block cursor-pointer mr-md-4 pr-md-2",
                  checkedColor: "h4 text-info align-middle",
                  uncheckedColor: "text-white hover-info border rounded-circle border-info ml-2 mr-1",
                  checked: props.selectedProPlanType === "m",
                  check: () => {
                    props.setSelectedProPlanType("m");
                  },
                  text: (
                    <div className="d-inline-block">
                      <div className="d-flex flex-column">
                        <div>
                          <div className={`d-inline-block ${props.selectedProPlanType === "m" ? "h1" : "h5"}`}>
                            ${props.discount.pro ? proPlan.m * (1 - props.discount.pro / 100) : proPlan.m}
                          </div>
                          <div className="d-inline-block text-muted ml-1">/ month</div>
                        </div>
                        {props.discount.pro && <div className="text-muted scratched">${proPlan.m} / month</div>}
                        <div className="text-muted text-wrap mb-1">
                          billed <b>monthly</b> - total{" "}
                          <b>${props.discount.pro ? proPlan.m * (1 - props.discount.pro / 100) : proPlan.m}</b>
                        </div>
                      </div>
                    </div>
                  ),
                },
                {
                  id: "radioProPlanL",
                  optionClasses: "d-block cursor-pointer mr-md-4 pr-md-2",
                  checkedColor: "h4 text-info align-middle",
                  uncheckedColor: "text-white hover-info border rounded-circle border-info ml-2 mr-1",
                  checked: props.selectedProPlanType === "l",
                  check: () => {
                    props.setSelectedProPlanType("l");
                  },
                  text: (
                    <div className="d-inline-block">
                      <div className="d-flex flex-column">
                        <div>
                          <div className={`d-inline-block ${props.selectedProPlanType === "l" ? "h1" : "h5"}`}>
                            ${props.discount.pro ? proPlan.l * (1 - props.discount.pro / 100) : proPlan.l}
                          </div>
                          <div className="d-inline-block text-muted ml-1">/ Lifetime</div>
                        </div>
                        {props.discount.pro && <div className="text-muted scratched">${proPlan.l} / Lifetime</div>}
                        <div className="text-muted  text-wrap mb-1">
                          billed <b>once</b> - total{" "}
                          <b>${props.discount.pro ? proPlan.l * (1 - props.discount.pro / 100) : proPlan.l}</b>
                        </div>
                      </div>
                    </div>
                  ),
                },
              ]}
            />

            <button
              type="button"
              className="d-block btn btn-sm btn-info w-25 mt-3"
              onClick={() => {
                props.btnFn.pro();
              }}
            >
              {props.btnLabel}
            </button>
          </div>
        </div>
        <div className="card mt-3">
          <div className="card-body">
            <h5 className="card-title">Individual</h5>
            <div className="text-muted small">
              <div>Create a plan according to your needs and trading style.</div>
            </div>
            <div className="h1 d-inline-block">$</div>
            <div className="h1 d-inline-block ml-2">?</div>
            <div className="text-muted small mb-1">Contact us to get an offer</div>
            <NavLink
              className="btn btn-sm btn-info w-25 mt-3"
              as={NavLink}
              to="/contact"
              exact
              activeClassName="active"
            >
              Contact us
            </NavLink>
          </div>
        </div>
      </div>
      <div className="table-responsive overflow-auto white mt-4">
        <table className="table table-sm table-borderless table-hover pricing equal-cols small text-left">
          <thead className="">
            <tr className="d-lg-none">
              <th></th>
              <th>Free</th>
              <th>Begin.</th>
              <th>Pro</th>
              <th>Indv.</th>
            </tr>
            <tr className="d-none d-lg-table-row">
              <th></th>
              <th className="h5">Free</th>
              <th className="h5">
                Beginner
                {props.discount.b && <span className="text-danger ml-3">-{props.discount.b}%</span>}
              </th>
              <th className="h5">
                Pro
                {props.discount.pro && <span className="text-danger ml-3">-{props.discount.pro}%</span>}
              </th>
              <th className="h5">Individual</th>
            </tr>
            <tr className="d-none d-lg-table-row">
              <td></td>
              <td className="">
                <div className="text-muted">
                  <div>1 week full access.</div>
                  <div>Limited functionality after that.</div>
                </div>
              </td>
              <td className="">
                <div className="text-muted">
                  <div>For crypto traders that are just starting with technical analysis.</div>
                </div>
              </td>
              <td className="">
                <div className="text-muted">
                  <div>For intensive trading on multiple coins, with multiple instruments.</div>
                </div>
              </td>
              <td className="">
                <div className="text-muted">
                  <div>Create a plan according to your needs and trading style.</div>
                </div>
              </td>
            </tr>
            <tr className="d-none d-lg-table-row">
              <td></td>
              <td className="">
                <div className="h1">$0</div>
              </td>
              <td className="">
                <div className="d-block text-nowrap mr-md-4 pr-md-2">
                  <FontAwesomeIcon className="h4 text-info align-middle mr-1" icon={faArrowAltCircleRight} />
                  <div className="d-inline-block">
                    <div className="d-flex flex-column">
                      <div className="">
                        <div className="d-inline-block h1">
                          ${props.discount.b ? beginnerPlan * (1 - props.discount.b / 100) : beginnerPlan}
                        </div>
                        <div className="d-inline-block text-muted ml-1">/ month</div>
                      </div>
                      {props.discount.b && <div className="text-muted scratched">${beginnerPlan} / month</div>}
                      <div className="text-muted text-wrap mb-1 ml-1">
                        billed <b>monthly</b>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
              <td className="">
                <RadioButtons
                  options={[
                    {
                      id: "radioProPlanY",
                      optionClasses: "d-block cursor-pointer mr-0 pr-md-2",
                      checkedColor: "h4 text-info align-middle",
                      uncheckedColor: "text-white hover-info border rounded-circle border-info ml-2 mr-1",
                      checked: props.selectedProPlanType === "y",
                      check: () => {
                        props.setSelectedProPlanType("y");
                      },
                      text: (
                        <div className="d-inline-block">
                          <div className="d-flex flex-column">
                            <div className="">
                              <div className={`d-inline-block ${props.selectedProPlanType === "y" ? "h1" : "h5"}`}>
                                $
                                {props.discount.pro
                                  ? (proPlan.y / 12) * (1 - props.discount.pro / 100)
                                  : proPlan.y / 12}
                              </div>
                              <div className="d-inline-block text-muted ml-1">/ month</div>
                            </div>
                            {props.discount.pro && (
                              <div className="text-muted scratched">${proPlan.y / 12} / month</div>
                            )}
                            <div className="text-muted text-wrap mb-1 ml-1">
                              billed <b>annually</b> - total{" "}
                              <b>${props.discount.pro ? proPlan.y * (1 - props.discount.pro / 100) : proPlan.y}</b>
                            </div>
                          </div>
                        </div>
                      ),
                    },
                    {
                      id: "radioProPlanM",
                      optionClasses: "d-block cursor-pointer mr-0 pr-md-2",
                      checkedColor: "h4 text-info align-middle",
                      uncheckedColor: "text-white hover-info border rounded-circle border-info ml-2 mr-1",
                      checked: props.selectedProPlanType === "m",
                      check: () => {
                        props.setSelectedProPlanType("m");
                      },
                      text: (
                        <div className="d-inline-block">
                          <div className="d-flex flex-column">
                            <div className="">
                              <div className={`d-inline-block ${props.selectedProPlanType === "m" ? "h1" : "h5"}`}>
                                ${props.discount.pro ? proPlan.m * (1 - props.discount.pro / 100) : proPlan.m}
                              </div>
                              <div className="d-inline-block text-muted ml-1">/ month</div>
                            </div>
                            {props.discount.pro && <div className="text-muted scratched">${proPlan.m} / month</div>}
                            <div className="text-muted text-wrap mb-1">
                              billed <b>monthly</b> - total{" "}
                              <b>${props.discount.pro ? proPlan.m * (1 - props.discount.pro / 100) : proPlan.m}</b>
                            </div>
                          </div>
                        </div>
                      ),
                    },
                    {
                      id: "radioProPlanL",
                      optionClasses: "d-block cursor-pointer mr-0 pr-md-2",
                      checkedColor: "h4 text-info align-middle",
                      uncheckedColor: "text-white hover-info border rounded-circle border-info ml-2 mr-1",
                      checked: props.selectedProPlanType === "l",
                      check: () => {
                        props.setSelectedProPlanType("l");
                      },
                      text: (
                        <div className="d-inline-block">
                          <div className="d-flex flex-column">
                            <div className="text-wrap">
                              <div className={`d-inline-block ${props.selectedProPlanType === "l" ? "h1" : "h5"}`}>
                                ${props.discount.pro ? proPlan.l * (1 - props.discount.pro / 100) : proPlan.l}
                              </div>
                              <div className="d-inline-block text-muted ml-1">/ Lifetime</div>
                            </div>
                            {props.discount.pro && <div className="text-muted scratched">${proPlan.l} / Lifetime</div>}
                            <div className="text-muted text-wrap mb-1">
                              billed <b>once</b> - total{" "}
                              <b>${props.discount.pro ? proPlan.l * (1 - props.discount.pro / 100) : proPlan.l}</b>
                            </div>
                          </div>
                        </div>
                      ),
                    },
                  ]}
                />
              </td>
              <td className="">
                <div className="h1 d-inline-block">$</div>
                <div className="h1 d-inline-block ml-2">?</div>
                <div className="text-muted mb-1">Contact us to get an offer</div>
              </td>
            </tr>
            <tr className="d-none d-lg-table-row">
              <td></td>
              <td></td>
              <td>
                <button
                  type="button"
                  className="btn btn-sm btn-info px-4 my-3"
                  onClick={() => {
                    props.btnFn.b();
                  }}
                >
                  {props.btnLabel}
                </button>
              </td>
              <td>
                <button
                  type="button"
                  className="btn btn-sm btn-info px-4 my-3"
                  onClick={() => {
                    props.btnFn.pro();
                  }}
                >
                  {props.btnLabel}
                </button>
              </td>
              <td>
                <NavLink className="btn btn-sm btn-info my-3" as={NavLink} to="/contact" exact activeClassName="active">
                  Contact us
                </NavLink>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr className="no-hover">
              <td className="text-secondary">TRADING TOOLS</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>Watchlists</td>
              <td>
                <FontAwesomeIcon icon={faCheck} />
              </td>
              <td>
                <FontAwesomeIcon icon={faCheck} />
              </td>
              <td>
                <FontAwesomeIcon icon={faCheck} />
              </td>
              <td>
                <FontAwesomeIcon icon={faCheck} />
              </td>
            </tr>
            <tr>
              <td>Live charts</td>
              <td>
                <FontAwesomeIcon icon={faCheck} />
              </td>
              <td>
                <FontAwesomeIcon icon={faCheck} />
              </td>
              <td>
                <FontAwesomeIcon icon={faCheck} />
              </td>
              <td>
                <FontAwesomeIcon icon={faCheck} />
              </td>
            </tr>
            <tr>
              <td>Coin pairs</td>
              <td>All</td>
              <td>All</td>
              <td>All</td>
              <td>All</td>
            </tr>
            <tr>
              <td>Technical instruments</td>
              <td>All</td>
              <td>All</td>
              <td>All</td>
              <td>All</td>
            </tr>
            <tr>
              <td>Manual trading</td>
              <td>
                <FontAwesomeIcon icon={faCheck} />
              </td>
              <td>
                <FontAwesomeIcon icon={faCheck} />
              </td>
              <td>
                <FontAwesomeIcon icon={faCheck} />
              </td>
              <td>
                <FontAwesomeIcon icon={faCheck} />
              </td>
            </tr>
            <tr className="no-hover">
              <td className="text-secondary pt-3">BOT TRADING</td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td>
                Strategies
                <small className="d-block">
                  Strategy tools, incl. trailing buy, stoploss, target and trailing target
                </small>
              </td>
              <td>
                <FontAwesomeIcon icon={faCheck} />
              </td>
              <td>
                <FontAwesomeIcon icon={faCheck} />
              </td>
              <td>
                <FontAwesomeIcon icon={faCheck} />
              </td>
              <td>
                <FontAwesomeIcon icon={faCheck} />
              </td>
            </tr>
            <tr>
              <td className="pb-2">Backtests</td>
              <td>
                <FontAwesomeIcon icon={faCheck} />
              </td>
              <td>
                <FontAwesomeIcon icon={faCheck} />
              </td>
              <td>
                <FontAwesomeIcon icon={faCheck} />
              </td>
              <td>
                <FontAwesomeIcon icon={faCheck} />
              </td>
            </tr>
            <tr>
              <td className="pb-2">Backtest periods</td>
              <td>Limited</td>
              <td>Unlimited</td>
              <td>Unlimited</td>
              <td>
                <FontAwesomeIcon icon={faQuestion} />
              </td>
            </tr>
            <tr>
              <td>
                Optimizations
                <small className="d-block">Number of optimizations per week</small>
              </td>
              <td>1</td>
              <td>5</td>
              <td>Unlimited</td>
              <td>
                <FontAwesomeIcon icon={faQuestion} />
              </td>
            </tr>
            <tr>
              <td>
                Simulations
                <small className="d-block">Number of simultaneously running simulations</small>
              </td>
              <td>1</td>
              <td>5</td>
              <td>Unlimited</td>
              <td>
                <FontAwesomeIcon icon={faQuestion} />
              </td>
            </tr>
            <tr>
              <td>
                Live Trading
                <small className="d-block">Number of simultaneously running strategies in live trading</small>
              </td>
              <td></td>
              <td>1</td>
              <td>Unlimited</td>
              <td>
                <FontAwesomeIcon icon={faQuestion} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

const PricingPlansDetails = (props) => {
  return (
    <div className="text-dark pb-4 rounded mt-4">
      <h2 className="h5 py-4">All pricing plans include:</h2>
      <div className="row row-cols-md-3 row-cols-1 mx-auto text-left">
        <div className="col">
          <h3 className="h6 font-weight-normal">
            <FontAwesomeIcon className="text-info" icon={faCheckCircle} /> Unlimited strategies
          </h3>
          <h3 className="h6 font-weight-normal">
            <FontAwesomeIcon className="text-info" icon={faCheckCircle} /> Unlimited simulations
          </h3>
          <h3 className="h6 font-weight-normal">
            <FontAwesomeIcon className="text-info" icon={faCheckCircle} /> Unlimited optimizations
          </h3>
          <h3 className="h6 font-weight-normal">
            <FontAwesomeIcon className="text-info" icon={faCheckCircle} /> Unlimited backtests
          </h3>
        </div>
        <div className="col">
          <h3 className="h6 font-weight-normal">
            <FontAwesomeIcon className="text-info" icon={faCheckCircle} /> Unlimited positions
          </h3>
          <h3 className="h6 font-weight-normal">
            <FontAwesomeIcon className="text-info" icon={faCheckCircle} /> Unlimited coins
          </h3>
          <h3 className="h6 font-weight-normal">
            <FontAwesomeIcon className="text-info" icon={faCheckCircle} /> Unlimited triggers
          </h3>
          <h3 className="h6 font-weight-normal">
            <FontAwesomeIcon className="text-info" icon={faCheckCircle} /> Unlimited trades
          </h3>
        </div>
        <div className="col">
          <h3 className="h6 font-weight-normal">
            <FontAwesomeIcon className="text-info" icon={faCheckCircle} /> All timeframes
          </h3>
          <h3 className="h6 font-weight-normal">
            <FontAwesomeIcon className="text-info" icon={faCheckCircle} /> All indicators
          </h3>
          <h3 className="h6 font-weight-normal">
            <FontAwesomeIcon className="text-info" icon={faCheckCircle} /> Target and trailing target
          </h3>
          <h3 className="h6 font-weight-normal">
            <FontAwesomeIcon className="text-info" icon={faCheckCircle} /> Stoploss and timestop
          </h3>
        </div>
      </div>
      <h2 className="h6 py-4">...and more! Sign up to find out!</h2>
    </div>
  );
};

export { PricingPlans, PricingPlansDetails, proPlan, beginnerPlan };
