import React, { useContext, useState, useEffect, useRef } from "react";
import logo from "./assets/images/logo.png";
import { globalContext } from "./Context.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faInfoCircle, faArrowsAltV } from "@fortawesome/free-solid-svg-icons";
import loading from "./assets/images/loading.gif";
import { updateExecutionStatus, showInfoDialog, fetchWithRetry, getFromLocalStorage } from "./utils.js";

const HomeApp = (props) => {
  let { state, dispatch } = useContext(globalContext);
  const componentIsMounted = useRef(true);
  const [userFilter, setUserFilter] = useState("");
  const [page, setPage] = useState(0);
  const [pages, setPages] = useState([]);
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(0);
  const [userIds, setUserIds] = useState([]);
  const [skipExpiredUsers, setSkipExpiredUsers] = useState(localStorage.getItem("skipExpiredUsers") ? true : false);
  const [usersPerPage, setUsersPerPage] = useState(getFromLocalStorage("usersPerPage", "", "number"));
  const [usersSort, setUsersSort] = useState(getFromLocalStorage("usersSort", "n"));

  useEffect(() => {
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  useEffect(() => {
    setUserIds(
      state.users
        .sort((a, b) => {
          switch (usersSort) {
            case "n":
              return a.name.localeCompare(b.name);
            case "nR":
              return b.name.localeCompare(a.name);
            case "m":
              return a.email.localeCompare(b.email);
            case "mR":
              return b.email.localeCompare(a.email);
            case "s":
              return a.subscriptionType.localeCompare(b.subscriptionType);
            case "sR":
              return b.subscriptionType.localeCompare(a.subscriptionType);
            case "c":
              return a.creationDate.localeCompare(b.creationDate);
            case "cR":
              return b.creationDate.localeCompare(a.creationDate);
            case "e":
              return a.subscriptionExpireDate.localeCompare(b.subscriptionExpireDate);
            case "eR":
              return b.subscriptionExpireDate.localeCompare(a.subscriptionExpireDate);
            default:
              return a.name.localeCompare(b.name);
          }
        })
        .filter((el) => !skipExpiredUsers || !el.isExpired)
        .filter((el) => !userFilter || el.name.toLowerCase().indexOf(userFilter.toLowerCase()) !== -1)
        .map((el) => el.id)
    );
  }, [state.users, usersSort, skipExpiredUsers, userFilter]);

  useEffect(() => {
    let pages = [];
    for (let i = 0; i < userIds.length / (usersPerPage ? usersPerPage : userIds.length); i++) {
      pages.push(i);
    }
    setPages(pages);
    setStartIndex((usersPerPage ? usersPerPage : 0) * page);
    setEndIndex((usersPerPage ? usersPerPage : 0) * page + (usersPerPage ? usersPerPage : userIds.length));
    if (page > pages.length - 1) {
      setPage(Math.max(pages.length - 1, 0));
    }
  }, [page, userIds, usersPerPage]);

  return (
    <div className="page p-2 p-md-5 ">
      <div className="">
        {state.users.length > 0 ? (
          <div>
            <h2 className="h3 mb-3">
              Users <span className="small">{state.users.length}</span>
            </h2>
            <div className="my-2 text-left d-flex">
              <div className="mr-auto">
                <FontAwesomeIcon className="text-info" icon={faSearch} />
                &nbsp;
                <div className="d-inline-block input-group-sm">
                  <input
                    type="text"
                    className="setup-info"
                    value={userFilter}
                    placeholder="Search"
                    onChange={(e) => {
                      setUserFilter(e.target.value);
                    }}
                  ></input>
                </div>
                <div className="custom-control custom-checkbox d-inline-block ml-2">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="expiredUsers"
                    checked={skipExpiredUsers}
                    onChange={(e) => {
                      if (skipExpiredUsers) {
                        localStorage.removeItem("skipExpiredUsers");
                      } else {
                        localStorage.setItem("skipExpiredUsers", 1);
                      }
                      setSkipExpiredUsers(!skipExpiredUsers);
                    }}
                  />
                  <label type="text" className="custom-control-label" htmlFor="expiredUsers">
                    Skip Expired
                  </label>
                </div>
              </div>

              <div className="text-left ml-auto text-nowrap user-select-none">
                <span className="small mr-2">Show:</span>
                <div className="d-inline-block input-group-sm">
                  <input
                    type="number"
                    className="setup-info text-left w-25-px"
                    value={usersPerPage}
                    placeholder="All"
                    onChange={(e) => {
                      let value = Math.abs(Number.parseInt(e.target.value));
                      setUsersPerPage(value);
                      localStorage.setItem("usersPerPage", value);
                    }}
                  ></input>
                </div>
              </div>
            </div>
            <div className="table-responsive rounded border border-dark rounded mb-3">
              <table className="table table-sm table-new-dark rounded sortable-table small mb-0 ">
                <thead className="thead-new-dark">
                  <tr>
                    <th className="text-left pl-3">ID</th>
                    <th
                      className="text-left sortable"
                      onClick={() => {
                        let newValue = usersSort !== "n" ? "n" : "nR";
                        setUsersSort(newValue);
                        localStorage.setItem("usersSort", newValue);
                      }}
                    >
                      Name{" "}
                      {(usersSort === "n" || usersSort === "nR") && (
                        <>
                          &nbsp;&nbsp;
                          <FontAwesomeIcon icon={faArrowsAltV} />
                        </>
                      )}
                    </th>
                    <th
                      className="text-left sortable"
                      onClick={() => {
                        let newValue = usersSort !== "m" ? "m" : "mR";
                        setUsersSort(newValue);
                        localStorage.setItem("usersSort", newValue);
                      }}
                    >
                      Email{" "}
                      {(usersSort === "m" || usersSort === "mR") && (
                        <>
                          &nbsp;&nbsp;
                          <FontAwesomeIcon icon={faArrowsAltV} />
                        </>
                      )}
                    </th>
                    <th
                      className="text-left sortable"
                      onClick={() => {
                        let newValue = usersSort !== "s" ? "s" : "sR";
                        setUsersSort(newValue);
                        localStorage.setItem("usersSort", newValue);
                      }}
                    >
                      Subscription{" "}
                      {(usersSort === "s" || usersSort === "sR") && (
                        <>
                          &nbsp;&nbsp;
                          <FontAwesomeIcon icon={faArrowsAltV} />
                        </>
                      )}
                    </th>
                    <th
                      className="text-left text-nowrap sortable"
                      onClick={() => {
                        let newValue = usersSort !== "c" ? "c" : "cR";
                        setUsersSort(newValue);
                        localStorage.setItem("usersSort", newValue);
                      }}
                    >
                      Created On{" "}
                      {(usersSort === "c" || usersSort === "cR") && (
                        <>
                          &nbsp;&nbsp;
                          <FontAwesomeIcon icon={faArrowsAltV} />
                        </>
                      )}
                    </th>
                    <th
                      className="text-left text-nowrap sortable"
                      onClick={() => {
                        let newValue = usersSort !== "e" ? "e" : "eR";
                        setUsersSort(newValue);
                        localStorage.setItem("usersSort", newValue);
                      }}
                    >
                      Expire Date{" "}
                      {(usersSort === "e" || usersSort === "eR") && (
                        <>
                          &nbsp;&nbsp;
                          <FontAwesomeIcon icon={faArrowsAltV} />
                        </>
                      )}
                    </th>
                    <th className="text-left">Verified</th>
                    <th className="text-left">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {userIds.slice(startIndex, endIndex).map((id) => {
                    let user = state.users.find((el) => el.id === id);
                    return (
                      <tr key={user.id}>
                        <td className="text-left pl-3">{user.id}</td>
                        <td className="text-left">{user.name}</td>
                        <td className="text-left">{user.email}</td>
                        <td className="text-left">{user.subscriptionType}</td>
                        <td className="text-left">{user.creationDate}</td>
                        <td className="text-left">{user.subscriptionExpireDate}</td>
                        <td className="text-left">{user.verifiedEmail === 1 ? "yes" : "no"}</td>

                        <td className="text-left">
                          <a
                            className="btn btn-sm btn-info ml-2 align-top"
                            href="#/"
                            onClick={(e) => {
                              e.preventDefault();
                              document.activeElement.blur();
                              dispatch({ type: "adminGetUser", payload: user.id });
                              dispatch({ type: "reportedBugs", payload: [] });

                              fetchWithRetry(
                                `https://api2.easycryptobot.com/admin/reported.php?id=${user.id}`,
                                "get",
                                null,
                                async (json) => {
                                  if (json && json.length > 0) {
                                    json.forEach((el) => {
                                      el.report = JSON.parse(el.report.replace(/&quot;/g, '"'));
                                    });
                                  }
                                  dispatch({ type: "reportedBugs", payload: json });
                                },
                                () => {},
                                { Authorization: `Bearer ${state.user.token}` }
                              );
                              updateExecutionStatus(
                                state.user.token,
                                user.id,
                                dispatch,
                                false,
                                true,
                                (strategiesN, simN, liveN) => {
                                  showInfoDialog(
                                    dispatch,
                                    <span className="text-info">
                                      <FontAwesomeIcon icon={faInfoCircle} /> User Data Loaded
                                    </span>,
                                    <div>
                                      <div>{user.name}</div>
                                      <ul>
                                        <li>
                                          Strategies: <span className="text-info">{strategiesN}</span>
                                        </li>
                                        <li>
                                          Simulations: <span className="text-info">{simN}</span>
                                        </li>
                                        <li>
                                          Live Tradings: <span className="text-info">{liveN}</span>
                                        </li>
                                      </ul>
                                    </div>
                                  );
                                }
                              );
                            }}
                          >
                            Load
                          </a>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {pages.length > 1 && (
              <div className="overflow-auto mb-2">
                <nav aria-label="page navigation">
                  <ul className="pagination">
                    <li className="page-item" key="prev">
                      <a
                        className="page-link small px-2 py-1"
                        aria-label="Previous"
                        href="#/"
                        onClick={(e) => {
                          e.preventDefault();
                          if (page > 0) {
                            setPage(page - 1);
                          }
                        }}
                      >
                        <span aria-hidden="true">&laquo;</span>
                        <span className="sr-only">Previous</span>
                      </a>
                    </li>
                    {pages.map((el) => {
                      return (
                        <li className={"page-item " + (page === el ? "active" : "")} key={el}>
                          <a
                            className="page-link small px-2 py-1"
                            href="#/"
                            onClick={(e) => {
                              e.preventDefault();
                              setPage(el);
                            }}
                          >
                            {el + 1}
                          </a>
                        </li>
                      );
                    })}

                    <li className="page-item" key="next">
                      <a
                        className="page-link small px-2 py-1"
                        href="#/"
                        aria-label="Next"
                        onClick={(e) => {
                          e.preventDefault();
                          if (page < Math.floor(userIds / (usersPerPage ? usersPerPage : userIds.length))) {
                            setPage(page + 1);
                          }
                        }}
                      >
                        <span aria-hidden="true">&raquo;</span>
                        <span className="sr-only">Next</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            )}
          </div>
        ) : (
          <div className="loading-img cursor-help my-5" title="Loading..">
            <img src={loading} alt="" />
          </div>
        )}
      </div>
    </div>
  );
};

export default HomeApp;
