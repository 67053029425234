const templateStratgies = [
  {
    name: "RSI 65/35 Crossover",
    desc: "Buys when RSI indicates the coin is oversold. Sells when RSI indicates the coin is overbought.",
    strategy: {
      id: "",
      creationDate: "",
      version: 3,
      name: "RSI 1H 65/35 Crossover",
      buyRules: [
        {
          id: "rule_41537",
          timeframe: "1 hour",
          indicator: "RSI",
          conditionsOrder: ["condition1"],
          period: 14,
          condition1: { direction: "crossing", value: 35, crossDirection: "bottom to top", saved: true },
          saved: true,
          mode:"close"
        },
      ],
      sellRules: [
        {
          id: "rule_79632",
          timeframe: "1 hour",
          indicator: "RSI",
          conditionsOrder: ["condition1"],
          period: 14,
          condition1: { direction: "crossing", value: 65, crossDirection: "top to bottom", saved: true },
          saved: true,
          mode:"close"
        },
      ],
      tBuy: "",
      tBuyWait: "",
      stoploss: "",
      target: "",
      trailingTarget: "",
      timeClose: "",
      exchange: "",
      pair: "",
      pairDetails: "",
      optimize: "Never",
      conditionDirection: "",
      conditionValue: "",
      userId: "",
      conditionPair: "",
    },
  },
  {
    name: "Price is Down",
    desc: "Buys on the reverse of big price drops. Sells with trailing target or stoploss.",
    strategy: {
      id: "",
      creationDate: "",
      version: 3,
      name: "Price is Down",
      buyRules: [
        {
          id: "rule_41537",
          timeframe: "1 hour",
          indicator: "the price",
          conditionsOrder: ["condition1"],
          condition1: { direction: "down", value: 5, saved: true },
          saved: true,
          mode:"current"
        },
      ],
      sellRules: [],
      tBuy: 0.5,
      tBuyWait: 10,
      stoploss: 2,
      target: 2,
      trailingTarget: 1,
      timeClose: "",
      exchange: "",
      pair: "",
      pairDetails: "",
      optimize: "Never",
      conditionDirection: "",
      conditionValue: "",
      userId: "",
      conditionPair: "",
    },
  },
  {
    name: "Fast SMA crossing slow SMA",
    desc: "Buys when a fast SMA is crossing a slow SMA from bottom to top. Sells when the cross is from top to bottom.",
    strategy: {
      id: "",
      creationDate: "",
      version: 3,
      name: "Fast SMA crossing slow SMA",
      buyRules: [
        {
          id: "rule_41537",
          timeframe: "1 hour",
          indicator: "SMA",
          conditionsOrder: ["condition1"],
          period: 10,
          condition1: { indicator: "SMA", period: 30, crossDirection: "bottom to top", saved: true },
          saved: true,
          mode:"close"
        },
      ],
      sellRules: [
        {
          id: "rule_75196",
          timeframe: "1 hour",
          indicator: "SMA",
          conditionsOrder: ["condition1"],
          period: 10,
          condition1: { indicator: "SMA", period: 30, crossDirection: "top to bottom", saved: true },
          saved: true,
          mode:"close"
        },
      ],
      tBuy: "",
      tBuyWait: "",
      stoploss: "",
      target: "",
      trailingTarget: "",
      timeClose: "",
      exchange: "",
      pair: "",
      pairDetails: "",
      optimize: "Never",
      conditionDirection: "",
      conditionValue: "",
      userId: "",
      conditionPair: "",
    },
  },
  {
    name: "EMA trend reversal",
    desc: "Buys when EMA reverses its downtrend. Sells when EMA reverses its uptrend.",
    strategy: {
      id: "",
      creationDate: "",
      version: 3,
      name: "EMA trend reversal",
      buyRules: [
        {
          id: "rule_41537",
          timeframe: "15 minutes",
          indicator: "EMA",
          conditionsOrder: ["condition3"],
          period: 15,
          saved: true,
          condition3: { direction: "downtrend", value2: 0.3, value: 1.5, saved: true },
          mode:"close"
        },
      ],
      sellRules: [
        {
          id: "rule_75196",
          timeframe: "15 minutes",
          indicator: "EMA",
          conditionsOrder: ["condition3"],
          period: 15,
          saved: true,
          condition3: { direction: "uptrend", value: 1.5, value2: 0.3, saved: true },
          mode:"close"
        },
      ],
      tBuy: "",
      tBuyWait: "",
      stoploss: "",
      target: "",
      trailingTarget: "",
      timeClose: "",
      exchange: "",
      pair: "",
      pairDetails: "",
      optimize: "Never",
      conditionDirection: "",
      conditionValue: "",
      userId: "",
      conditionPair: "",
    },
  },
  {
    name: "MACD Crossover",
    desc:
      "Buys when MACD is negative and crosses the signal line from bottom to top. Sells when MACD is positive and the cross is from top to bottom.",
    strategy: {
      id: "",
      creationDate: "",
      version: 3,
      name: "MACD Crossover",
      buyRules: [
        {
          id: "rule_41537",
          timeframe: "4 hours",
          indicator: "MACD",
          conditionsOrder: ["condition1", "condition2"],
          period: 12,
          period2: 26,
          period3: 9,
          condition1: { direction: "crossing", crossDirection: "bottom to top", saved: true },
          condition2: { direction: "below", saved: true },
          saved: true,
          mode:"close"
        },
      ],
      sellRules: [
        {
          id: "rule_79632",
          timeframe: "4 hours",
          indicator: "MACD",
          conditionsOrder: ["condition1", "condition2"],
          period: 12,
          period2: 26,
          period3: 9,
          condition1: { direction: "crossing", crossDirection: "top to bottom", saved: true },
          condition2: { direction: "above", saved: true },
          saved: true,
          mode:"close"
        },
      ],
      tBuy: "",
      tBuyWait: "",
      stoploss: "",
      target: "",
      trailingTarget: "",
      timeClose: "",
      exchange: "",
      pair: "",
      pairDetails: "",
      optimize: "Never",
      conditionDirection: "",
      conditionValue: "",
      userId: "",
      conditionPair: "",
    },
  },
  {
    name: "Bollinger Bands squeeze break",
    desc:
      "Buys when Bollinger Bands break from a squeeze and the price is moving up. Sells with trailing target or stoploss.",
    strategy: {
      id: "",
      creationDate: "",
      version: 3,
      name: "Bollinger Bands squeeze break",
      buyRules: [
        {
          id: "rule_20116",
          timeframe: "15 minutes",
          indicator: "Bollinger Bands",
          conditionsOrder: ["condition3"],
          period: 20,
          period2: 2,
          condition3: { value: 2.5, saved: true },
          saved: true,
          mode:"close"
        },
        {
          id: "rule_31812",
          timeframe: "15 minutes",
          indicator: "the price",
          conditionsOrder: ["condition2"],
          condition2: { direction: "above", period: 20, indicator: "SMA", saved: true },
          saved: true,
          mode:"close"
        },
      ],
      sellRules: [],
      tBuy: "",
      tBuyWait: "",
      stoploss: 3,
      target: 3,
      trailingTarget: 1,
      timeClose: "",
      exchange: "",
      pair: "",
      pairDetails: "",
      optimize: "Never",
      conditionDirection: "",
      conditionValue: "",
      userId: "",
      conditionPair: "",
    },
  },
  {
    name: "Kelthner Chanel price crossover",
    desc:
      "Buys when price crosses Kelthner Chanel's lower band from bottom to top. Sells when price crosses the upper band.",
    strategy: {
      id: "",
      creationDate: "",
      version: 3,
      name: "Kelthner Chanel price crossover",
      buyRules: [
        {
          id: "rule_88112",
          timeframe: "15 minutes",
          indicator: "the price",
          conditionsOrder: ["condition2"],
          condition2: {
            direction: "crossing",
            period: 20,
            period2: 1.5,
            indicator: "Keltner Channel",
            band: "lower band",
            crossDirection: "bottom to top",
            saved: true,
          },
          saved: true,
          mode:"close"
        },
      ],
      sellRules: [
        {
          id: "rule_93691",
          timeframe: "15 minutes",
          indicator: "the price",
          conditionsOrder: ["condition2"],
          condition2: {
            direction: "crossing",
            period: 20,
            period2: 1.5,
            indicator: "Keltner Channel",
            band: "upper band",
            crossDirection: "bottom to top",
            saved: true,
          },
          saved: true,
          mode:"close"
        },
      ],
      tBuy: 0.2,
      tBuyWait: 2,
      stoploss: "",
      target: "",
      trailingTarget: "",
      timeClose: "",
      exchange: "",
      pair: "",
      pairDetails: "",
      optimize: "Never",
      conditionDirection: "",
      conditionValue: "",
      userId: "",
      conditionPair: "",
    },
  },
  {
    name: "Stochastic trend reversal",
    desc: "Buys when Stochastic is below 30 and is reversing its downtrend. Sells when Stochastic crosses 70.",
    strategy: {
      id: "",
      creationDate: "",
      version: 3,
      name: "Stochastic trend reversal",
      buyRules: [
        {
          id: "rule_88112",
          timeframe: "1 hour",
          indicator: "Stochastic",
          conditionsOrder: ["condition3", "condition2"],
          period: 14,
          period2: 3,
          period3: 3,
          saved: true,
          condition3: { direction: "downtrend", value: 20, value2: 2, saved: true },
          condition2: { direction: "below", value: 30, saved: true },
          mode:"close"
        },
      ],
      sellRules: [
        {
          id: "rule_36666",
          timeframe: "1 hour",
          indicator: "Stochastic",
          conditionsOrder: ["condition2"],
          period: 14,
          period2: 3,
          period3: 3,
          condition2: { direction: "crossing", value: 70, crossDirection: "bottom to top", saved: true },
          saved: true,
          mode:"close"
        },
      ],
      tBuy: "",
      tBuyWait: "",
      stoploss: "",
      target: "",
      trailingTarget: "",
      timeClose: "",
      exchange: "",
      pair: "",
      pairDetails: "",
      optimize: "Never",
      conditionDirection: "",
      conditionValue: "",
      userId: "",
      conditionPair: "",
    },
  },
  {
    name: "Stochastic RSI crossover",
    desc:
      "Buys when Stochastic RSI is below 30 and is crossing %D line from bottom to top and the SMA on a bigger timeframe is in uptrend. Sells when Stochastic RSI is crossing the %D line above 70.",
    strategy: {
      id: "",
      creationDate: "",
      version: 3,
      name: "Stochastic RSI crossover",
      buyRules: [
        {
          id: "rule_53737",
          timeframe: "15 minutes",
          indicator: "Stochastic RSI",
          conditionsOrder: ["condition1", "condition2"],
          period: 14,
          period2: 3,
          period3: 3,
          period4: 14,
          condition1: { direction: "crossing", crossDirection: "bottom to top", saved: true },
          condition2: { direction: "below", value: 30, saved: true },
          saved: true,
          mode:"close"
        },
        {
          id: "rule_60557",
          timeframe: "4 hours",
          indicator: "SMA",
          conditionsOrder: ["condition2"],
          period: 20,
          condition2: { direction: "uptrend", value: 2, saved: true },
          saved: true,
          mode:"close"
        },
      ],
      sellRules: [
        {
          id: "rule_36666",
          timeframe: "15 minutes",
          indicator: "Stochastic RSI",
          conditionsOrder: ["condition1", "condition2"],
          period: 14,
          period2: 3,
          period3: 3,
          period4: 14,
          condition1: { direction: "crossing", crossDirection: "top to bottom", saved: true },
          condition2: { direction: "above", value: 70, saved: true },
          saved: true,
          mode:"close"
        },
      ],
      tBuy: "",
      tBuyWait: "",
      stoploss: "",
      target: "",
      trailingTarget: "",
      timeClose: "",
      exchange: "",
      pair: "",
      pairDetails: "",
      optimize: "Never",
      conditionDirection: "",
      conditionValue: "",
      userId: "",
      conditionPair: "",
    },
  },
  {
    name: "Momentum Squeeze positive breakout",
    desc:
      "Buys when the price momentum is positive and Bollinger Bands break from a Kelthner Chanel squeeze. Sells with trailing target or stoploss.",
    strategy: {
      id: "",
      creationDate: "",
      version: 3,
      name: "Momentum Squeeze positive breakout",
      buyRules: [
        {
          id: "rule_53737",
          timeframe: "1 hour",
          indicator: "Momentum Squeeze",
          conditionsOrder: ["condition3", "condition5"],
          period: 20,
          period2: 2,
          period3: 20,
          period4: 1,
          formula: "smoothed",
          saved: true,
          condition3: { saved: true },
          condition5: { direction: "positive", saved: true },
          mode:"close"
        },
      ],
      sellRules: [],
      tBuy: 0.5,
      tBuyWait: 5,
      stoploss: 4,
      target: 4,
      trailingTarget: 1.5,
      timeClose: "",
      exchange: "",
      pair: "",
      pairDetails: "",
      optimize: "Never",
      conditionDirection: "",
      conditionValue: "",
      userId: "",
      conditionPair: "",
    },
  },
  /*{
    name: "",
    desc: "",
    strategy: {},
  },
  {
    name: "",
    desc: "",
    strategy: {},
  },
  {
    name: "",
    desc: "",
    strategy: {},
  },*/
];

export default templateStratgies;
